import React from 'react';
import { observer } from 'mobx-react';

import { ProductMovement, ProductMovementStatus } from '~/types/ff/productMovement.types';
import productMovementStore from '~/stores/ff/productMovementStore';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import TimerIcon from '@material-ui/icons/Timer';

import Button from '~ui/Button';
import { ErrorMessage } from '~ui/Message';

const CancelProductMovementButton = ({ product_movement_id }: { product_movement_id: number }) => {
    const handleBtn = () => {
        productMovementStore.changeMovementStatus(product_movement_id, ProductMovementStatus.Cancel);
    };

    return (
        <Button fullWidth color="secondary" variant="outlined" onClick={handleBtn} disabled={false} startIcon={<ErrorOutlineIcon />}>
            Отменить
        </Button>
    );
};

const ExecuteProductMovementButton = ({
    product_movement_id,
    direction
}: {
    product_movement_id: number;
    direction: ProductMovement['direction'];
}) => {
    const handleBtn = () => {
        productMovementStore.changeMovementStatus(product_movement_id, ProductMovementStatus.Done);
    };

    return (
        <Button fullWidth color="primary" variant="contained" onClick={handleBtn} disabled={false} startIcon={<DoneOutlineIcon />}>
            {direction === 'in' ? 'Приход выполнен' : 'Отгрузка выполнена'}
        </Button>
    );
};

type ProductMovementStatusProps = {
    product_movement_id: number;
};

const ProductMovementStatusList = ({ product_movement_id }: ProductMovementStatusProps) => {
    const { item, loadingItem } = productMovementStore.getItem(product_movement_id);
    const { direction, status, planningDeliveryDate, executeDeliveryDate } = item || {};

    if (loadingItem) {
        return null;
    }

    return (
        <List>
            <ListItem>
                <ListItemIcon>
                    <TimerIcon />
                </ListItemIcon>
                {status !== ProductMovementStatus.Done && (
                    <ListItemText primary={new Date(planningDeliveryDate * 1000).toLocaleDateString('ru')} secondary="Ожидаемая дата" />
                )}
                {status === ProductMovementStatus.Done && (
                    <ListItemText primary={new Date(executeDeliveryDate * 1000).toLocaleDateString('ru')} secondary="Дата исполнения" />
                )}
            </ListItem>
            {status === ProductMovementStatus.WaitingForExecution && (
                <ListItem>
                    <ExecuteProductMovementButton product_movement_id={product_movement_id} direction={direction} />
                </ListItem>
            )}
            <ListItem>
                {status !== ProductMovementStatus.Cancel && <CancelProductMovementButton product_movement_id={product_movement_id} />}
                {status === ProductMovementStatus.Cancel && <ErrorMessage>Движение товара отменено</ErrorMessage>}
            </ListItem>
        </List>
    );
};

export default observer(ProductMovementStatusList);
