import { ThemeOptions, createTheme } from '@mui/material/styles';

export const PRIMARY_COLOR = '#00abd1';
export const SECONDARY_COLOR = '#d12600';
export const TEXT_COLOR = '#181f31';
export const CONTRAST_COLOR = '#FCFCFC';

const theme: ThemeOptions = {
    palette: {
        primary: {
            main: PRIMARY_COLOR, //'#03c9d7',
            contrastText: CONTRAST_COLOR //'#fff'
        },
        secondary: {
            main: SECONDARY_COLOR, //'#fb9678',
            contrastText: CONTRAST_COLOR //'#fff'
        },
        text: {
            primary: TEXT_COLOR // '#181f31'
        }
    }
};

export default createTheme(theme);
