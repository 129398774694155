import React from 'react';
import { ProductAspect } from '~/types/ff/product.types';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Grid from '~ui/Grid';

const ProductAspectsShow = ({ aspects }: { aspects: ProductAspect[] }) => {
    return (
        <Grid alignItems="flex-start" columns={2} spacing={1}>
            {aspects.map(({ aspect, value }) => (
                <Grid.Column key={aspect}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography variant="subtitle1" sx={{ color: 'grey.600' }}>
                            {aspect}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                            {value}
                        </Typography>
                    </Stack>
                </Grid.Column>
            ))}
        </Grid>
    );
};

export default ProductAspectsShow;
