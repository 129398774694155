import React, { Component, Fragment, MouseEvent } from 'react';
import { ComponentType } from 'react';
import { Button, SemanticCOLORS } from 'semantic-ui-react';

import authStore from '~/stores/authStore';
import history from '../../history';
import { ESTATE_BASES } from '~/types/estate.types';
import { CREATING_ITEM_ID, ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import { TAB_ID_DEFAULT } from './ItemWrapper';
import TabMenuDropdown, { TabMenuConfigType } from './TabMenuDropdown';

import { AdditionalDocType } from '~/types/documents.types';
import commonStore from '~/stores/commonStore';
import bottomTabStore from '~/stores/bottomTabStore';
import userStore from '~/stores/userStore';
import estateStore from '~/stores/estateStore';

import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabScrollButton from '@material-ui/core/TabScrollButton';
import Badge from '@material-ui/core/Badge';

import DescriptionIcon from '@material-ui/icons/Description';
import contactStore from '~/stores/contactStore';
import dealStore from '~/stores/dealStore';

export const TAB_DOCUMENTS_ID = 'documents';

export const matchTabLabel = (label?: number | string) => {
    return label === undefined ? '..' : label;
};

export type TabMenuWrapperProps = {
    item_id: number;
    param?: string;

    tabName: string;
    enable: boolean;
    major_user_id: number | Array<number>;
    documentsCount?: number;
    additionalDocuments?: AdditionalDocType[];
    contact_id?: number | null;
    owner_id?: number | null;

    itemTabs: MenuItem[];
    onGoBack?: () => void;
};

export type TabMenuWrapperState = {
    toggleDocModal: boolean;
    showCallEventModal: boolean;
    showMeetingEventModal: boolean;
    anchorEl: null | HTMLElement;
    showCloseButton: boolean;
};

export type MenuItem = {
    name: string;
    id: string;
    color?: SemanticCOLORS | 'error';
    label?: string | number;
    icon?: React.ReactElement;
};

const MenuTab = withStyles(({ breakpoints }: Theme) =>
    createStyles({
        root: {
            minWidth: '1em',
            padding: '0 1.5em',
            margin: '0 0.5em',
            [breakpoints.up('sm')]: {
                paddingRight: '2em'
            }
        }
    })
)(Tab);

const MenuBadge = withStyles(({ palette }) => {
    return createStyles({
        colorPrimary: {
            backgroundColor: '#ccc',
            color: palette['action'].active
        }
    });
})(Badge);

const MenuAppBar = withStyles(() =>
    createStyles({
        root: {
            zIndex: 2
        }
    })
)(AppBar);

export type LinkStateType = (number, string, base?: ESTATE_BASES) => any;

const TabMenuWrapper = (
    store: ItemStoreInterface<any>,
    linkState: LinkStateType,
    WrappedComponent: ComponentType<TabMenuWrapperProps>,
    config: Partial<TabMenuConfigType> = {}
) => {
    class TabMenuWrapperClass extends Component<TabMenuWrapperProps, TabMenuWrapperState> {
        constructor(props: TabMenuWrapperProps) {
            super(props);

            this.state = {
                toggleDocModal: false,
                showCallEventModal: false,
                showMeetingEventModal: false,
                anchorEl: null,
                showCloseButton: true
            };
        }

        handleTabClick = (event: React.SyntheticEvent, tabname: string) => {
            const { item_id, param } = this.props;

            if (tabname === TAB_DOCUMENTS_ID) {
                this.handleShowDocModal(event);
                return;
            }

            const LinkState = linkState(item_id, param || '', config.base || undefined);

            LinkState.pathname += tabname ? `/${tabname}` : '';
            LinkState.state['switchFromTab'] = true;

            history.replace(LinkState);
        };

        handleShowDocModal = (event: React.SyntheticEvent) => {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ toggleDocModal: !this.state.toggleDocModal });
        };

        handleReloadItem = () => {
            store.reloadItem(this.props.item_id);
        };

        handleAddBottomTab = () => {
            const { item_id } = this.props;
            const { item } = store.getItemForAccess(item_id);
            if (item) {
                bottomTabStore.addTab({ item_id, item_type: store.moduleName, item });
            }

            history.goBack();
        };

        handleOpenDropdown = (event: MouseEvent<HTMLButtonElement>) => {
            this.setState({ anchorEl: event.currentTarget });
        };

        handleCloseDropdown = () => {
            this.setState({ anchorEl: null });
        };

        handleShowMenu = (direction: string) => {
            this.setState({ showCloseButton: direction !== 'right' });
        };

        handleGoBack = () => {
            if (typeof this.props.onGoBack !== 'function') {
                history.goBack();
            } else {
                this.props.onGoBack();
            }
        };

        render() {
            const { item_id, tabName, enable, major_user_id, itemTabs, documentsCount, contact_id, additionalDocuments, owner_id } =
                this.props;
            const { anchorEl, toggleDocModal, showCloseButton } = this.state;

            const itemTabsAdjusted = [...itemTabs];

            if (
                (documentsCount > 0 || store.moduleName === contactStore.moduleName || store.moduleName === dealStore.moduleName) &&
                (authStore.canEdit(store.moduleName, major_user_id) || (store !== userStore && store !== estateStore))
            ) {
                itemTabsAdjusted.push({
                    name: `Документы`,
                    icon: <DescriptionIcon />,
                    id: TAB_DOCUMENTS_ID,
                    color: 'yellow',
                    label: documentsCount
                });
            }

            const canDelete = authStore.canDelete(store.moduleName, major_user_id);
            const canEdit = authStore.canEdit(store.moduleName, major_user_id);

            return (
                <Fragment>
                    <MenuAppBar
                        position={commonStore.fullSizePage || commonStore.isLowHeight || item_id === CREATING_ITEM_ID ? 'static' : 'fixed'}
                        color="default"
                        className="crm-Item__tabMenu"
                    >
                        {showCloseButton && (
                            <Button
                                basic
                                circular
                                icon="chevron right"
                                onClick={this.handleGoBack}
                                title="Закрыть карточку"
                                className="crm-Item__tabMenu_closeBtn"
                            />
                        )}

                        {item_id > CREATING_ITEM_ID && (
                            <Tabs
                                value={tabName}
                                onChange={this.handleTabClick}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="desktop"
                                style={commonStore.isMobile ? { marginLeft: '30px', marginRight: '60px' } : { marginRight: '150px' }}
                                ScrollButtonComponent={props => (
                                    <TabScrollButton
                                        {...props}
                                        onClick={() => {
                                            this.handleShowMenu(props.direction);
                                            props.onClick();
                                        }}
                                    />
                                )}
                            >
                                {itemTabsAdjusted.map(({ id, name, label, icon, color }) => (
                                    <MenuTab
                                        key={id}
                                        value={id || TAB_ID_DEFAULT}
                                        label={
                                            <MenuBadge color={color === 'error' ? 'error' : 'primary'} badgeContent={label}>
                                                <span className="crm-Flex__center">
                                                    {icon}&nbsp;
                                                    {!commonStore.isMobile && <span>{name}</span>}
                                                </span>
                                            </MenuBadge>
                                        }
                                    />
                                ))}
                            </Tabs>
                        )}

                        <div className="crm-Item__tabMenu_right">
                            {[TAB_ID_DEFAULT].includes(tabName) && (
                                <Button basic circular title="Обновить карточку" icon="refresh" onClick={this.handleReloadItem} />
                            )}
                            {item_id > CREATING_ITEM_ID && !commonStore.isMobile && (
                                <Button basic circular icon="window minimize" title="Свернуть карточку" onClick={this.handleAddBottomTab} />
                            )}
                            {/*Выпадающее меню только, если есть доступ к редактированию*/}
                            {item_id > CREATING_ITEM_ID && !config.disableDropdownMenu && (canEdit || canDelete || owner_id) && (
                                <IconButton onClick={this.handleOpenDropdown}>
                                    <MoreVertIcon />
                                </IconButton>
                            )}
                        </div>
                    </MenuAppBar>

                    {item_id > CREATING_ITEM_ID && (
                        <TabMenuDropdown
                            item_id={item_id}
                            major_user_id={major_user_id}
                            owner_id={owner_id}
                            contact_id={contact_id}
                            store={store}
                            config={config}
                            enable={enable}
                            additionalDocuments={additionalDocuments}
                            dropdownAnchorEl={anchorEl}
                            onClose={this.handleCloseDropdown}
                            toggleDocModal={toggleDocModal}
                        >
                            <WrappedComponent {...this.props} />
                        </TabMenuDropdown>
                    )}
                </Fragment>
            );
        }
    }

    return TabMenuWrapperClass;
};

export default TabMenuWrapper;
