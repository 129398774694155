import React, { ReactNode, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import commonStore from '~/stores/commonStore';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';

import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';

import Divider from '~ui/Divider';
import Button from '~ui/Button';
import { AWAIT_LOADER_SHOW_TIME_MS } from '~/components/Base/LoaderAwait';
import Modal, { ModalBtnAction, ModalContent, useToggle } from '~ui/Modal';

const FilterEnableList = ({ store }: { store: ListStoreInterface }) => {
    const { listFilter } = store;
    const { enable } = listFilter;

    const handleChangeEnable = (event: SyntheticEvent, enable: boolean | null) => {
        if (typeof enable === 'boolean') {
            store.changeFilter('enable', enable);
            // store.fetchDeliveriesStoresOptions();
        }
    };

    return (
        <ToggleButtonGroup size="small" color={enable ? 'primary' : 'secondary'} value={enable} exclusive onChange={handleChangeEnable}>
            <ToggleButton value={true}>Список</ToggleButton>
            <ToggleButton value={false}>Архив</ToggleButton>
        </ToggleButtonGroup>
    );
};

const FilterModal = observer(
    ({
        children,
        handleReset,
        onClose,
        store
    }: {
        children: ReactNode;
        handleReset: () => void;
        onClose: () => void;
        store: ListStoreInterface;
    }) => {
        const { loadingList, listCount } = store;

        const actions: ModalBtnAction[] = [
            { onClick: handleReset, label: 'Очистить', variant: 'text', color: 'error' },
            {
                onClick: onClose,
                label: `Показать (${listCount})`,
                loading: loadingList,
                variant: 'contained',
                color: 'primary'
            }
        ];

        return (
            <Modal maxWidth="md" onClose={onClose} actions={actions} header="Фильтр">
                <ModalContent>
                    <Box sx={{ py: 2, gap: 3, display: 'flex', flexDirection: 'column' }}>{children}</Box>
                </ModalContent>
            </Modal>
        );
    }
);

const ListFilterWrapper = ({
    children,
    store,
    disableArchive
}: {
    children: ReactNode;
    store: ListStoreInterface;
    disableArchive?: boolean;
}) => {
    const { loadingList } = store;

    const [showModal, toggleModal] = useToggle();

    const handleRefresh = () => {
        // чтобы LoaderAwait отобразился
        store.startLoading();
        setTimeout(store.fetchList.bind(store), 2 * AWAIT_LOADER_SHOW_TIME_MS);
    };

    const handleReset = () => {
        store.startLoading();
        store.clearFilter();
        // чтобы LoaderAwait отобразился
        setTimeout(() => {
            store.debounceFilterFetch();
        }, AWAIT_LOADER_SHOW_TIME_MS);
    };

    return (
        <Paper elevation={1} sx={{ p: 1, my: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                    px: commonStore.isMobile ? 2 : undefined,
                    pt: commonStore.isMobile ? 1 : undefined
                }}
            >
                <ButtonGroup size="small" color="primary" variant="contained" disableElevation>
                    <Button loading={loadingList} tooltip="Обновить" onClick={handleRefresh}>
                        <CachedIcon />
                    </Button>
                    <Button tooltip="Очистить" onClick={handleReset} color={store.filterIsEmpty ? 'inherit' : 'secondary'}>
                        <CloseIcon />
                    </Button>
                </ButtonGroup>

                <Box sx={{ flex: 1, gap: 3, display: 'flex', alignItems: 'center' }}>
                    {!commonStore.isMobile && (
                        <Box sx={{ gap: 3, flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ gap: 3, flex: 1, display: 'flex', alignItems: 'center' }}>{children}</Box>
                            {!disableArchive && <FilterEnableList store={store} />}
                        </Box>
                    )}
                </Box>

                {commonStore.isMobile && (
                    <Button
                        color={store.filterIsEmpty ? 'inherit' : 'secondary'}
                        icon={<FilterListIcon />}
                        onClick={toggleModal}
                        tooltip="Фильтр"
                    />
                )}
                {showModal && (
                    <FilterModal store={store} handleReset={handleReset} onClose={toggleModal}>
                        {children}
                        {!disableArchive && <FilterEnableList store={store} />}
                    </FilterModal>
                )}
            </Box>
        </Paper>
    );
};

export default observer(ListFilterWrapper);
