import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import productStore from '~/stores/ff/productStore';

import TextInput from '~ui/TextInput';
import { TableCell, TableRow } from '~ui/Table';
import Button from '~ui/Button';
import CloseIcon from '@material-ui/icons/Close';

const ProductQrCodeItem = ({ product_id, index }: { product_id: number; index: number }) => {
    const handleChangeQrCode = (event: SyntheticEvent, { value }: { value: string }) => {
        productStore.changeArrayValue(product_id, 'qrCodes', index, 'qrCode', value);
    };

    const handleChangeTitle = (event: SyntheticEvent, { value }: { value: string }) => {
        productStore.changeArrayValue(product_id, 'qrCodes', index, 'title', value);
    };

    const handleDelete = () => {
        productStore.deleteArrayItem(product_id, 'qrCodes', index);
    };

    const product = productStore.getItem(product_id).editingItem;
    if (!product) {
        return null;
    }
    const { qrCode, title } = product.qrCodes[index];

    return (
        <TableRow>
            <TableCell>
                <Button icon={<CloseIcon />} tooltip="Убрать штрихкод" color="secondary" onClick={handleDelete} />
            </TableCell>
            <TableCell>
                <TextInput label="Штрихкод *" value={qrCode} onChange={handleChangeQrCode} />
            </TableCell>
            <TableCell>
                <TextInput label="Наименование" value={title} onChange={handleChangeTitle} />
            </TableCell>
        </TableRow>
    );
};

export default observer(ProductQrCodeItem);
