// const serverUrl = 'http://192.168.0.121:4000';
const serverUrl = 'https://crm-data.topstore.pro';
const feedUrl = '';

export default {
    serverUrl,
    photoUploadUrl: serverUrl + '/uploadPhotosHandler',
    feedUrl,
    pushNotifications: {},
    crmOutgoingCalls: true,
    callsServerUrl: '',
    whatsappServerUrl: 'ws://localhost:2233/'
};
