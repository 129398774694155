import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { Product, ProductList, ProductFilter } from '~/types/ff/product.types';
import { ProductMovementStatistics } from '~/types/ff/productMovement.types';
import { GQContactPhones, GQMainContactFields } from '../contactsApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { HistoryChange } from '~/types/historyChanges.types';
import { GQHistoryChange, GQUserMainFields } from '~/api/userApi';
import { GQComments } from '~/api/commentsApi';

export const GQProductMainFields = `
    product_id    
    title
`;

const GQProductFields = `
    ${GQProductMainFields}
    
    contact_id

    description
    consist

    width
    length
    height
    weight
    
    major_user_ids
    major_users {
        ${GQUserMainFields}    
    }
    
    contact {
        ${GQMainContactFields}
        ${GQContactPhones}
    }
    
    aspects {
        aspect
        value
    }
    
    qrCodes {
        qrcode_id
        qrCode
        title   
    }

    createTime
    updateTime
    enable
`;

export const fetchItem = async (product_id: number): Promise<Product> => {
    const graphql = `{
        fetchProduct(product_id: ${product_id}) {
            ${GQProductFields}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchProduct: Product }>(graphql);
    return data.fetchProduct;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    productFilter: ProductFilter,
    controller?: AbortController
): Promise<{ list: ProductList[]; count: number }> => {
    const graphql = `{
            fetchProducts(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(productFilter)}) {
                list {
                    ${GQProductFields}
                    countOnStorage               
                    comments {
                        ${GQComments}
                    }
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchProducts: { list: ProductList[]; count: number } }>(graphql, controller);
    return data.fetchProducts;
};

export const saveItem = async (product_id: number, productDiff: Product): Promise<number> => {
    const graphql = `mutation { 
        updateProduct(product_id: ${product_id}, productDiff: ${objectToGraphql(productDiff)})
    }`;

    const data = await fetchApi.postGQ<{ updateProduct: number }>(graphql);
    return data.updateProduct;
};

export const createItem = async (productInput: Product): Promise<number> => {
    const graphql = `mutation { 
          createProduct(productInput: ${objectToGraphql(productInput)})
        }`;

    const data = await fetchApi.postGQ<{ createProduct: number }>(graphql);
    return data.createProduct;
};

export const fetchItemHistory = async (product_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchProductChangeHistory(product_id: ${product_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchProductChangeHistory: HistoryChange[] }>(graphql);
    return data.fetchProductChangeHistory;
};

export const fetchCountProductsOnStore = async (product_id: number): Promise<ProductMovementStatistics> => {
    const graphql = `{ 
          fetchCountProductsOnStore (product_id: ${product_id}) {
            countOnStorage  
            countIn
            countOut
          }
        }`;

    const data = await fetchApi.getGQ<{ fetchCountProductsOnStore: ProductMovementStatistics }>(graphql);
    return data.fetchCountProductsOnStore;
};

export const fetchProductByContact = async (contact_id: number): Promise<ProductList[]> => {
    const graphql = `{ 
          fetchProductByContact (contact_id: ${contact_id}) {
            ${GQProductFields}
          }
        }`;

    const data = await fetchApi.getGQ<{ fetchProductByContact: ProductList[] }>(graphql);
    return data.fetchProductByContact;
};
