import { proxyL10nHandler } from '~/common/localizations';
import {
    DEAL_STATUS_BREAK,
    DEAL_STATUS_CLOSE,
    DEAL_STATUS_DONE,
    DEAL_STATUS_MORTGAGE_APPLY,
    DEAL_STATUS_MORTGAGE_APPROVE,
    DEAL_STATUS_MORTGAGE_EXECUTE,
    DEAL_STATUS_MORTGAGE_EXPIRED,
    DEAL_STATUS_MORTGAGE_INIT,
    DEAL_STATUS_MORTGAGE_REJECT,
    DEAL_STATUS_OPEN,
    DEAL_TYPE_ACCOMPANIMENT_ID,
    DEAL_TYPE_EXCLUSIVE_ID,
    DEAL_TYPE_FF_ID,
    DEAL_TYPE_FORKEYS_ID,
    DEAL_TYPE_MORTGAGE_ID,
    DEAL_TYPE_NEWBUILDING_ID,
    DEAL_TYPE_SEO_ID
} from '~/types/deals.types';
import { ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';

const DEAL_ESTATE_TYPE = [
    // [ESTATE_TYPE_SELL_ID, 'Продажа / Покупка'],
    // [ESTATE_TYPE_RENT_ID, 'Аренда'],
    // [DEAL_TYPE_EXCLUSIVE_ID, 'Эксклюзив'],
    // [DEAL_TYPE_ACCOMPANIMENT_ID, 'Сопровождение'],
    // [DEAL_TYPE_NEWBUILDING_ID, 'Новостройка'],
    // [DEAL_TYPE_MORTGAGE_ID, 'Ипотека'],

    [DEAL_TYPE_FF_ID, 'Фулфилмент'],
    [DEAL_TYPE_FORKEYS_ID, 'Выкупы под ключ'],
    [DEAL_TYPE_SEO_ID, 'SEO']
];

const DEAL_STATUS_TYPE = [
    [DEAL_STATUS_OPEN, 'Открыта'],
    [DEAL_STATUS_DONE, 'Проведена'],
    [DEAL_STATUS_CLOSE, 'Закрыта'],
    [DEAL_STATUS_BREAK, 'Развал']
];

const DEAL_STATUS_MORTGAGE_TYPE = [
    [DEAL_STATUS_MORTGAGE_INIT, 'Сбор документов'],
    [DEAL_STATUS_MORTGAGE_APPLY, 'Подача'],
    [DEAL_STATUS_MORTGAGE_APPROVE, 'Одобрен'],
    [DEAL_STATUS_MORTGAGE_EXECUTE, 'Выдан кредит'],
    [DEAL_STATUS_MORTGAGE_EXPIRED, 'Просроченный'],
    [DEAL_STATUS_MORTGAGE_REJECT, 'Отказ банка']
];

const dealL10n = new Proxy({}, proxyL10nHandler);

dealL10n.DEAL_STATUS_TYPE = DEAL_STATUS_TYPE;
dealL10n.DEAL_ESTATE_TYPE = DEAL_ESTATE_TYPE;
dealL10n.DEAL_STATUS_MORTGAGE_TYPE = DEAL_STATUS_MORTGAGE_TYPE;

export default dealL10n;
