import './leftMenu.less';

import React, { Fragment, PureComponent } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Icon, Menu, Button, SemanticICONS } from 'semantic-ui-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cs from 'classnames';

import { ITEM_TYPES } from '~/types/notifications.types';
import { ACCESS_COMPANY, ACCESS_GROUP, ACCESS_READ, ACCESS_SELF, AccessPermission } from '~/types/access.types';

import commonStore from '~/stores/commonStore';

import history from '../../history';
import authStore from '~/stores/authStore';
import userStore from '~/stores/userStore';
import dealStore from '~/stores/dealStore';
import taskStore from '~/stores/taskStore';
import requestStore from '~/stores/requestStore';
import contactStore from '~/stores/contactStore';
import estateStore from '~/stores/estateStore';
import showingStore from '~/stores/showingStore';
import cellCallStore from '~/stores/cell/cellCallStore';
import wishStore from '~/stores/wishStore';
import statisticStore from '~/stores/statisticStore';
import callEventStore from '~/stores/cell/callEventStore';
import learningStore from '~/stores/learningStore';
import exportStore from '~/stores/export/exportStore';
import productStore from '~/stores/ff/productStore';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import MaterialMenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import LeftMenuMobile from './LeftMenuMobile';
import settingStore from '~/stores/settingStore';
import { DEAL_TYPE_FF, DEAL_TYPE_FORKEYS, DEAL_TYPE_FORKEYS_ID, DEAL_TYPE_SEO, DEAL_TYPE_SEO_ID } from '~/types/deals.types';

const requestSubmenu: MenuItemSubmenuType[] = [
    { title: 'Список заявок', src: '/requests/list', access: requestStore.moduleName },
    { title: 'Рекрутинг', src: '/requests/hh', access: requestStore.moduleName, accessPermission: ACCESS_GROUP }
];

if (['crm.agentbase.ru', 'localhost:3000'].includes(document.location.host)) {
    requestSubmenu.push({ title: 'Фиксация цен', src: '/spp/fixPrices', access: requestStore.moduleName });
}

const MenuItems: MenuItemType[] = [
    {
        icon: 'building',
        title: 'Недвижимость',
        src: '/estate',
        access: estateStore.moduleName,
        submenu: [
            {
                title: 'Продажа / Аренда',
                createPath: `/estate/crm/sell/${CREATING_ITEM_ID}`,
                src: '/estate/crm',
                access: estateStore.moduleName
            },
            {
                title: 'Зарубежная',
                src: '/estate/foreign',
                access: estateStore.moduleName
            },
            {
                title: 'Покупатели',
                createPath: `/wishes/list/overview/${CREATING_ITEM_ID}`,
                src: '/wishes/list',
                access: wishStore.moduleName
            },
            {
                title: 'Показы',
                createPath: `/showings/list/overview/${CREATING_ITEM_ID}`,
                src: '/showings/list',
                access: showingStore.moduleName
            }
        ]
    },
    {
        icon: 'box',
        title: 'Товары',
        src: '/products',
        access: productStore.moduleName,
        submenu: [
            {
                title: 'Список товаров',
                createPath: `/products/list/overview/${CREATING_ITEM_ID}`,
                src: '/products/list',
                access: productStore.moduleName
            },
            {
                title: 'Приход товара',
                createPath: `/movements/in/overview/${CREATING_ITEM_ID}`,
                src: '/movements/in',
                access: productStore.moduleName
            },
            {
                title: 'Отгрузка товара',
                createPath: `/movements/out/overview/${CREATING_ITEM_ID}`,
                src: '/movements/out',
                access: productStore.moduleName
            }
        ]
    },
    {
        icon: 'tasks',
        title: 'Задачи',
        src: '/tasks/list',
        access: taskStore.moduleName,
        submenu: [
            {
                title: 'Список задач',
                createPath: `/tasks/list/overview/${CREATING_ITEM_ID}`,
                src: '/tasks/list',
                access: taskStore.moduleName
            }
        ]
    },
    {
        icon: 'address card',
        title: 'Контакты',
        src: '/contacts',
        access: contactStore.moduleName,
        submenu: [
            {
                title: 'Список контактов',
                createPath: `/contacts/list/profile/${CREATING_ITEM_ID}`,
                src: '/contacts/list',
                access: contactStore.moduleName
            },
            {
                title: 'Канбан доски',
                createPath: `/contacts/list/profile/${CREATING_ITEM_ID}`,
                src: '/contacts/kanban',
                access: contactStore.moduleName
            }
        ]
    },
    {
        icon: 'phone',
        title: 'Звонки',
        src: '/calls/list',
        access: cellCallStore.moduleName,
        submenu: [
            { title: 'Список звонков', src: '/calls/list', access: cellCallStore.moduleName },
            { title: 'Запланированные звонки', src: '/calls/events', access: callEventStore.moduleName }
        ]
    },
    {
        icon: 'money',
        title: 'Договоры',
        src: '/deals',
        access: dealStore.moduleName,
        submenu: [
            // {
            //     title: 'Сделки (Продажа / Покупка)',
            //     createPath: `/deals/sell/overview/${CREATING_ITEM_ID}`,
            //     src: '/deals/sell',
            //     access: dealStore.moduleName
            // },
            // { title: 'Эксклюзивы', src: '/deals/exclusive', access: dealStore.moduleName },
            // { title: 'Аренда', src: '/deals/rent', access: dealStore.moduleName },
            // { title: 'Сопровождение', src: '/deals/accompaniment', access: dealStore.moduleName },
            // { title: 'Новостройка', src: '/deals/newbuilding', access: dealStore.moduleName },
            // { title: 'Ипотека', src: '/deals/mortgage', access: dealStore.moduleName }
            {
                title: 'Фулфилмент',
                src: `/deals/${DEAL_TYPE_FF}`,
                createPath: `/deals/${DEAL_TYPE_FF}/overview/${CREATING_ITEM_ID}`,
                access: dealStore.moduleName
            },
            {
                title: 'Выкупы под ключ',
                src: `/deals/${DEAL_TYPE_FORKEYS}`,
                createPath: `/deals/${DEAL_TYPE_FF}/overview/${CREATING_ITEM_ID}`,
                access: dealStore.moduleName
            },
            {
                title: 'SEO',
                src: `/deals/${DEAL_TYPE_SEO}`,
                createPath: `/deals/${DEAL_TYPE_SEO}/overview/${CREATING_ITEM_ID}`,
                access: dealStore.moduleName
            }
        ]
    },
    {
        icon: 'wordpress forms',
        title: 'Заявки',
        src: '/requests',
        access: requestStore.moduleName,
        submenu: requestSubmenu
    },
    {
        icon: 'chart pie',
        title: 'Аналитика',
        src: '/analytics',
        access: statisticStore.moduleName,
        accessPermission: ACCESS_GROUP,
        submenu: [
            { title: 'Таблицы', src: '/analytics', access: statisticStore.moduleName },
            { title: 'KPI', src: '/kpi', access: statisticStore.moduleName, accessPermission: ACCESS_COMPANY },
            {
                title: 'Реклама',
                src: '/ads/stat',
                accessPermission: ACCESS_GROUP,
                access: exportStore.moduleName
            }
        ]
    },
    {
        icon: 'graduation',
        title: 'Обучение',
        src: '/learning',
        access: learningStore.moduleName,
        submenu: [{ title: 'Материалы', src: '/learning', access: learningStore.moduleName }]
    },
    {
        icon: 'users',
        title: 'Сотрудники',
        src: '/users',
        access: userStore.moduleName,
        submenu: [
            {
                title: 'Список сотрудников',
                createPath: `/users/list/profile/${CREATING_ITEM_ID}`,
                src: '/users/list',
                access: userStore.moduleName
            },
            {
                title: 'Чаты / Мессенджеры',
                src: '/chat',
                access: userStore.moduleName
            },
            { title: 'Офисы / Отделы', src: '/users/groups', access: 'groups' }
        ]
    },
    {
        icon: 'settings',
        title: 'Настройки',
        src: '/settings/crm',
        access: settingStore.moduleName,
        submenu: [
            {
                title: 'Настройки CRM',
                src: '/settings/crm',
                access: settingStore.moduleName
            },
            {
                title: 'Экспорт / Фиды',
                src: '/ads/price',
                accessPermission: ACCESS_COMPANY,
                access: exportStore.moduleName
            }
        ]
    }
];

type MenuItemSubmenuType = {
    icon?: SemanticICONS;
    title: string;
    src: string;
    createPath?: string;
    access?: ITEM_TYPES;
    accessPermission?: AccessPermission;
};

type MenuItemType = MenuItemSubmenuType & {
    submenu?: MenuItemSubmenuType[];
};

type CollapsedMenuProps = {
    activeItem: string;
    handleItemClick: (event: React.SyntheticEvent, data: any) => void;
    MenuItems: MenuItemType[];
};

class CollapsedMenu extends PureComponent<CollapsedMenuProps, { openedItem: string; anchorEl: null | HTMLElement }> {
    state = {
        openedItem: '',
        anchorEl: null
    };

    handleClickSubmenu = (event: React.SyntheticEvent, object: any) => {
        this.handleCloseDropdown();
        this.props.handleItemClick(event, object);
    };

    handleOpenDropdown = (event: React.SyntheticEvent<HTMLElement>, { title }: { title: string }) => {
        this.setState({ anchorEl: event.currentTarget, openedItem: title });
    };

    handleCloseDropdown = () => {
        this.setState({ anchorEl: null, openedItem: '' });
    };

    render() {
        const { activeItem, handleItemClick, MenuItems } = this.props;
        const { openedItem, anchorEl } = this.state;

        const { submenu, icon, title: titleHeader } = MenuItems.find(({ title }) => openedItem === title) || {};

        return (
            <Fragment>
                <PerfectScrollbar options={{ suppressScrollX: true }} className="crm-leftMenu__menu_collapsed">
                    <Menu icon="labeled" size="mini" vertical className="crm-leftMenu__menu">
                        <Menu.Item as={Link} to="/" title="Начальный экран" name="/" active={activeItem === '/'} onClick={handleItemClick}>
                            <Icon name="home" />
                            Главная
                        </Menu.Item>

                        {MenuItems.map(({ icon, title, src, submenu }) => (
                            <Menu.Item
                                key={src}
                                title={title}
                                name={src}
                                active={Boolean(submenu && submenu.find(({ src }) => src === activeItem))}
                                onClick={this.handleOpenDropdown}
                            >
                                <Icon name={icon} />
                                {title}
                            </Menu.Item>
                        ))}
                    </Menu>
                </PerfectScrollbar>

                <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} transition placement="right">
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: 'left middle' }}>
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                                    <div>
                                        {submenu &&
                                            submenu.map(({ title, src, createPath }, index) => (
                                                <div key={title}>
                                                    {index === 0 && (
                                                        <Link to={src} style={{ color: 'inherit' }}>
                                                            <MaterialMenuItem
                                                                onClick={event =>
                                                                    this.handleClickSubmenu(event, {
                                                                        to: src,
                                                                        name: src
                                                                    })
                                                                }
                                                            >
                                                                <b style={{ fontWeight: 500 }}>
                                                                    <Icon name={icon} /> {titleHeader}
                                                                </b>
                                                            </MaterialMenuItem>
                                                            <Divider />
                                                        </Link>
                                                    )}
                                                    <Link to={src} style={{ color: 'inherit' }}>
                                                        <MaterialMenuItem
                                                            onClick={event => this.handleClickSubmenu(event, { to: src, name: src })}
                                                            className="crm-leftMenu__menu_withPlus"
                                                        >
                                                            {title}
                                                        </MaterialMenuItem>
                                                    </Link>
                                                </div>
                                            ))}
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Fragment>
        );
    }
}

const goToCreatePath = (event: Event, pathname: string) => {
    event.preventDefault();
    event.stopPropagation();

    history.push({
        pathname,
        state: { modal: true, switchFromTab: false }
    });
};

const ExpandedMenu = ({ activeItem, handleItemClick, MenuItems }: CollapsedMenuProps) => (
    <Menu vertical className="crm-leftMenu__menu" size={commonStore.isMobile ? 'large' : null}>
        <Menu.Item as={Link} to="/" title="Главная" name="/" active={activeItem === '/'} onClick={handleItemClick}>
            <Icon name="home" />
            Начальный экран
        </Menu.Item>

        {MenuItems.map(({ icon, title, src, submenu }) => (
            <Menu.Item key={src}>
                <Icon name={icon} />
                {title}
                <Menu.Menu>
                    {submenu &&
                        submenu.map(({ title, src, createPath }, i) => (
                            <Menu.Item
                                as={Link}
                                key={title}
                                to={src}
                                name={src}
                                active={activeItem === src}
                                onClick={handleItemClick}
                                className="crm-leftMenu__menu_withPlus"
                            >
                                {title}
                                {createPath && (
                                    <Icon
                                        size="large"
                                        link
                                        name="plus circle"
                                        className="crm-leftMenu__menu_withPlusIcon"
                                        title="Добавить"
                                        onClick={e => goToCreatePath(e, createPath)}
                                    />
                                )}
                            </Menu.Item>
                        ))}
                </Menu.Menu>
            </Menu.Item>
        ))}
    </Menu>
);

type LeftMenuState = {
    activeItem: string;
};

@observer
export default class LeftMenu extends React.Component<{ fullSize: boolean }, LeftMenuState> {
    state = {
        activeItem: history.location.pathname
    };

    handleItemClick = (event: React.SyntheticEvent, { name }: any) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ activeItem: name });

        if (commonStore.showMenu) {
            commonStore.toggleMenu();
        }

        history.push(name);
    };

    toggleVisibility = (event: React.SyntheticEvent) => {
        event.preventDefault();
        commonStore.toggleMenu();
    };

    handleToggleExpandedMenu = () => {
        commonStore.toggleExpanded();
    };

    render() {
        const MenuItemsFiltered = MenuItems.filter(({ access, accessPermission }) => {
            return authStore.matchAccess(access, ACCESS_READ, accessPermission || ACCESS_SELF);
        }).map(({ icon, title, access, src, submenu }) => {
            return {
                icon,
                title,
                src,
                submenu: submenu
                    ? submenu.filter(({ access, accessPermission }) =>
                          authStore.matchAccess(access, ACCESS_READ, accessPermission || ACCESS_SELF)
                      )
                    : []
            };
        });

        const { activeItem } = this.state;
        const { fullSize } = this.props;
        const { expandedMenu, isMobile, showMenu: visible } = commonStore;

        if (isMobile) {
            return (
                <LeftMenuMobile>
                    <ExpandedMenu MenuItems={MenuItemsFiltered} activeItem={activeItem} handleItemClick={this.handleItemClick} />
                </LeftMenuMobile>
            );
        }

        return (
            <Fragment>
                <nav
                    className={cs({
                        'crm-leftMenu': true,
                        'crm-leftMenu_visible': visible,
                        'crm-Main__opacity': fullSize,
                        'crm-Print__hidden': true
                    })}
                >
                    <Link className="crm-leftMenu__logo" to="/">
                        <span className="crm-leftMenu__logo_item">
                            <img src="/static/brand/brand-min.png" alt="agentbase.ru" />
                        </span>

                        <div className="crm-leftMenu__close">
                            <Button basic icon="chevron left" onClick={this.toggleVisibility} />
                        </div>
                    </Link>

                    {expandedMenu || visible ? (
                        <PerfectScrollbar className={cs({ 'crm-leftMenu__scrollBox': true })}>
                            <ExpandedMenu MenuItems={MenuItemsFiltered} activeItem={activeItem} handleItemClick={this.handleItemClick} />
                        </PerfectScrollbar>
                    ) : (
                        <CollapsedMenu MenuItems={MenuItemsFiltered} activeItem={activeItem} handleItemClick={this.handleItemClick} />
                    )}

                    <button
                        className="crm-leftMenu__expandSwitch"
                        onClick={this.handleToggleExpandedMenu}
                        title={`${expandedMenu ? 'Свернуть' : 'Развернуть'} меню`}
                    >
                        <Icon name={expandedMenu ? 'toggle on' : 'toggle off'} color="blue" />
                        {expandedMenu && ` свернуть`}
                    </button>
                </nav>

                {visible && <div className="crm-leftMenu__pusher" onClick={this.toggleVisibility} />}
            </Fragment>
        );
    }
}
