import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'semantic-ui-react';

import nl2br from '~/common/nl2br';
import urlify from '~/common/urlify';

import productStore from '~/stores/ff/productStore';

import ShadowFragment from '~/components/Base/ShadowFragment';
import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapperNew';
import Time from '~/components/Base/Time';
import LabelArchive from '~/components/Items/Common/LabelArchive';

import ProductMainEditing from '../EditBlocks/ProductMainEditing';
import ProductSizeEditing from '../EditBlocks/ProductSizeEditing';
import ProductAspectsEditing from '../EditBlocks/ProductAspectsEditing';

import ProductAspectsShow from './ProductAspectsShow';

const ProductMainShow = (props: EditingWrappedProps) => {
    const { item_id, editing } = props;
    const { item, loadingItem } = productStore.getItem(item_id);
    if (!item) {
        return null;
    }
    const { createTime, updateTime, enable, description, title, aspects } = item;

    return (
        <Fragment>
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Card.Header>
                                {!loadingItem && !enable && <LabelArchive ribbon />}№{item_id} &nbsp;
                                <ShadowFragment print={!loadingItem && title} />
                            </Card.Header>
                            <Card.Meta>
                                <div className="date">
                                    Карточка создана <Time time={createTime} />
                                </div>
                                {updateTime > 0 && createTime !== updateTime && (
                                    <div className="date">
                                        Карточка обновлена <Time time={updateTime} />
                                    </div>
                                )}
                            </Card.Meta>
                            {aspects.length > 0 && <ProductAspectsShow aspects={aspects} />}
                            {description && (
                                <Card.Description style={{ padding: '10px 0 15px' }}>
                                    <span dangerouslySetInnerHTML={{ __html: !loadingItem && nl2br(urlify(description)) }} />
                                </Card.Description>
                            )}
                        </Fragment>
                    )}
                    {editing && (
                        <Fragment>
                            <ProductMainEditing item_id={item_id} {...item} />
                            <ProductSizeEditing simpleMode item_id={item_id} {...item} />
                            <ProductAspectsEditing item_id={item_id} {...item} />
                        </Fragment>
                    )}
                </Card.Content>
            </Card>
        </Fragment>
    );
};

export default EditingBlockWrapper(productStore, observer(ProductMainShow));
