import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { ProductAspect } from '~/types/ff/product.types';
import productStore from '~/stores/ff/productStore';

import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';

import Table, { TableBody, TableCell, TableHead, TableRow } from '~ui/Table';
import Button from '~ui/Button';
import AddIcon from '@material-ui/icons/Add';
import OpenWithIcon from '@mui/icons-material/OpenWith';

import ProductAspectsItem from './ProductAspectsItem';

type MainBlockEditingProps = HandlerEditingWrappedProps & {
    aspects?: ProductAspect[];
};

const ProductAspectsEditing = (props: MainBlockEditingProps) => {
    useMemo(() => {
        const item = {
            product_id: props.item_id,
            aspects: props.aspects || []
        };
        productStore.setEditingItem(item.product_id, item);
    }, []);

    const { item_id, handleChange, handleTextAreaChange } = props;
    const { aspects = [] } = productStore.getItem(item_id).editingItem;

    const handleAddAspect = () => {
        productStore.setEditingItem(item_id, {
            aspects: [...aspects, { aspect: '', value: '' }]
        });
    };

    return (
        <>
            <Table elevation={0}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '40px' }} />
                        <TableCell align="center">Характеристика</TableCell>
                        <TableCell style={{ width: '160px' }} align="center">
                            Значение
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {aspects.map((_, index) => (
                        <ProductAspectsItem key={`${item_id}-${index}`} product_id={item_id} index={index} />
                    ))}
                </TableBody>
            </Table>
            <Button
                size="large"
                startIcon={<OpenWithIcon />}
                endIcon={<AddIcon />}
                color="secondary"
                fullWidth
                onClick={handleAddAspect}
                tooltip={`Добавить характеристику`}
            >
                Добавить характеристику
            </Button>
        </>
    );
};

export default HandlerEditingBlockWrapper(productStore, observer(ProductAspectsEditing));
