import React from 'react';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import { ProductLinkState } from '~/components/Lists/Products/ProductLink';
import productStore from '~/stores/ff/productStore';

const ProductTabMenu = (props: TabMenuWrapperProps) => {
    return null;
};

export default TabMenuWrapper(productStore, ProductLinkState, ProductTabMenu, {
    callEventModal: true
});
