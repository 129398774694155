import React from 'react';

import { ProductMovementList } from '~/types/ff/productMovement.types';
import productL10n from '~/L10n/products.L10n';
import { TableBody, TableCell, TableRow } from '~ui/Table';

import ProductMovementLink from './ProductMovementLink';
import ListComments from '~/components/Lists/Common/ListComments';
import { ContactWithPhonesList } from '~/components/Base/ClientShowBlock';
import CutString from '~ui/CutString';

const ProductMovementsTableBody = ({ productMovements, frameMode }: { productMovements: ProductMovementList[]; frameMode?: boolean }) => {
    return (
        <TableBody>
            {productMovements.map(
                ({ product_movement_id, direction, contact, comments, planningDeliveryDate, status, itemsCount, major_user_id, about }) => (
                    <TableRow
                        key={product_movement_id}
                        positive={frameMode && direction === 'in'}
                        negative={frameMode && direction === 'out'}
                    >
                        <TableCell>
                            <ProductMovementLink productMovement={{ product_movement_id, direction }} />
                        </TableCell>
                        <TableCell>{new Date(planningDeliveryDate * 1000).toLocaleDateString('ru')}</TableCell>
                        <TableCell>{productL10n.PRODUCT_MOVEMENT_STATUS_FIND(status)}</TableCell>
                        <TableCell>{itemsCount?.toLocaleString('ru')}</TableCell>
                        <TableCell>
                            <ListComments comments={comments} item_id={product_movement_id} item_type="productMovement" />
                        </TableCell>
                        <TableCell>
                            <CutString title={about} />
                        </TableCell>
                        {!frameMode && (
                            <TableCell>
                                <ContactWithPhonesList contact={contact} />
                            </TableCell>
                        )}
                    </TableRow>
                )
            )}
        </TableBody>
    );
};

export default ProductMovementsTableBody;
