import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react';

import documentStore from '~/stores/documentStore';
import { ITEM_TYPES } from '~/types/notifications.types';

import InputAdornment from '@material-ui/core/InputAdornment';
import LinkIcon from '@material-ui/icons/Link';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';

import Button from '~ui/Button';

const DocumentsUrlUpload = ({ item_id, item_type }: { item_id: number; item_type: ITEM_TYPES }) => {
    const { documentDirs, loadingDirs, documents, loadingDocuments, loadingError } = documentStore;

    const [url, setUrl] = useState('');

    const handleChangeUrl = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setUrl(value);
    };

    const urlIsGood = url.match(/^https?:\/\//);

    const sendUrlDocument = async () => {
        await documentStore.addLinkDocument(item_id, item_type, url);
        setUrl('');
    };

    return (
        <TextField
            label="Ссылка на документ"
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <LinkIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <Button
                            disabled={loadingDocuments || !urlIsGood}
                            icon={<AddIcon />}
                            color="primary"
                            tooltip="Добавить"
                            onClick={sendUrlDocument}
                        />
                    </InputAdornment>
                )
            }}
            margin="normal"
            value={url || ''}
            onChange={handleChangeUrl}
            type="url"
            error={url && !urlIsGood}
        />
    );
};

export default observer(DocumentsUrlUpload);
