import React from 'react';
import { observer } from 'mobx-react';
import { Card } from 'semantic-ui-react';
import { QrCodeType } from '~/types/ff/product.types';

import productStore from '~/stores/ff/productStore';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import QrCodeIcon from '@mui/icons-material/QrCode';

import ProductQrCodesEditing from '../EditBlocks/ProductQrCodesEditing';

import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapperNew';

import SkeletonTable from '~ui/SkeletonTable';
import { WarningMessage } from '~ui/Message';

const ProductMovementList = ({ qrCodes, loading }: { qrCodes: QrCodeType[]; loading: boolean }) => {
    if (loading) {
        return <SkeletonTable columns={1} rows={2} wrapToTable></SkeletonTable>;
    }
    if (!qrCodes.length) {
        return <WarningMessage header="Нет штрихкода">Добавьте штрихкод(ы) товара</WarningMessage>;
    }
    return (
        <List>
            {qrCodes.map(({ qrCode, title, qrcode_id }) => (
                <ListItem key={qrcode_id}>
                    <ListItemIcon>
                        <QrCodeIcon />
                    </ListItemIcon>
                    <ListItemText primary={qrCode} secondary={title} />
                </ListItem>
            ))}
        </List>
    );
};

const ProductQrCodesShow = (props: EditingWrappedProps) => {
    const { item_id, editing } = props;
    const { item, loadingItem } = productStore.getItem(item_id);
    const { qrCodes = [] } = item || {};

    return (
        <Card fluid color="blue">
            <Card.Content>
                {!editing && <ProductMovementList qrCodes={qrCodes} loading={loadingItem} />}
                {editing && (
                    <div style={{ marginTop: '1.5rem' }}>
                        <ProductQrCodesEditing
                            item_id={item_id}
                            qrCodes={qrCodes.map(({ qrCode, title, qrcode_id }) => ({ qrCode, title, qrcode_id }))}
                        />
                    </div>
                )}
            </Card.Content>
        </Card>
    );
};

export default EditingBlockWrapper(productStore, observer(ProductQrCodesShow));
