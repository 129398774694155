import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { ProductMovement } from '~/types/ff/productMovement.types';
import productMovementStore from '~/stores/ff/productMovementStore';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';
import DatePicker from '~ui/DatePicker';

import ProductMovementSelectDestination from './ProductMovementSelectDestination';
import Box from '@material-ui/core/Box';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';

type MainBlockEditingProps = HandlerEditingWrappedProps & {
    direction?: ProductMovement['direction'];
    count?: number;
    planningDeliveryDate?: number;
    destination_id?: number | null;
    about?: string;
};

const ProductMovementMainEditing = (props: MainBlockEditingProps) => {
    useMemo(() => {
        const { item_id: product_movement_id } = props;
        productMovementStore.setEditingItem(product_movement_id, {
            product_movement_id,
            about: props.about || '',
            planningDeliveryDate: props.planningDeliveryDate || Math.floor(Date.now() / 1000),
            destination_id: props.destination_id || null
        });
        if (props.direction) {
            productMovementStore.setEditingItem(product_movement_id, {
                direction: props.direction
            });
        }
    }, []);

    const { item_id, handleChange, handleDate, handleTextAreaChange } = props;

    const {
        editingItem: { direction, about, planningDeliveryDate, destination_id }
    } = productMovementStore.getItem(item_id);

    const handleDeliveryDate = (planningDeliveryDate: number) => {
        productMovementStore.setEditingItem(item_id, { planningDeliveryDate });
    };

    const handleChangeDestination = (destination_id: number | null) => {
        productMovementStore.setEditingItem(item_id, { destination_id });
    };

    return (
        <Box display="flex" flexDirection="column" style={{ gap: '1.5rem' }}>
            {direction === 'out' && (
                <Grid.Column>
                    <ProductMovementSelectDestination destination_id={destination_id} onChange={handleChangeDestination} />
                </Grid.Column>
            )}
            <DatePicker
                label={`Планируемая дата ${direction === 'in' ? 'прихода' : 'отгрузки'}`}
                value={planningDeliveryDate}
                onChange={handleDeliveryDate}
            />
            <TextInput
                label="Описание"
                fullWidth
                value={about}
                onChange={handleChange}
                name="about"
                variant="classic"
                multiline
                rows={2}
                rowsMax={4}
            />
        </Box>
    );
};

export default HandlerEditingBlockWrapper(productMovementStore, observer(ProductMovementMainEditing));
