import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import contactPaymentStore from '~/stores/ff/contactPaymentStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import { Modal, ModalBtnAction, ModalContent, ModalHeader } from '~ui/Modal';
import { PriceInput } from '~ui/TextInput';
import MoneyIcon from '@mui/icons-material/Money';

const ContactPaymentModal = ({ contact_id, onClose }: { contact_id: number; onClose: () => void }) => {
    useMemo(() => {
        contactPaymentStore.fetchItem(CREATING_ITEM_ID);
        contactPaymentStore.setEditingItem(CREATING_ITEM_ID, { contact_id, payment: 0 });
    }, []);

    const handlePayment = (_, { value }: { value: number }) => {
        contactPaymentStore.setEditingItem(CREATING_ITEM_ID, { payment: value });
    };

    const {
        editingItem: { payment },
        loadingItem
    } = contactPaymentStore.getItem(CREATING_ITEM_ID);

    const handleCreate = async () => {
        if (await contactPaymentStore.createItem()) {
            onClose();
        }
    };

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'primary' },
        { onClick: handleCreate, label: 'Зачислить', variant: 'outlined', color: 'primary', disabled: !payment || loadingItem }
    ];

    return (
        <Modal
            onClose={onClose}
            maxWidth="xs"
            header={
                <>
                    <MoneyIcon />
                    &nbsp;Добавить платеж
                </>
            }
            actions={actions}
            transition={'slideUp'}
            loading={loadingItem}
        >
            <ModalContent>
                <PriceInput value={payment} onChange={handlePayment} />
            </ModalContent>
        </Modal>
    );
};

export default observer(ContactPaymentModal);
