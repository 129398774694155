import React, { Fragment } from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import TaskLink from '../Lists/Tasks/TaskLink';
import UserLinkWithPreview from '../Lists/Users/UserLinkWithPreview';
import { ITEM_TYPES, ITEM_TYPES_OBJECT } from '~/types/notifications.types';
import { ESTATE_BASE_MAIN, ESTATE_BASE_MLS, ESTATE_BASE_OWNERS } from '~/types/estate.types';
import EstateLinkWithPreview from '../Lists/Estate/EstateLinkWithPreview';
import ContactLinkWithPreview from '../Lists/Contacts/ContactLinkWithPreview';
import ShowingLink from '../Lists/Showings/ShowingLink';
import DealLink from '../Lists/Deals/DealLink';
import WishLinkWithPreview from '../Lists/Wishes/WishLinkWithPreview';
import MeetingEventLink from '~/components/CalendarEvents/MeetingEvents/MeetingEventLink';
import CallEventLink from '~/components/Lists/CallEvents/CallEventLink';
import RequestLink from '~/components/Lists/Requests/RequestLink';
import { ExportBaseLink } from '~/components/Ads/Export';
import ProductLink from '~/components/Lists/Products/ProductLink';
import ProductMovementLink from '~/components/Lists/ProductsMovements/ProductMovementLink';

export const matchIconName = (item_type: ITEM_TYPES): SemanticICONS | null => {
    switch (item_type) {
        case 'task':
            return 'tasks';
        case 'estate':
        case 'export':
        case 'mls':
            return 'building outline';
        case 'user':
            return 'user';
        case 'contact':
            return 'address card outline';
        case 'callEvent':
            return 'phone';
        case 'showing':
            return 'compass';
        case 'meetingEvent':
            return 'handshake outline';
        case 'owner':
            return 'male';
        case 'deal':
            return 'money';
        case 'expiredCall':
            return 'tty';
        case 'wish':
            return 'spy';
        case 'groups':
            return 'group';
        case 'request':
            return 'wordpress forms';
        case 'cellCall':
            return 'sign-in';
        case 'learning':
            return 'graduation';
        case 'access':
            return 'key';
        case 'exportingBase':
            return 'database';
        case 'product':
            return 'box';
        case 'productMovement':
            return 'truck';
        case 'chat':
        case 'tag':
        case 'share':
        case 'statistics':
        case 'externalestate':
        case 'comment':
        case 'trigger':
        case 'callSource':
        case 'settings':
        case 'calendar':
        case 'balance':
        case 'mortgagePerson':
            return null;
        default:
            const error: never = item_type;
    }
};

const ItemLink = ({ item_type, item }: { item_type: ITEM_TYPES; item: ITEM_TYPES_OBJECT }) => {
    const {
        estate_id,
        wish_id,
        type,
        propertyType,
        roomsCount,
        address,
        houseNumberAdjusted,
        task_id,
        createTime,
        firstName,
        lastName,
        user_id,
        enable,
        showingTime,
        showing_id,
        deal_id,
        meeting_event_id,
        call_event_id,
        startTime,
        endTime,
        callTimeMin,
        callTimeMax,
        request_id,
        export_base_id,
        name,
        product_id,
        title,
        product_movement_id,
        direction
    } = item || {};

    switch (item_type) {
        case 'task':
            return task_id && createTime ? <TaskLink task={{ task_id, createTime }} /> : null;

        case 'user':
            return firstName && lastName && user_id ? (
                <UserLinkWithPreview user={{ firstName, lastName, middleName: '', user_id, enable }} />
            ) : null;

        case 'contact':
        case 'expiredCall':
            const { contact_id, contactType, companyName } = item;
            return contact_id && contactType ? (
                <>
                    {item_type === 'expiredCall' && <span>просроченный звонок: </span>}
                    <ContactLinkWithPreview
                        contact={{
                            contact_id,
                            contactType,
                            companyName: companyName || '',
                            firstName: firstName || '',
                            lastName: lastName || '',
                            middleName: ''
                        }}
                    />
                </>
            ) : null;

        case 'estate':
        case 'export':
        case 'cellCall':
            return estate_id && type && propertyType && address ? (
                <Fragment>
                    {item_type === 'export' && <span>экспорт: </span>}
                    {item_type === 'cellCall' && <span>прикреплен звонок: </span>}
                    <EstateLinkWithPreview
                        estate={{ estate_id, type, propertyType, roomsCount: roomsCount || null, address, enable, houseNumberAdjusted }}
                        base={ESTATE_BASE_MAIN}
                    />
                </Fragment>
            ) : null;

        case 'mls':
            return estate_id && type && propertyType && address ? (
                <EstateLinkWithPreview
                    estate={{ estate_id, type, propertyType, roomsCount: roomsCount || null, address, houseNumberAdjusted }}
                    base={ESTATE_BASE_MLS}
                />
            ) : null;

        case 'owner':
            return estate_id && type && propertyType && address ? (
                <EstateLinkWithPreview
                    estate={{ estate_id, type, propertyType, roomsCount: roomsCount || null, address, houseNumberAdjusted }}
                    base={ESTATE_BASE_OWNERS}
                />
            ) : null;

        case 'wish':
            return wish_id && type ? <WishLinkWithPreview wish={{ wish_id, enable: Boolean(enable), type }} /> : null;

        case 'showing':
            return showingTime && showing_id ? <ShowingLink showing={{ showingTime, showing_id }} /> : null;

        case 'deal':
            return deal_id && type ? <DealLink deal={{ deal_id, type, enable: Boolean(enable) }} /> : null;

        case 'groups':
            return <>Отдел/офис</>;

        case 'meetingEvent':
            return meeting_event_id && startTime && endTime ? (
                <MeetingEventLink meetingEvent={{ meeting_event_id, startTime, endTime }} />
            ) : null;

        case 'callEvent':
            return call_event_id && callTimeMax && callTimeMin ? (
                <CallEventLink callEvent={{ call_event_id, callHasReleased: false, callTimeMin, callTimeMax }} hideIcon />
            ) : null;

        case 'tag':
            return <>Тэг</>;
        case 'settings':
            return <>Настройки</>;
        case 'access':
            return <>Настройка доступа</>;
        case 'learning':
            return <>Обучающие материалы</>;

        case 'request':
            return request_id ? <RequestLink request={{ request_id, type, propertyType, enable }} /> : null;

        case 'exportingBase':
            return export_base_id && name ? <ExportBaseLink export_base_id={export_base_id} name={name} /> : null;
        case 'product':
            return product_id && title ? <ProductLink product={{ product_id, title }} /> : null;
        case 'productMovement':
            return product_movement_id && direction ? <ProductMovementLink productMovement={{ product_movement_id, direction }} /> : null;

        case 'chat':
        case 'share':
        case 'statistics':
        case 'externalestate':
        case 'comment':
        case 'trigger':
        case 'callSource':
        case 'calendar':
        case 'balance':
        case 'mortgagePerson':
            return <>{item_type}</>;

        default:
            const error: never = item_type;
    }
};

export default ItemLink;
