import React from 'react';
import { Link } from 'react-router-dom';
import { ProductMovementLink as ProductMovementLinkType } from '~/types/ff/productMovement.types';

type ProductMovementLinkProps = {
    productMovement: ProductMovementLinkType;
};

export const ProductMovementLinkState = (product_movement_id: number, direction: ProductMovementLinkType['direction']) => ({
    pathname: `/movements/${direction}/overview/${product_movement_id}`,
    state: { modal: true, switchFromTab: false }
});

export const productMovementLinkTitle = ({ product_movement_id, direction }: ProductMovementLinkProps['productMovement']): string => {
    return `№${product_movement_id} ${direction === 'in' ? 'приход' : 'отгрузка'}`;
};

const ProductMovementLink = ({ productMovement: { product_movement_id, direction } }: ProductMovementLinkProps) => {
    return (
        <Link to={ProductMovementLinkState(product_movement_id, direction)}>
            {productMovementLinkTitle({ product_movement_id, direction })}
        </Link>
    );
};

export default ProductMovementLink;
