import React from 'react';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import { ProductMovementLinkState } from '~/components/Lists/ProductsMovements/ProductMovementLink';
import productMovementStore from '~/stores/ff/productMovementStore';

const ProductMovementTabMenu = (props: TabMenuWrapperProps) => {
    return null;
};

export default TabMenuWrapper(productMovementStore, ProductMovementLinkState, ProductMovementTabMenu, {});
