import React, { ReactNode, SyntheticEvent, ReactElement, useState, CSSProperties, useEffect, useCallback, useReducer } from 'react';

import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import Button from '~ui/Button';
import DialogContentText from '@mui/material/DialogContentText';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import LoaderAwait from '~/components/Base/LoaderAwait';
import { grey } from '@mui/material/colors';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const useToggleModal = (initialState?: boolean): [boolean, () => void] => {
    return useReducer(state => !state, initialState || false);
};

export const useToggle = useToggleModal;

// const styles = ({ spacing, palette }: Theme) =>
//     createStyles({
//         root: {
//             margin: 0,
//             padding: spacing(2)
//         },
//         // closeButton: {
//         //     position: 'absolute',
//         //     right: spacing(1),
//         //     top: spacing(1),
//         //     color: palette.grey[500]
//         // },
//         // closeButtonContrast: {
//         //     color: palette.primary.contrastText
//         // },
//         // titleHeader: {
//         //     display: 'flex',
//         //     alignItems: 'center'
//         // }
//     });

// interface DialogTitleProps extends WithStyles<typeof styles> {
//     children: React.ReactNode;
//     onClose?: (event: SyntheticEvent, reason: string) => void;
// }

const DialogTitle = (props: { children: React.ReactNode; onClose?: (event: SyntheticEvent, reason: string) => void }) => {
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle sx={{ margin: 0, padding: 2 }} {...other}>
            <Typography
                component="p"
                variant="h6"
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {children}
            </Typography>
            {onClose ? (
                <Button
                    tooltip="Закрыть окно"
                    icon={<CloseIcon />}
                    sx={{
                        position: 'absolute',
                        right: '0.5rem',
                        top: '0.5rem',
                        color: grey[500]
                    }}
                    onClick={event => onClose(event, '')}
                />
            ) : null}
        </MuiDialogTitle>
    );
};

type ModalHeaderProps = {
    children: ReactNode;
    onClose?: (event: SyntheticEvent, reason: string) => void;
};

// const useStylesModalHeader = makeStyles(({ palette, spacing }: Theme) =>
//     createStyles({
//         root: {
//             background: palette.primary.main,
//             color: palette.primary.contrastText,
//             paddingBottom: '2rem'
//         }
//     })
// );

export const ModalHeader = React.memo((props: ModalHeaderProps) => {
    return <DialogTitle onClose={props.onClose}>{props.children}</DialogTitle>;
});

// const useStylesModalActions = makeStyles(({ palette, spacing }: Theme) =>
//     createStyles({
//         root: {
//             paddingTop: 0
//         }
//     })
// );

export const ModalActions = (props: { children: ReactNode }) => {
    return <DialogActions>{props.children}</DialogActions>;
};

// const ModalContentStyles = (theme: Theme) => ({
//     root: {
//         padding: theme.spacing(2)
//     }
// });

type ModalContentProps = {
    children: ReactNode;
    onClose?: () => void;
    dividers?: boolean;
    style?: CSSProperties;
    className?: string;
    id?: string;
    sx?: SxProps<Theme>;
};

// const useStylesModalContent = makeStyles(({ palette, spacing }: Theme) =>
//     createStyles({
//         root: {
//             position: 'relative',
//             top: '-1rem',
//             background: 'white',
//             borderRadius: '1rem 1rem 0 0'
//         }
//     })
// );

export const ModalContent = (props: ModalContentProps) => {
    const { children, ...other } = props;

    return <MuiDialogContent {...other}>{children}</MuiDialogContent>;
};

export const ModalContentText = DialogContentText;

export type ModalBtnAction = {
    onClick: (event: SyntheticEvent) => void;
    label: ReactNode;
    disabled?: boolean;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'default';
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    loading?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
};

type ModalProps = {
    children: ReactNode;
    header?: ReactNode;
    actions?: ReactElement | ModalBtnAction[];
    onClose?: (event: SyntheticEvent, reason: string) => void;
    fullScreen?: boolean;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    loading?: boolean;
    trigger?: ReactNode;
    transition?: 'slideUp';
    style?: CSSProperties;
    onMount?: () => void;
    disableBackdropClick?: boolean;
    disableCloseBtn?: boolean;
};

const TransitionSlideUp = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Modal = (props: ModalProps) => {
    const {
        onMount,
        onClose,
        style,
        children,
        header,
        actions,
        fullScreen,
        fullWidth,
        maxWidth,
        loading,
        trigger,
        transition,
        disableCloseBtn
    } = props;

    const [open, setOpen] = useState(!trigger);
    const handleToggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    useEffect(() => {
        if (onMount) {
            onMount();
        }
    });

    const handleClose = onClose || (!disableCloseBtn ? handleToggleOpen : undefined);

    return (
        <>
            {trigger && <span onClick={handleToggleOpen}>{trigger}</span>}
            <Dialog
                onClose={handleClose}
                open={open}
                fullScreen={fullScreen}
                fullWidth={typeof fullWidth === 'boolean' ? fullWidth : Boolean(maxWidth)}
                maxWidth={maxWidth || 'md'}
                TransitionComponent={transition === 'slideUp' ? TransitionSlideUp : undefined}
                PaperProps={{ style, sx: { background: '#eef0f1' } }}
            >
                {header && <ModalHeader onClose={handleClose}>{header}</ModalHeader>}
                {children}
                {actions && !Array.isArray(actions) && <DialogActions>{actions}</DialogActions>}
                {Array.isArray(actions) && (
                    <ModalActions>
                        {actions.map(({ onClick, variant, label, loading, disabled, color, startIcon, endIcon }, index) => (
                            <Button
                                key={`btn-${index}`}
                                disabled={disabled}
                                color={color === 'default' ? 'inherit' : color}
                                onClick={onClick}
                                variant={variant || 'contained'}
                                endIcon={endIcon}
                                startIcon={startIcon}
                                loading={loading}
                            >
                                {label}
                            </Button>
                        ))}
                    </ModalActions>
                )}

                <LoaderAwait dimmer active={loading} />
            </Dialog>
        </>
    );
};

export default Modal;
