import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Grid } from 'semantic-ui-react';

import Comments from '../Common/Comments/index';

import dealStore from '~/stores/dealStore';

import DealPropertyShowing from './ShowBlocks/DealPropertyShowing';
import DealCustomersShowing from './ShowBlocks/DealCustomersShowing';
import DealStatusShowing from './ShowBlocks/DealStatusShowing';
import DealPriceShowing from './ShowBlocks/DealPriceShowing';
import DealPaymentsShowing from './ShowBlocks/DealPaymentsShowing';
import DealTypeShowing from './ShowBlocks/DealTypeShowing';
import LoaderAwait from '../../Base/LoaderAwait';

type DealShowModeProps = {
    deal_id: number;
};

@observer
class DealShowMode extends Component<DealShowModeProps> {
    render() {
        const { deal_id } = this.props;
        const { loadingItem, item } = dealStore.getItem(deal_id);
        const type = item?.type || 0;

        return (
            <Grid stackable>
                <Grid.Column width={8}>
                    <DealTypeShowing item_id={deal_id} />
                    {!dealStore.whetherIsNotEstate(type) && <DealPropertyShowing item_id={deal_id} />}
                    <DealCustomersShowing item_id={deal_id} />
                    <DealPriceShowing item_id={deal_id} />
                    <DealPaymentsShowing item_id={deal_id} />

                    <LoaderAwait active={loadingItem} dimmer />
                </Grid.Column>

                <Grid.Column width={8}>
                    <DealStatusShowing item_id={deal_id} />
                    <br />
                    {!loadingItem && <Comments item_id={deal_id} item_type="DEAL" />}
                </Grid.Column>
            </Grid>
        );
    }
}

export default DealShowMode;
