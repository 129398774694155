import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Icon, List } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import PhonesList from '../../../Base/PhonesList';

export const matchSourceUrl = (source: string, sourceInnerId: string): string => {
    switch (source) {
        case 'cian.ru':
            return `https://cian.ru/sale/flat/${sourceInnerId}/`;
        case 'yandex.ru':
            return `https://realty.yandex.ru/offer/${sourceInnerId}/`;
        case 'avito.ru':
            return `https://avito.ru/items/${sourceInnerId}/`;
        case 'irr.ru':
            return `http://irr.ru/advert${sourceInnerId}.html`;

        case 'youla.io':
            return `https://youla.ru/a/a/a/a-${sourceInnerId}`;

        case 'n1.ru':
        case 'n2.ru':
            return `https://n1.ru/view/${sourceInnerId}/`;

        case 'kupiprodai.ru':
            return `https://kupiprodai.ru/search?searching=${sourceInnerId}`;

        case 'sob.ru':
            return `https://sob.ru/prodazha-komnat-moskva/card-${sourceInnerId}`;

        case 'domclick.ru':
            return `https://domclick.ru/card/sale__flat__${sourceInnerId}`;
        default:
            return source;
    }
};

@observer
class ExternalBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id } = this.props;
        const { item } = estateStore.getItem(item_id);
        const { source, sourceInnerId, agencyName, externalPhones } = item || {};

        return (
            <Card fluid color="green">
                <Card.Content>
                    <Grid columns={2}>
                        <Grid.Column>
                            <b>Источник</b>
                        </Grid.Column>
                        <Grid.Column>
                            <a
                                title="Открыть ссылку на объявление в новом окне"
                                target="_blank"
                                href={matchSourceUrl(source, sourceInnerId)}
                                rel="noreferrer"
                            >
                                {source} <Icon name="external" />
                            </a>
                        </Grid.Column>
                    </Grid>

                    <Card.Description>
                        <Grid columns={1}>
                            <Grid.Column>{agencyName}</Grid.Column>
                        </Grid>
                        <List>
                            <PhonesList phones={externalPhones} />
                        </List>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }
}

export default ExternalBlockShow;
