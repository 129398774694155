import { UserLinkType } from '../users.types';
import { ContactLinkWithPhonesType } from '../contacts.types';
import { CommentType } from '../comments.types';
import { Product, ProductLink } from './product.types';

export type ProductMovementItemsInBase = {
    product_movement_item_id: number;
    product_movement_id: number;
    product_id: number;
    count: number;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type ProductMovementItem = ProductMovementItemsInBase & {
    product: Product;
};

export enum ProductMovementStatus {
    WaitingForExecution = 1,
    Done = 2,
    Cancel = 100
}

export type ProductMovementLink = {
    product_movement_id: number;
    direction: 'in' | 'out';
};

export type ProductMovementInBase = ProductMovementLink & {
    contact_id: number;
    destination_id: number | null;
    about: string;
    status: ProductMovementStatus;
    major_user_id: number;
    planningDeliveryDate: number;
    executeDeliveryDate: number | null;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type ProductMovementItemShort = {
    product_id: number | null;
    count: number;
    product?: ProductLink;
};

type ProductMovementExtended = ProductMovementInBase & {
    contact: ContactLinkWithPhonesType;
    major_user_ids: number[];
    major_users: UserLinkType[];
};

export type ProductMovement = ProductMovementExtended & {
    items: ProductMovementItemShort[];
};

export type ProductMovementList = ProductMovementExtended & {
    itemsCount: number;
    comments: CommentType[];
};

export type ProductMovementFilter = {
    direction: ProductMovement['direction'];
    product_id: number[];
    group_id: number[];
    major_user_id: number[];
    enable: boolean;
};

export type ProductMovementDestination = {
    destination_id: number;
    name: string;
};

export type ProductMovementStatistics = {
    countOnStorage: number;
    countIn: number;
    countOut: number;
};
