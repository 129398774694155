import React from 'react';
import { observer } from 'mobx-react';

import Table from '~ui/Table';
import ListFooter from '~/components/Lists/Common/ListFooterNew';
import { WarningMessage } from '~ui/Message';
import productStore from '~/stores/ff/productStore';

import ProductMovementsTableHeader from '~/components/Lists/ProductsMovements/ProductMovementsTableHeader';
import ProductMovementsTableBody from '~/components/Lists/ProductsMovements/ProductMovementsTableBody';

const ProductMovementsTable = ({ product_id }: { product_id: number }) => {
    const handlePageChange = (activePage: number) => {
        // contactStore.changeCellCallsPaginationPage(this.props.contact_id, activePage);
    };

    const handlePageSizeChange = (pageSize: number) => {
        // contactStore.changeCellCallsPaginationPageSize(this.props.contact_id, pageSize);
    };

    const { productMovementsLoading, productMovementsCount, productMovementsPagination, productMovements } =
        productStore.getItem(product_id).property;

    return (
        <>
            <Table size="small" compact>
                <ProductMovementsTableHeader frameMode />

                <ProductMovementsTableBody productMovements={productMovements} frameMode />

                <ListFooter
                    loading={productMovementsLoading}
                    colSpan={5}
                    pagination={productMovementsPagination}
                    listCount={productMovementsCount || 0}
                    pageChange={handlePageChange}
                    pageSizeChange={handlePageSizeChange}
                    hideGoToPage
                />
            </Table>

            {!productMovementsLoading && productMovementsCount === 0 && <WarningMessage>Движения не найдены</WarningMessage>}
        </>
    );
};

export default observer(ProductMovementsTable);
