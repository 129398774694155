import React, { useState, useEffect } from 'react';
import * as contactPaymentApi from '~/api/ff/contactPaymentApi';

import Table, { TableBody, TableRow, TableCell, TableHead } from '~ui/Table';
import SkeletonTable from '~ui/SkeletonTable';

import ListError from '~/components/ListError';
import ListPagination from '~/components/Lists/Common/ListPaginationNew';
import Time from '~/components/Base/Time';
import { PaginationType } from '~/stores/prototypes/ListStore.prototype';
import { getDefaultPagination, matchPaginationTotalPages } from '~/common/pagination';
import { BalanceTransaction } from '~/api/ff/contactPaymentApi';

const ContactPaymentModeTable = ({ contact_id }: { contact_id: number }) => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [balanceTransactions, setBalanceTransactions] = useState<BalanceTransaction[]>([]);
    const [pagination, setPagination] = useState<PaginationType>({ ...getDefaultPagination(), pageSize: 100 });
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        const { pageSize, activePage } = pagination;
        contactPaymentApi
            .fetchContactTransactions(contact_id, pageSize, (activePage - 1) * pageSize)
            .then(({ list, count }) => {
                setBalanceTransactions(list);
                setPagination(matchPaginationTotalPages(pagination, count));
                setCount(count);
            })
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, [pagination.activePage, pagination.pageSize]);

    const handlePageChange = (activePage: number) => {
        setPagination({ ...pagination, activePage });
    };

    const pageSizeChange = (pageSize: number) => {
        setPagination({ pageSize: pageSize, activePage: 1, totalPages: 1 });
    };

    return (
        <>
            <ListError errors={errors} />
            <Table compact>
                <TableHead>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell>Операция</TableCell>
                        <TableCell>Сумма</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && <SkeletonTable rows={3} columns={3} />}
                    {balanceTransactions.map(bt => {
                        let cost: number;
                        let time: number;
                        const { direction } = bt;

                        if (direction === 'in') {
                            const { payment } = bt;
                            cost = payment.payment;
                            time = payment.createTime;
                        } else {
                            // const { transaction } = bt;
                            // cost = transaction.cost;
                            // time = transaction.createTime;
                        }
                        return (
                            <TableRow key={time} positive={direction === 'in' && cost > 0} negative={direction === 'in' && cost < 0}>
                                <TableCell>
                                    <Time time={time} />
                                </TableCell>
                                <TableCell>
                                    {bt.direction === 'in' &&
                                        (bt.payment.type === 'gift' ? 'подарок' : cost > 0 ? 'пополнение' : 'списание')}
                                    {/*{bt.direction === 'out' && matchTypeName(bt.transaction.itemType)}*/}
                                </TableCell>
                                <TableCell>
                                    {bt.direction === 'in' ? (cost > 0 ? '+' : '') : '-'}
                                    {cost} ₽
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <ListPagination
                    pagination={pagination}
                    listCount={count}
                    pageChange={handlePageChange}
                    pageSizeChange={pageSizeChange}
                    colSpan={3}
                    showPult
                />
            </Table>
        </>
    );
};

export default ContactPaymentModeTable;
