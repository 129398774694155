import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, List, Icon, Tab, Placeholder } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import dealStore from '~/stores/dealStore';

import DealCustomersEditing from '../EditBlocks/DealCustomersEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import { ContactLinkType } from '~/types/contacts.types';
import ContactLinkWithPreview from '~/components/Lists/Contacts/ContactLinkWithPreview';
import { DEAL_TYPE_ACCOMPANIMENT_ID, DEAL_TYPE_MORTGAGE_ID, DEAL_TYPE_NEWBUILDING_ID } from '~/types/deals.types';

const panes = (sellers: ContactLinkType[], buyers: ContactLinkType[], dealType: number) =>
    dealStore.whetherIsNotEstate(dealType)
        ? [
              {
                  menuItem: `Заказчик (${buyers.length})`,
                  render: () => (
                      <Tab.Pane>
                          <List>
                              {buyers &&
                                  buyers.map(contact => (
                                      <List.Item key={contact.contact_id}>
                                          <div className="crm-Estate__fieldNowrap">
                                              <Icon name="vcard" />
                                              <ContactLinkWithPreview contact={contact} />
                                          </div>
                                      </List.Item>
                                  ))}
                          </List>
                      </Tab.Pane>
                  )
              }
          ]
        : [
              {
                  menuItem: `Продавец (${sellers.length})`,
                  render: () => (
                      <Tab.Pane>
                          <List>
                              {sellers &&
                                  sellers.map(contact => (
                                      <List.Item key={contact.contact_id}>
                                          <div className="crm-Estate__fieldNowrap">
                                              <Icon name="vcard" />
                                              <ContactLinkWithPreview contact={contact} />
                                          </div>
                                      </List.Item>
                                  ))}
                          </List>
                      </Tab.Pane>
                  )
              },
              {
                  menuItem: `Покупатель (${buyers.length})`,
                  render: () => (
                      <Tab.Pane>
                          <List>
                              {buyers &&
                                  buyers.map(contact => (
                                      <List.Item key={contact.contact_id}>
                                          <div className="crm-Estate__fieldNowrap">
                                              <Icon name="vcard" />
                                              <ContactLinkWithPreview contact={contact} />
                                          </div>
                                      </List.Item>
                                  ))}
                          </List>
                      </Tab.Pane>
                  )
              }
          ];

@observer
class DealCustomersShowing extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = dealStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { sellers, buyers, buyers_ids, sellers_ids, type } = item;

        return (
            <Fragment>
                {!editing && (
                    <Tab
                        panes={panes(sellers, buyers, type)}
                        defaultActiveIndex={
                            [DEAL_TYPE_MORTGAGE_ID, DEAL_TYPE_ACCOMPANIMENT_ID, DEAL_TYPE_NEWBUILDING_ID].includes(type) ? 1 : 0
                        }
                    />
                )}
                {editing && (
                    <Card fluid color="red">
                        <Card.Content>
                            <DealCustomersEditing
                                item_id={item_id}
                                editing={editing}
                                sellers_ids={Array.from(sellers_ids)}
                                sellers={Array.from(sellers)}
                                buyers_ids={Array.from(buyers_ids)}
                                buyers={Array.from(buyers)}
                            />
                        </Card.Content>
                    </Card>
                )}
            </Fragment>
        );
    }
}

export default EditingBlockWrapper(dealStore, DealCustomersShowing);
