import React from 'react';
import { observer } from 'mobx-react';
import history from '~/history';

import productStore from '~/stores/ff/productStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import authStore from '~/stores/authStore';

import Container from '@mui/material/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';

import Table, { TableHead } from '~ui/Table';
import Button from '~ui/Button';

import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';

import ProductTableBody from './ProductTableBody';
import ProductTableHeader from './ProductTableHeader';
import ListFooter from '../Common/ListFooterNew';

import ListError from '~/components/ListError';
import ListPagination from '~/components/Lists/Common/ListPaginationNew';
import { ProductLinkState } from './ProductLink';
import ProductsFilterList from './ProductsFilterList';

const ProductsList = (props: ListWrapperProps) => {
    const { orderBy, orderDirection, handleSort } = props;
    const { loadingList, list, listCount, pagination, nextListLoaded, listErrors } = productStore;

    const handleCreateProduct = () => {
        history.push(ProductLinkState(CREATING_ITEM_ID));
    };

    return (
        <Container maxWidth="xl" id="crm_ListTop" sx={{ pb: 3 }}>
            <div className="crm-List" style={{ marginBottom: '1rem' }}>
                <Box display="flex" alignItems="center">
                    <TakeoutDiningIcon fontSize="large" style={{ paddingRight: '0.5rem' }} />
                    <Typography variant="h5" style={{ paddingRight: '0.5rem' }}>
                        Товары
                    </Typography>
                </Box>

                {authStore.canCreate(productStore.moduleName) && (
                    <div className="crm-List__addButton">
                        <Button
                            startIcon={<TakeoutDiningIcon fontSize="large" style={{ paddingRight: '0.5rem' }} />}
                            primary
                            onClick={handleCreateProduct}
                            variant="contained"
                        >
                            Добавить
                        </Button>
                    </div>
                )}

                {listErrors && <ListError errors={listErrors} />}
            </div>

            <ProductsFilterList />

            <Table size="small">
                <TableHead>
                    <ListPagination
                        loading={loadingList}
                        colSpan={5}
                        pagination={pagination}
                        listCount={listCount}
                        pageChange={productStore.pageChange}
                        pageSizeChange={productStore.pageSizeChange}
                        nextListLoaded={nextListLoaded}
                    />
                </TableHead>
                <ProductTableHeader orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                <ProductTableBody productList={list} />

                <ListFooter
                    loading={loadingList}
                    colSpan={5}
                    pagination={pagination}
                    listCount={listCount}
                    pageChange={productStore.pageChange}
                    pageSizeChange={productStore.pageSizeChange}
                    nextListLoaded={nextListLoaded}
                    scrollToElementId="crm_ListTop"
                />
            </Table>
        </Container>
    );
};

export default ListWrapper(productStore, observer(ProductsList));
