import { ContactPaymentType } from '~/types/ff/contactPayment.types';
import { objectToGraphql } from '~/common/graphql';
import fetchApi from '~/common/fetchApi';

export const createItem = async (inputContactPayment: Partial<ContactPaymentType>): Promise<number> => {
    const graphql = `mutation { 
          createContactPayment(inputContactPayment: ${objectToGraphql(inputContactPayment)})
        }`;

    const data = await fetchApi.postGQ<{ createContactPayment: number }>(graphql);
    return data.createContactPayment;
};

export const fetchContactBalance = async (contact_id: number): Promise<number> => {
    const graphql = `{
        fetchContactBalance(contact_id: ${contact_id})
      }`;

    const data = await fetchApi.getGQ<{ fetchContactBalance: number }>(graphql);
    return data.fetchContactBalance;
};

export type BalanceTransaction = {
    direction: 'in';
    payment: {
        type: 'order' | 'gift';
        payment: number;
        createTime: number;
    };
};

export const fetchContactTransactions = async (
    contact_id: number,
    limit: number,
    offset: number
): Promise<{ list: BalanceTransaction[]; count: number }> => {
    const graphql = `{
            fetchContactTransactions(contact_id: ${contact_id}, limit: ${limit}, offset: ${offset}) {
                list {
                    direction
                    payment {
                        type
                        payment
                        createTime   
                    }
                 
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchContactTransactions: { list: BalanceTransaction[]; count: number } }>(graphql);
    return data.fetchContactTransactions;
};
