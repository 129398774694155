import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { QrCodeType } from '~/types/ff/product.types';
import productStore from '~/stores/ff/productStore';

import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';

import Table, { TableBody, TableCell, TableHead, TableRow } from '~ui/Table';
import Button from '~ui/Button';
import AddIcon from '@material-ui/icons/Add';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import ProductQrCodeItem from './ProductQrCodeItem';

type MainBlockEditingProps = HandlerEditingWrappedProps & {
    qrCodes?: QrCodeType[];
};

const ProductQrCodeEditing = (props: MainBlockEditingProps) => {
    useMemo(() => {
        const item = {
            product_id: props.item_id,
            qrCodes: props.qrCodes || []
        };
        productStore.setEditingItem(item.product_id, item);
    }, []);

    const { item_id, handleChange, handleTextAreaChange } = props;
    const { qrCodes = [] } = productStore.getItem(item_id).editingItem;

    const handleAddQrCode = () => {
        productStore.setEditingItem(item_id, {
            qrCodes: [...qrCodes, { qrcode_id: CREATING_ITEM_ID, qrCode: '', title: '' }]
        });
    };

    return (
        <>
            <Table elevation={0}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '40px' }} />
                        <TableCell align="center">Штрихкод</TableCell>
                        <TableCell style={{ width: '160px' }} align="center">
                            Описание
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {qrCodes.map(({ qrCode, title, qrcode_id }, index) => (
                        <ProductQrCodeItem key={`${qrcode_id}-${index}`} product_id={item_id} index={index} />
                    ))}
                </TableBody>
            </Table>
            <Button
                size="large"
                startIcon={<QrCodeIcon />}
                endIcon={<AddIcon />}
                color="secondary"
                fullWidth
                onClick={handleAddQrCode}
                tooltip={`Добавить штрихкод`}
            >
                Добавить штрихкод
            </Button>
        </>
    );
};

export default HandlerEditingBlockWrapper(productStore, observer(ProductQrCodeEditing));
