import React from 'react';

import Table, { TableBody, TableCell, TableRow } from '~ui/Table';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonTable = ({ rows, columns, wrapToTable }: { rows: number; columns: number; wrapToTable?: boolean }) => {
    const rowsArr = new Array(rows).fill(0);
    const columnsArr = new Array(columns).fill(0);

    const Columns = columnsArr.map((key, indexCol) => (
        <TableCell key={indexCol}>
            <Skeleton animation="wave" />
        </TableCell>
    ));

    const Rows = rowsArr.map((key, indexRow) => <TableRow key={indexRow}>{Columns}</TableRow>);

    if (!wrapToTable) {
        return <>{Rows}</>;
    }
    return (
        <Table>
            <TableBody>{Rows}</TableBody>
        </Table>
    );
};

export default React.memo(SkeletonTable);
