import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import productStore from '~/stores/ff/productStore';

import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';

type MainBlockEditingProps = HandlerEditingWrappedProps & {
    title?: string;
    description?: string;
};

const ProductMainEditing = (props: MainBlockEditingProps) => {
    useMemo(() => {
        const item = {
            product_id: props.item_id,
            title: props.title || '',
            description: props.description || ''
        };
        productStore.setEditingItem(item.product_id, item);
    }, []);

    const { item_id, handleChange, handleTextAreaChange } = props;
    const { title, description } = productStore.getItem(item_id).editingItem;

    return (
        <Grid>
            <Grid.Column>
                <TextInput
                    label="Название товара"
                    size="small"
                    value={title || ''}
                    name="title"
                    onChange={handleChange}
                    variant="classic"
                />
            </Grid.Column>
            <Grid.Column>
                <TextInput
                    label="Описание"
                    value={description}
                    name="description"
                    onChange={handleChange}
                    multiline
                    rows={1}
                    rowsMax={5}
                    variant="classic"
                />
            </Grid.Column>
        </Grid>
    );
};

export default HandlerEditingBlockWrapper(productStore, observer(ProductMainEditing));
