import React from 'react';
import { observer } from 'mobx-react';

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import HistoryIcon from '@material-ui/icons/History';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import ItemErrors from '../ItemErrors';
import { ItemWrapperState, TAB_ID_HISTORY } from '../ItemWrapper';
import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT } from '../ItemWrapper';
import productStore from '~/stores/ff/productStore';

import ProductTabMenu from './ProductTabMenu';
import { MenuItem } from '~/components/Items/TabMenuWrapper';

import ProductAddMode from './ProductAddMode';
import ProductShowMode from './ProductShowMode';
import ProductHistoryMode from './ProductHistoryMode';
import ProductMovementMode from './ProductMovementMode';

const TAB_MOVEMENT = 'movement';

const ProductItem = ({ item_id, tabName, param }: ItemWrapperState) => {
    const { errors, item } = productStore.getItem(item_id);
    const { enable, major_user_ids, contact_id } = item || {};

    const productTabs: MenuItem[] = [
        { name: 'Описание', id: '', icon: <FormatListNumberedIcon /> },
        { name: 'Движение', id: TAB_MOVEMENT, icon: <LocalShippingIcon /> },
        { name: 'История', id: TAB_ID_HISTORY, icon: <HistoryIcon /> }
    ];

    return (
        <>
            <ProductTabMenu
                itemTabs={productTabs}
                item_id={item_id}
                tabName={tabName}
                enable={Boolean(enable)}
                major_user_id={major_user_ids}
                contact_id={contact_id}
            />
            <div className="crm-List__sidebar_modalBox">
                {errors && <ItemErrors errors={errors} tabName={tabName} />}
                {tabName === TAB_ID_DEFAULT && <ProductShowMode product_id={item_id} />}
                {tabName === TAB_ID_CREATING && <ProductAddMode />}
                {tabName === TAB_ID_HISTORY && <ProductHistoryMode item_id={item_id} />}
                {tabName === TAB_MOVEMENT && <ProductMovementMode product_id={item_id} />}
            </div>
        </>
    );
};

export default ItemWrapper(productStore, observer(ProductItem));
