import React from 'react';
import { observer } from 'mobx-react';

import productStore from '~/stores/ff/productStore';
import { useStoreHooks } from '~/common/listFilterHooks';

import Checkbox from '~ui/Checkbox';

import ListFilterWrapper from '../ListFilterWrapperNew';

const ProductsFilterList = () => {
    const { handleCheckbox } = useStoreHooks(productStore);

    const { listFilter } = productStore;
    const { onlyInStock } = listFilter;

    return (
        <ListFilterWrapper store={productStore}>
            <Checkbox name="onlyInStock" label="Только в наличии" checked={onlyInStock} onChange={handleCheckbox} />
        </ListFilterWrapper>
    );
};

export default observer(ProductsFilterList);
