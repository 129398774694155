import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import history from '~/history';
import productMovementStore from '~/stores/ff/productMovementStore';

import ContactIdBlockEditing from '~/components/Items/Common/ContactIDBlockEditing';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';

type ProductContactIdEditingProps = HandlerEditingWrappedProps & {
    contact_id?: number;
};

const ProductMovementContactIdEditing = (props: ProductContactIdEditingProps) => {
    useMemo(() => {
        const {
            location: {
                state: { from }
            }
        } = history;

        const item = {
            contact_id: props.contact_id || (from && from.storeName === 'contactStore' ? from.item_id : null)
        };

        productMovementStore.setEditingItem(props.item_id, item);
    }, []);

    const { item_id } = props;
    const { contact_id } = productMovementStore.getItem(item_id).editingItem;

    return (
        <div className="crm-Item__editingMode">
            <label className="crm-Estate__field_label">Контакт</label>
            <div className="crm-Item__contactEditingDropdown">
                <ContactIdBlockEditing store={productMovementStore} editing={contact_id === null} item_id={item_id} value={contact_id} />
            </div>
        </div>
    );
};

export default HandlerEditingBlockWrapper(productMovementStore, observer(ProductMovementContactIdEditing));
