import React from 'react';
import { TableCell, TableHead, TableRow } from '~ui/Table';

const ProductMovementsTableHeader = ({ frameMode }: { frameMode?: boolean }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Кол-во</TableCell>
                <TableCell>Комментарии</TableCell>
                <TableCell>Описание</TableCell>
                {!frameMode && <TableCell>Контакт</TableCell>}
            </TableRow>
        </TableHead>
    );
};

export default ProductMovementsTableHeader;
