import * as productApi from '~/api/ff/productApi';
import * as productMovementApi from '~/api/ff/productMovementApi';

import { Product, ProductList, ProductFilter } from '~/types/ff/product.types';
import { ProductMovementStatistics, ProductMovementList } from '~/types/ff/productMovement.types';
import ListStorePrototype, { PaginationType } from '../prototypes/ListStore.prototype';
import { HistoryChange } from '~/types/historyChanges.types';
import { action } from 'mobx';
import { getDefaultPagination, matchPaginationTotalPages } from '~/common/pagination';

type ProductItemPropertyType = {
    loadingHistory: boolean;
    history: HistoryChange[];

    productMovements: ProductMovementList[];
    productMovementsCount: number;
    productMovementsLoading: boolean;
    productMovementsPagination: PaginationType;

    productOnStoreStat: ProductMovementStatistics;
    loadingCount: boolean;
};

class ProductStore extends ListStorePrototype<Product, ProductList, ProductItemPropertyType, ProductFilter> {
    listFilterClear: ProductFilter = {
        enable: true,
        group_id: [],
        major_user_id: [],
        onlyInStock: false
    };

    orderBy = 'updateTime';

    constructor() {
        super('product_id', 'product', productApi);
        this.clearFilter();
    }

    validationItem(product: Product): string[] {
        const errors: string[] = [];

        if (!product.contact_id) {
            errors.push('Заполните "Контакт"');
        }
        if (!product.title) {
            errors.push('Заполните "Название"');
        }

        return errors;
    }

    @action
    async productMovementsByProductId(product_id: number) {
        const productMovementsPagination = this.getItem(product_id).property.productMovementsPagination || getDefaultPagination();

        const { activePage, pageSize } = productMovementsPagination;
        const offset = (activePage - 1) * pageSize;

        this.setProperty(product_id, { productMovements: [], productMovementsLoading: true, productMovementsPagination });

        const { list, count } = await productMovementApi.fetchList(pageSize, offset, 'product_movement_id', 'descending', {
            product_id: [product_id]
        });

        this.setProperty(product_id, {
            productMovements: list,
            productMovementsLoading: false,
            productMovementsPagination: matchPaginationTotalPages(productMovementsPagination, count),
            productMovementsCount: count
        });
    }

    @action
    async fetchCountOnStore(product_id: number): Promise<void> {
        this.setProperty(product_id, { loadingCount: true });
        const productOnStoreStat = await productApi.fetchCountProductsOnStore(product_id);
        this.setProperty(product_id, { productOnStoreStat, loadingCount: false });
    }
}

export default new ProductStore();
