import React from 'react';
import { TableCell, TableRow, TableBody } from '~ui/Table';

import Time from '~/components/Base/Time';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { ProductAspect, ProductList } from '~/types/ff/product.types';
import { ContactWithPhonesList } from '~/components/Base/ClientShowBlock';
import ProductLink from '~/components/Lists/Products/ProductLink';
import UsersLinkList from '~/components/Lists/Users/UsersLinkList';
import ListComments from '~/components/Lists/Common/ListComments';

type ProductTableBodyProps = {
    productList: ProductList[];
};

const ProductAspects = ({ aspects }: { aspects: ProductAspect[] }) => {
    return (
        <Stack direction="row" spacing={1}>
            {aspects.map(({ aspect, value }) => (
                <Box key={aspect}>
                    {aspect}: {value}
                </Box>
            ))}
        </Stack>
    );
};

const ProductTableBody = (props: ProductTableBodyProps) => {
    const { productList } = props;
    if (!productList?.length) {
        return null;
    }

    return (
        <TableBody>
            {productList.map(({ product_id, countOnStorage, title, aspects, updateTime, contact, major_users, comments }) => {
                return (
                    <TableRow key={product_id}>
                        <TableCell>
                            <Time time={updateTime} />
                        </TableCell>
                        <TableCell>
                            <ProductLink product={{ product_id, title }} />
                            <ProductAspects aspects={aspects} />
                        </TableCell>
                        <TableCell>
                            <ContactWithPhonesList contact={contact} />
                        </TableCell>
                        <TableCell>{countOnStorage?.toLocaleString('ru')}</TableCell>
                        <TableCell>
                            <ListComments comments={comments} item_id={product_id} item_type="product" />
                        </TableCell>
                        <TableCell>
                            <UsersLinkList usersList={major_users} />
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

export default ProductTableBody;
