import { observable, action, runInAction } from 'mobx';
import * as contactApi from '~/api/contactsApi';
import * as productApi from '~/api/ff/productApi';
import ListWithContactsStorePrototype from './prototypes/listWithContactsStore.prototype';

import { Contact, ContactFilter, ContactLinkType, ContactTableItem } from '~/types/contacts.types';
import * as estateApi from '~/api/estateApi';
import * as showingApi from '~/api/showingApi';
import { requestsByContact } from '~/api/requestApi';
import debounce from '../common/debounce';
import { Estate } from '~/types/estate.types';
import { ShowingTableItem } from '~/types/showings.types';
import globalSearchStore from './globalSearchStore';
import { SearchModuleResult } from './globalSearchStore';
import { ContactLinkState, matchContactTitle } from '~/components/Lists/Contacts/ContactLink';
import { RequestTableType } from '~/types/requests.types';
import { Deal } from '~/types/deals.types';
import { dealsByContact } from '~/api/dealApi';
import { CellCall } from '~/types/cellCalls.types';
import { fetchCellCallsByContact } from '~/api/cell/cellCallsApi';
import { PaginationType } from './prototypes/ListStore.prototype';
import { getDefaultPagination, matchPaginationTotalPages } from '~/common/pagination';
import { CallEvent, MeetingEvent } from '~/types/events.types';
import * as callEventApi from '~/api/cell/callEventApi';
import { Wish } from '~/types/wish.types';
import { wishesByContact } from '~/api/wishApi';
import { meetingEventsByContact } from '~/api/meetingEventApi';
import { nProgressItem, withAbortController } from './helpers/decorators.helpers';
import escapedRegExp from '../common/escapedRegExp';
import { UserLinkType } from '~/types/users.types';
import { DaDataNameSuggestion } from '~/types/dadata.types';
import * as dadataApi from '~/api/dadataApi';

import { ProductList } from '~/types/ff/product.types';
import { ProductMovementInBase } from '~/types/ff/productMovement.types';
import { productMovementsByContact } from '~/api/ff/productMovementApi';
import * as contactPaymentApi from '~/api/ff/contactPaymentApi';

export type ContactItemPropertyType = {
    searchNameQuery: string;
    estates: Estate[];
    estatesLoading: boolean;
    showings: ShowingTableItem[];
    showingsLoading: boolean;
    requests: RequestTableType[];
    requestsLoading: boolean;
    deals: Deal[];
    dealsLoading: boolean;
    wishes: Wish[];
    wishesLoading: boolean;
    meetingEvents: MeetingEvent[];
    meetingEventsLoading: boolean;
    productMovements: ProductMovementInBase[];
    productMovementsLoading: boolean;

    cellCalls: CellCall[];
    cellCallsCount: number;
    cellCallsLoading: boolean;
    cellCallsPagination: PaginationType;

    contactIsAlreadyCreated: ContactLinkType | UserLinkType | null;

    loadingContactCallsEvents: boolean;
    contactCallsEvents: CallEvent[];

    mergingInProgress: boolean;
    mergingErrors: string[];

    productsLoading: boolean;
    products: ProductList[];
    productsErrors: string[];

    balance: number | null;
    balanceErrors: string[];
};

class ContactStore extends ListWithContactsStorePrototype<Contact, ContactTableItem, ContactItemPropertyType, ContactFilter> {
    listFilterClear = {
        group_id: [],
        major_user_id: [],
        tags_ids: [],
        search: '',
        enable: true,
        withoutAgent: false,
        tagsTogether: false,
        showEstates: false,
        commentsContain: null,
        hasCallEvent: null,
        kanban_list_id: [],
        noOutgoingCallsTime: null
    };

    @observable
    selectedItemsIds: number[] = [];

    orderBy = 'updateTime';

    constructor() {
        super('contact_id', 'contact', contactApi);
        this.clearFilter();

        globalSearchStore.regModule({
            title: 'Контакты',
            moduleName: this.moduleName,
            search: this.globalSearchList
        });
    }

    globalSearchList = async (
        abortController: AbortController,
        query: string,
        start: number,
        limit: number
    ): Promise<SearchModuleResult> => {
        const { list, count } = await contactApi.fetchList(
            limit,
            start,
            'match',
            'descending',
            {
                search: query,
                withoutAgent: true
            },
            abortController
        );

        return {
            list: list.map(({ contact_id, firstName, lastName, phones }) => ({
                key: contact_id,
                title: `${lastName || ''} ${firstName || ''}`.trim(),
                description: phones[0] && phones[0].phone ? `+${phones[0].phone}` : '',
                state: ContactLinkState(contact_id),
                className: this.moduleName
            })),
            count
        };
    };

    @observable
    loadingNames = false;
    names: DaDataNameSuggestion[] = [];
    nameQuery: string;

    @action
    async fetchNames(query: string) {
        this.nameQuery = query;
        this.debounceFetchNames();
    }

    @action
    async fetchNamesCount(query: string, count: number) {
        runInAction(() => {
            this.loadingNames = true;
        });
        const names = await dadataApi.fetchNamesSuggestions(query, count);
        runInAction(() => {
            this.names = names;
            this.loadingNames = false;
        });
    }

    debounceFetchNames = debounce(() => {
        (async () => {
            await this.fetchNamesCount(this.nameQuery, 7);
        })();
    }, 350);

    dropdownList: ContactTableItem[] = [];
    dropdownAC: AbortController | null = null;

    @withAbortController('dropdownAC')
    @action
    async fetchItemDropdownOptions(search: string) {
        this.loadingDropdownOptions = true;
        this.dropdownList = (
            await contactApi.fetchList(20, 0, 'updateTime', 'descending', { search, withoutAgent: true }, this.dropdownAC)
        ).list;

        const searchReg = escapedRegExp(search);

        this.itemDropdownOptions = this.dropdownList.map(
            ({ contact_id, contactType, companyName, firstName, lastName, middleName, phones }) => {
                let text = `№${contact_id} :: ${matchContactTitle({
                    contact_id,
                    contactType,
                    companyName,
                    firstName,
                    lastName,
                    middleName
                })}${phones[0] && phones[0].phone ? `   +${phones[0].phone}` : ''}`.trim();
                if (!text.match(searchReg)) {
                    text += ` // ${search}`;
                }

                return {
                    key: contact_id,
                    value: contact_id,
                    text
                };
            }
        );

        runInAction(() => {
            this.loadingDropdownOptions = false;
        });
    }

    @action
    async estatesByContact(contact_id: number) {
        this.setProperty(contact_id, { estates: [], estatesLoading: true });
        const estates = await estateApi.estatesByContact(contact_id);
        this.setProperty(contact_id, { estates, estatesLoading: false });
    }

    @action
    async showingsByContact(contact_id: number) {
        this.setProperty(contact_id, { showings: [], showingsLoading: true });
        const showings = await showingApi.fetchShowingsByItem('contact', contact_id, true);
        this.setProperty(contact_id, { showings, showingsLoading: false });
    }

    @action
    async requestsByContact(contact_id: number) {
        this.setProperty(contact_id, { requests: [], requestsLoading: true });
        const requests = await requestsByContact(contact_id);
        this.setProperty(contact_id, { requests, requestsLoading: false });
    }

    @action
    async dealsByContact(contact_id: number) {
        this.setProperty(contact_id, { deals: [], dealsLoading: true });
        const deals = await dealsByContact(contact_id);
        this.setProperty(contact_id, { deals, dealsLoading: false });
    }

    @action
    async wishsByContact(contact_id: number) {
        // wishs не следует менять на wishes
        this.setProperty(contact_id, { wishes: [], wishesLoading: true });
        const wishes = await wishesByContact(contact_id);
        this.setProperty(contact_id, { wishes, wishesLoading: false });
    }

    @action
    async meetingEventsByContact(contact_id: number) {
        this.setProperty(contact_id, { meetingEvents: [], meetingEventsLoading: true });
        const meetingEvents = await meetingEventsByContact(contact_id);
        this.setProperty(contact_id, { meetingEvents, meetingEventsLoading: false });
    }

    @action
    async productMovementsByContact(contact_id: number) {
        this.setProperty(contact_id, { productMovements: [], productMovementsLoading: true });
        const productMovements = await productMovementsByContact(contact_id);
        this.setProperty(contact_id, { productMovements, productMovementsLoading: false });
    }

    @action
    async cellCallsByContact(contact_id: number) {
        const cellCallsPagination = this.getItem(contact_id).property.cellCallsPagination || getDefaultPagination();

        const { activePage, pageSize } = cellCallsPagination;
        const offset = (activePage - 1) * pageSize;

        this.setProperty(contact_id, { cellCalls: [], cellCallsLoading: true, cellCallsPagination });

        const { list, count } = await fetchCellCallsByContact(contact_id, pageSize, offset);

        this.setProperty(contact_id, {
            cellCalls: list,
            cellCallsLoading: false,
            cellCallsPagination: matchPaginationTotalPages(cellCallsPagination, count),
            cellCallsCount: count
        });
    }

    @action
    async fetchCallsEventsByContact(contact_id: number) {
        if (!this.isItemExist(contact_id)) {
            return;
        }
        this.setProperty(contact_id, { loadingContactCallsEvents: true, contactCallsEvents: [] });
        const contactCallsEvents = await callEventApi.fetchCallsEventsByContact(contact_id);
        this.setProperty(contact_id, { loadingContactCallsEvents: false, contactCallsEvents });
    }

    @nProgressItem
    @action
    async changeCallEventReleased(contact_id: number, call_event_id: number, released: boolean) {
        const { contactCallsEvents = [] } = this.getItem(contact_id).property;
        const foundCallEvent = contactCallsEvents.find(callEvent => callEvent.call_event_id === call_event_id);

        if (foundCallEvent) {
            foundCallEvent.callHasReleased = released;
            await callEventApi.changeCallReleased(call_event_id, released);
        }
    }

    @action
    async changeCellCallsPaginationPage(contact_id: number, pageNumber: number) {
        const { cellCallsPagination } = this.getItem(contact_id).property;
        cellCallsPagination.activePage = pageNumber;
        this.cellCallsByContact(contact_id);
    }

    @action
    async changeCellCallsPaginationPageSize(contact_id: number, pageSize: number) {
        const cellCallsPagination = { pageSize: pageSize, activePage: 1, totalPages: 1 };
        this.setProperty(contact_id, { cellCallsPagination });
        this.cellCallsByContact(contact_id);
    }

    @action
    addTag(contact_id: number, tag_id: number) {
        const { tags_ids } = this.getItem(contact_id).editingItem;
        if (!tags_ids.includes(tag_id)) {
            tags_ids.push(tag_id);
        }
    }

    @action
    removeTag(contact_id: number, tag_id: number) {
        const { tags_ids } = this.getItem(contact_id).editingItem;
        const foundIndex = tags_ids.findIndex(tId => tId === tag_id);
        if (~foundIndex) {
            tags_ids.splice(foundIndex, 1);
            this.getItem(contact_id).editingItem['tags_ids'] = [...tags_ids];
        }
    }

    @action
    async checkWhetherPhoneIsNotUsed(contact_id: number, phone: string) {
        const contact = await contactApi.checkWhetherPhoneIsNotUsed(contact_id, phone);

        if (contact) {
            this.setProperty(contact_id, { contactIsAlreadyCreated: contact });
        }
    }

    @action
    setEditingItem(id: number, editingProps: Partial<Contact>) {
        if (editingProps.phones) {
            this.setProperty(id, { contactIsAlreadyCreated: null });
        }
        super.setEditingItem(id, editingProps);
    }

    @action
    async mergeTwoContacts(contact_id: number, merge_with_contact_id: number, mergeType: 1 | 2) {
        this.setProperty(contact_id, { mergingInProgress: true, mergingErrors: [] });
        try {
            const firstContactId = mergeType === 1 ? contact_id : merge_with_contact_id;
            const secondContactId = mergeType === 1 ? merge_with_contact_id : contact_id;
            return await contactApi.mergeTwoContacts(firstContactId, secondContactId);
        } catch (mergingErrors) {
            this.setProperty(contact_id, { mergingErrors });
        } finally {
            this.setProperty(contact_id, { mergingInProgress: false });
        }
    }

    @action
    async fetchAllContact(contact_id: number) {
        this.fetchItem(contact_id, null, true);
        this.estatesByContact(contact_id);
        this.requestsByContact(contact_id);
        this.dealsByContact(contact_id);
        this.wishsByContact(contact_id);
        this.meetingEventsByContact(contact_id);
        this.cellCallsByContact(contact_id);
    }

    @nProgressItem
    @action
    async takeContactToWork(contact_id: number) {
        if (await contactApi.takeContactToWork(contact_id)) {
            this.reloadItem(contact_id);
        }
    }

    @action
    async fetchProductsByContact(contact_id: number): Promise<void> {
        this.setProperty(contact_id, { products: [], productsErrors: [], productsLoading: true });
        try {
            const products = await productApi.fetchProductByContact(contact_id);
            this.setProperty(contact_id, { products });
        } catch (productsErrors) {
            this.setProperty(contact_id, { productsErrors });
        } finally {
            this.setProperty(contact_id, { productsLoading: false });
        }
    }

    @action
    async fetchContactBalance(contact_id: number): Promise<void> {
        try {
            const balance = await contactPaymentApi.fetchContactBalance(contact_id);
            this.setProperty(contact_id, { balance });
        } catch (balanceErrors) {
            this.setProperty(contact_id, { balance: null, balanceErrors });
        }
    }
}

export default new ContactStore();
