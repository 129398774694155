import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import productStore from '~/stores/ff/productStore';

import TextInput from '~ui/TextInput';
import { TableCell, TableRow } from '~ui/Table';
import Button from '~ui/Button';
import CloseIcon from '@material-ui/icons/Close';

const ProductAspectItem = ({ product_id, index }: { product_id: number; index: number }) => {
    const handleChangeAspect = (event: SyntheticEvent, { value }: { value: string }) => {
        productStore.changeArrayValue(product_id, 'aspects', index, 'aspect', value);
    };

    const handleChangeAspectValue = (event: SyntheticEvent, { value }: { value: string }) => {
        productStore.changeArrayValue(product_id, 'aspects', index, 'value', value);
    };

    const handleDelete = () => {
        productStore.deleteArrayItem(product_id, 'aspects', index);
    };

    const product = productStore.getItem(product_id).editingItem;
    if (!product) {
        return null;
    }
    const { aspect, value } = product.aspects[index];

    return (
        <TableRow>
            <TableCell>
                <Button icon={<CloseIcon />} tooltip="Убрать характеристику" color="secondary" onClick={handleDelete} />
            </TableCell>
            <TableCell>
                <TextInput label="Характеристика" maxLength={255} value={aspect} onChange={handleChangeAspect} />
            </TableCell>
            <TableCell>
                <TextInput label="Значение" maxLength={255} value={value} onChange={handleChangeAspectValue} />
            </TableCell>
        </TableRow>
    );
};

export default observer(ProductAspectItem);
