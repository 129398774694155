import { action, observable } from 'mobx';
import * as productMovementApi from '~/api/ff/productMovementApi';

import {
    ProductMovement,
    ProductMovementDestination,
    ProductMovementFilter,
    ProductMovementList,
    ProductMovementStatus
} from '~/types/ff/productMovement.types';
import ListStorePrototype from '../prototypes/ListStore.prototype';
import { nProgress } from '~/stores/helpers/decorators.helpers';

class ProductMovementStore extends ListStorePrototype<ProductMovement, ProductMovementList, {}, ProductMovementFilter> {
    listFilterClear: ProductMovementFilter = {
        direction: 'in',
        product_id: [],
        major_user_id: [],
        group_id: [],
        enable: true
    };

    orderBy = 'updateTime';

    constructor() {
        super('product_movement_id', 'productMovement', productMovementApi);
        this.clearFilter();
        this.loadDestinations();
    }

    @observable
    destinations: ProductMovementDestination[] = [];
    @observable
    loadingDestinations = true;

    @action
    async loadDestinations(): Promise<void> {
        this.loadingDestinations = true;
        this.destinations = await productMovementApi.fetchMovementDestinations();
        this.loadingDestinations = false;
    }

    @action
    setRouteFilter(param: string) {
        switch (param) {
            case 'in':
                this.listFilter['direction'] = 'in';
                break;
            case 'out':
                this.listFilter['direction'] = 'out';
                break;
            default:
                break;
        }
    }

    @nProgress
    @action
    async createDestination(name: string): Promise<number> {
        this.loadingDestinations = true;
        const destination_id = await productMovementApi.createMovementDestinations(name);
        await this.loadDestinations();
        return destination_id;
    }

    @action
    async saveItem(id: number): Promise<boolean | Partial<ProductMovement>> {
        const { items } = this.getItem(id).editingItem;
        if (items) {
            this.getItem(id).loadingItem = true;
        }
        const result = await super.saveItem(id);
        if (typeof result === 'object' && result['items']) {
            this.getItem(id).item = null;
            await this.fetchItem(id, null, true);
        }
        return result;
    }

    @nProgress
    @action
    async changeMovementStatus(product_movement_id: number, status: ProductMovementStatus) {
        this.mergeItem(product_movement_id, { status });
        if (status === ProductMovementStatus.Done) {
            this.mergeItem(product_movement_id, { executeDeliveryDate: Math.floor(Date.now() / 1000) });
        }
        await productMovementApi.changeMovementStatus(product_movement_id, status);
    }
}

export default new ProductMovementStore();
