import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'semantic-ui-react';
import { ProductMovementItemShort } from '~/types/ff/productMovement.types';

import productMovementStore from '~/stores/ff/productMovementStore';

import ProductMovementItemsEditing from '../EditBlocks/ProductMovementItemsEditing';

import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapperNew';

import Table, { TableBody, TableCell, TableRow, TableHead } from '~ui/Table';
import ProductLink from '~/components/Lists/Products/ProductLink';
import SkeletonTable from '~ui/SkeletonTable';

const ProductMovementItemsTable = ({ items, loading }: { items: ProductMovementItemShort[]; loading: boolean }) => {
    return (
        <Table compact elevation={0}>
            <TableHead>
                <TableRow>
                    <TableCell>Товар</TableCell>
                    <TableCell>Количество</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loading && <SkeletonTable columns={2} rows={3} />}
                {!loading &&
                    items.map(({ product_id, count, product }) => (
                        <TableRow key={product_id}>
                            <TableCell>
                                <ProductLink product={product} />
                            </TableCell>
                            <TableCell>{count}</TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

const ProductMovementItemsShow = (props: EditingWrappedProps) => {
    const { item_id, editing } = props;
    const { item, loadingItem } = productMovementStore.getItem(item_id);
    const { items = [], contact_id } = item || {};

    return (
        <Fragment>
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && <ProductMovementItemsTable items={items} loading={loadingItem} />}
                    {editing && (
                        <div style={{ marginTop: '1.5rem' }}>
                            <ProductMovementItemsEditing
                                product_movement_id={item_id}
                                contact_id={contact_id}
                                items={items.map(({ product_id, count }) => ({ product_id, count }))}
                            />
                        </div>
                    )}
                </Card.Content>
            </Card>
        </Fragment>
    );
};

export default EditingBlockWrapper(productMovementStore, observer(ProductMovementItemsShow));
