import React, { Component } from 'react';
import { observer } from 'mobx-react';

import GlobalError from './components/Base/GlobalError';

import LoginForm from './components/LoginForm';

import commonStore from './stores/commonStore';
import authStore from './stores/authStore';

import { Router } from 'react-router-dom';
import MainRouter from './mainRouter';

import history from './history';
import * as crmChangesApi from '~/api/crmChangesApi';

type AppState = {
    isError: string | null;
};

@observer
class App extends Component<Record<string, never>, AppState> {
    state = {
        isError: null
    };

    async componentDidMount(): Promise<void> {
        if (authStore.token) {
            authStore.whatsUp();
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('componentDidCatch', error);
        console.log('errorInfo', errorInfo);
        crmChangesApi.sendUiError(error, errorInfo, 'App');
        this.setState({ isError: error.message });
    }

    render() {
        if (this.state.isError) {
            return <GlobalError error={this.state.isError} />;
        }
        if (!authStore.loggedIn) {
            return <LoginForm />;
        }

        const { expandedMenu, isMobile, fullSizePage } = commonStore;

        return (
            <div className={`crm-Main ${expandedMenu ? 'crm-Main__expanded' : ''}`}>
                <Router history={history}>
                    <MainRouter location={history.location} fullSize={!isMobile && fullSizePage} />
                </Router>
            </div>
        );
    }
}

export default App;
