import { SyntheticEvent } from 'react';

import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';

export const useStoreHooks = (store: ListStoreInterface) => {
    const handleCheckbox = (event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => {
        event.preventDefault();
        store.changeFilter(name, checked);
    };

    return {
        handleCheckbox
    };
};
