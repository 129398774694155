import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Segment, Ref } from 'semantic-ui-react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'moment/locale/ru';
import history from '../../history';

import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.less';
import '../../../node_modules/react-big-calendar/lib/addons/dragAndDrop/styles.css';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import commonStore from '~/stores/commonStore';
import calendarStore from '~/stores/calendarStore';
import callEventStore from '~/stores/cell/callEventStore';
import showingStore from '~/stores/showingStore';
import dealStore from '~/stores/dealStore';
import userStore from '~/stores/userStore';
import contactStore from '~/stores/contactStore';
import meetingEventStore from '~/stores/meetingEventStore';
import productMovementStore from '~/stores/ff/productMovementStore';

import EventModal from '../CalendarEvents/EventModal';
import CalendarToolbarWrapper from './CalendarToolbarWrapper';
import CalendarEventWrapper, { CalendarEvent } from './CalendarEventWrapper';

import { CalendarEventType, CALENDAR_VIEW_TYPES, CALENDAR_ITEM_TYPE, CALENDAR_VIEW_TYPE_AGENDA } from '~/types/calendar.types';
import { CALENDAR_VIEW_TYPE_DAY, CALENDAR_VIEW_TYPE_MONTH, CALENDAR_VIEW_TYPE_WEEK } from '~/types/calendar.types';

import { ShowingLinkState } from '../Lists/Showings/ShowingLink';

import { DealLinkState } from '../Lists/Deals/DealLink';
import { ESTATE_TYPE_SELL } from '~/types/estate.types';
import { DAY_MS } from '../../common/time';

import { UserLinkState } from '../Lists/Users/UserLink';

import { EVENT_TYPE_CALL, EVENT_TYPE_MAIN } from '~/types/events.types';
import { MeetingEventLinkUrl } from '../CalendarEvents/MeetingEvents/MeetingEventLink';
import LoaderAwait from '../Base/LoaderAwait';

import { ContactLinkState } from '../Lists/Contacts/ContactLink';
import debounce from '../../common/debounce';
import { ProductMovementLinkState } from '~/components/Lists/ProductsMovements/ProductMovementLink';

moment.locale('ru');

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

type CalendarProps = {
    onRefresh: (startRange: number, endRange: number) => void;
    loading?: boolean;
    events: CalendarEventType[];
    views?: CALENDAR_VIEW_TYPES[];
    defaultView?: CALENDAR_VIEW_TYPES;
    className?: string;

    itemTypes?: CALENDAR_ITEM_TYPE[];
    itemTypesAvailable?: CALENDAR_ITEM_TYPE[];
    onItemTypeClick?: (type: CALENDAR_ITEM_TYPE) => void;
    onChangeUser?: null | ((user_id: number | Array<number>) => void);
    disableCreatingEvent?: boolean;
    user_id?: number;
    groups_id?: number[];
};

type CalendarState = {
    showEventId: number;
    showEventType: number;
    viewType: CALENDAR_VIEW_TYPES;
    startRange: number;
    endRange: number;

    startTimeEvent?: number;
    endTimeEvent?: number;
};

const CALL_EVENT_ID_CLOSE = -1;

type ResizeHandler = {
    event: CalendarEventType;
    start: Date;
    end: Date;
    droppedOnAllDaySlot: boolean;
    isAllDay?: boolean;
};

export default class Calendar extends Component<CalendarProps, CalendarState> {
    constructor(props: CalendarProps) {
        super(props);

        this.state = {
            showEventId: CALL_EVENT_ID_CLOSE,
            showEventType: EVENT_TYPE_MAIN,
            viewType: props.defaultView || (commonStore.isMobile ? CALENDAR_VIEW_TYPE_DAY : CALENDAR_VIEW_TYPE_WEEK),
            startRange: Math.round((Date.now() - 31 * DAY_MS) / 1000),
            endRange: Math.round((Date.now() + 31 * DAY_MS) / 1000)
        };
    }

    calendarRef?: HTMLElement;

    componentDidMount() {
        const $el = this.calendarRef ? this.calendarRef.querySelector('.rbc-time-content') : null;
        const date = new Date();
        if ($el) {
            $el.scrollTop = 40 * (date.getHours() - 3);
        }
    }

    handleClickEvent = ({ item_type, item_id }: CalendarEventType) => {
        switch (item_type) {
            case callEventStore.moduleName:
                history.push(`/modal/events/callEvent/${item_id}`);
                break;
            case meetingEventStore.moduleName:
                history.push(MeetingEventLinkUrl(item_id));
                break;
            case showingStore.moduleName:
                history.push(ShowingLinkState(item_id));
                break;
            case dealStore.moduleName:
                history.push(DealLinkState(item_id, ESTATE_TYPE_SELL));
                break;
            case userStore.moduleName:
                history.push(UserLinkState(item_id));
                break;
            case contactStore.moduleName:
                history.push(ContactLinkState(item_id));
                break;
            case productMovementStore.moduleName:
                history.push(ProductMovementLinkState(item_id, 'in'));
                break;
            default:
                break;
        }
    };

    handleCloseCallEventModal = () => {
        this.setState({ showEventId: CALL_EVENT_ID_CLOSE });
    };

    handleCreateCallEvent = () => {
        this.setState({ showEventId: CREATING_ITEM_ID });
    };

    handleChangeViewType = (viewType: CALENDAR_VIEW_TYPES) => {
        this.setState({ viewType });
    };

    onRangeChange = (dates: { start: Date; end: Date } | Date[], viewType: CALENDAR_VIEW_TYPES) => {
        let startRange;
        let endRange;

        if (dates instanceof Array) {
            startRange = new Date(dates[0]);
            endRange = new Date(dates[dates.length - 1]);

            // глюк react-big-calendar, когда тыкаешь на день вверху, он кидает неправильный день (на один меньше)
            endRange.setTime(endRange.getTime() + 2 * DAY_MS - 1);
        } else {
            startRange = new Date(dates.start);
            endRange = new Date(dates.end);
        }

        startRange = Math.round(startRange.getTime() / 1000);
        endRange = Math.round(endRange.getTime() / 1000);

        this.setState({ startRange, endRange });
        this.debounceSearch();
    };

    debounceSearch = debounce(() => {
        this.props.onRefresh(this.state.startRange, this.state.endRange);
    }, 50);

    handleResizeEvent = ({ event, start, end }: ResizeHandler) => {
        calendarStore.moveEvent(event, start, end);
    };

    handleMoveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }: ResizeHandler) => {
        calendarStore.moveEvent(event, start, end);
    };

    handleSelectSlot = async ({ start, end }: { start: Date; end: Date }) => {
        this.setState({
            showEventType: EVENT_TYPE_CALL,
            showEventId: CREATING_ITEM_ID,
            startTimeEvent: start.getTime() / 1000,
            endTimeEvent: end.getTime() / 1000
        });
    };

    handleRefresh = () => {
        this.props.onRefresh(this.state.startRange, this.state.endRange);
    };

    render() {
        const {
            events,
            loading,
            className,
            itemTypes,
            itemTypesAvailable,
            onItemTypeClick,
            onChangeUser,
            disableCreatingEvent,

            user_id,
            groups_id
        } = this.props;
        const { showEventId, viewType, startTimeEvent, endTimeEvent, showEventType } = this.state;

        const views = this.props.views || [
            CALENDAR_VIEW_TYPE_DAY,
            CALENDAR_VIEW_TYPE_WEEK,
            CALENDAR_VIEW_TYPE_MONTH /*, CALENDAR_VIEW_TYPE_AGENDA*/
        ];

        const calendarProps = {
            localizer,
            events,
            defaultView: viewType,
            views,
            components: {
                event: CalendarEvent(viewType),
                eventWrapper: CalendarEventWrapper,
                toolbar: CalendarToolbarWrapper({
                    onRefresh: this.handleRefresh,
                    onCreateCallEvent: this.handleCreateCallEvent,
                    itemTypes,
                    itemTypesAvailable,
                    onItemTypeClick,
                    onChangeUser,
                    user_id,
                    groups_id
                })
            },
            messages,
            onSelectEvent: this.handleClickEvent,
            step: 30,
            timeslots: 2,
            onView: this.handleChangeViewType,
            formats,
            selectable: !disableCreatingEvent,
            onRangeChange: this.onRangeChange,
            onEventDrop: this.handleMoveEvent,
            onEventResize: this.handleResizeEvent,
            onSelectSlot: this.handleSelectSlot,
            resizable: !disableCreatingEvent
        };

        return (
            <Ref innerRef={ref => (this.calendarRef = ref)}>
                <Segment className={className}>
                    {[CALENDAR_VIEW_TYPE_AGENDA, CALENDAR_VIEW_TYPE_MONTH].includes(viewType) ? (
                        <BigCalendar {...calendarProps} />
                    ) : (
                        <DragAndDropCalendar {...calendarProps} />
                    )}

                    <LoaderAwait active={loading} />

                    {showEventId !== CALL_EVENT_ID_CLOSE && (
                        <EventModal
                            item_id={showEventId}
                            eventType={showEventId > CREATING_ITEM_ID ? showEventType : null}
                            startTime={startTimeEvent}
                            endTime={endTimeEvent}
                            contact_id={null}
                            onClose={this.handleCloseCallEventModal}
                        />
                    )}
                </Segment>
            </Ref>
        );
    }
}

export const messages = {
    allDay: 'Весь день',
    previous: '<',
    next: '>',
    today: 'Сегодня',
    month: 'Месяц',
    week: 'Неделя',
    day: 'День',
    agenda: 'Расписание',
    date: 'Дата',
    time: 'Время',
    event: 'Событие',
    showMore: (total: number) => `+ еще (${total})`,
    noEventsInRange: 'Событий не найдено'
};

const formats = {
    dayHeaderFormat: commonStore.isMobile ? 'DD MMM, dd' : 'DD MMM, dddd'
};
