import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { ProductMovement } from '~/types/ff/productMovement.types';
import productMovementStore from '~/stores/ff/productMovementStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import { Modal, ModalBtnAction, ModalContent } from '~ui/Modal';

import ProductMovementMainEditing from '~/components/Items/ProductMovement/EditBlocks/ProductMovementMainEditing';
import MovementItemsContainer from '~/components/Items/ProductMovement/EditBlocks/MovementItems/MovementItemsContainer';

const ProductMovementCreateModal = ({
    onClose,
    product_id,
    contact_id,
    direction
}: {
    onClose: () => void;
    product_id: number;
    contact_id: number;
    direction: ProductMovement['direction'];
}) => {
    useMemo(() => {
        productMovementStore.fetchItem(CREATING_ITEM_ID);
        productMovementStore.setEditingItem(CREATING_ITEM_ID, {
            about: '',
            direction,
            contact_id,
            planningDeliveryDate: Math.floor(Date.now() / 1000),
            destination_id: null
        });
    }, [product_id]);

    // const {
    //     editingItem: { count },
    //     loadingItem
    // } = productMovementStore.getItem(CREATING_ITEM_ID);

    let loadingItem = false;

    const handleCreate = async () => {
        if (await productMovementStore.createItem()) {
            onClose();
        }
    };

    const ConfirmActions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'default' },
        {
            onClick: handleCreate,
            loading: loadingItem,
            label: direction === 'in' ? 'Принять товар' : 'Отгрузить товар',
            variant: 'contained',
            color: direction === 'in' ? 'primary' : 'secondary'
        }
    ];

    return (
        <Modal
            onClose={onClose}
            loading={loadingItem}
            maxWidth="xs"
            actions={ConfirmActions}
            header={direction === 'in' ? 'Приход товара' : 'Отгрузка товара'}
        >
            <ModalContent>
                <ProductMovementMainEditing item_id={CREATING_ITEM_ID} direction={direction} />
                <MovementItemsContainer product_movement_id={CREATING_ITEM_ID} contact_id={contact_id} />
            </ModalContent>
        </Modal>
    );
};

export default observer(ProductMovementCreateModal);
