import { observable, action } from 'mobx';
import * as settingsApi from '~/api/settingsApi';

import ItemEditingPrototype from '~/stores/prototypes/ItemEditing.prototype';
import * as exportsApi from '~/api/export/exportsApi';
import { TestReportAnswer } from '~/types/exports.types';
import { ExportBaseIdStat } from '~/types/exportStatistics.types';
import { CRM_SETTINGS_KEY, CrmMainSettings, SettingsProperty } from '~/types/settings.types';
import authStore from '~/stores/authStore';
import deepCopy from '~/common/deepCopy';

const DEFAULT_MAIN_CONFIG_MEMKEY = 'DEFAULT_MAIN_CONFIG';

const defaultMainConfig: CrmMainSettings = {
    enableFakeEstate: false,
    enableExpiredCalls: false,
    lastOutgoingCallTimesWarning: null,
    lastOutgoingCallTimesBan: null,
    enableCanonicalExportBasesId: false,
    CANONICAL_EXPORT_BASES_ID: [],
    whetherNeedCallForOwnerAccess: false,
    enableWishBooking: false,
    wishBookingExpirationDays: 2,
    wishAutoAttaching: false
};

class SettingStore extends ItemEditingPrototype<SettingsProperty> {
    constructor() {
        super('settings_key', 'settings', settingsApi);

        const memConfig = localStorage.getItem(DEFAULT_MAIN_CONFIG_MEMKEY);
        this.mainConfig = memConfig ? JSON.parse(memConfig) : deepCopy(defaultMainConfig);
        authStore.registerInitFunc(this.fetchDefaultConfig.bind(this, CRM_SETTINGS_KEY));
    }

    mainConfig: CrmMainSettings;

    @action
    async fetchDefaultConfig() {
        await this.fetchItem(CRM_SETTINGS_KEY);
        this.mainConfig = this.getConfigObject(CRM_SETTINGS_KEY);
        localStorage.setItem(DEFAULT_MAIN_CONFIG_MEMKEY, JSON.stringify(this.mainConfig));
    }

    getConfigObject<T>(key: string): T | null {
        const { item } = this.getItem(key);
        if (!item?.value) {
            return null;
        }
        return JSON.parse(item.value) as T;
    }

    async checkBaseApiTest(key: string, export_base_id: ExportBaseIdStat, index: number): Promise<TestReportAnswer> {
        const { editingItem } = this.getItem(key);
        const answer = await exportsApi.makeTestLoadReport(export_base_id, editingItem?.value, index);
        this.saveItem(key);
        return answer;
    }

    async saveItem(key: string): Promise<boolean | Partial<SettingsProperty>> {
        const item = this.getItem(key);
        item.loadingItem = true;
        const answer = await super.saveItem(key);
        item.loadingItem = false;

        return answer;
    }
}

export default new SettingStore();
