import React from 'react';

import { NumberInput } from '~ui/TextInput';
import Select, { SelectMenuType } from '~ui/Select';
import Button from '~ui/Button';
import CloseIcon from '@material-ui/icons/Close';

import { TableCell, TableRow } from '~ui/Table';
import { HandleChangeType } from '~/components/Base/EditField';

const ProductMovementItem = ({
    product_id,
    count,
    productList,
    onChangeProduct,
    onChangeCount,
    onDelete
}: {
    product_id: number | null;
    count: number;
    productList: SelectMenuType[];
    onChangeProduct: HandleChangeType;
    onChangeCount: HandleChangeType;
    onDelete: () => void;
}) => {
    return (
        <TableRow>
            <TableCell>
                <Button icon={<CloseIcon />} tooltip="Убрать товар" color="secondary" onClick={onDelete} />
            </TableCell>
            <TableCell>
                <Select value={product_id} onChange={onChangeProduct} options={productList} fullWidth />
            </TableCell>
            <TableCell>
                <NumberInput
                    label="Количество *"
                    value={count}
                    min={0}
                    step={1}
                    onChange={onChangeCount}
                    name="count"
                    extraLabel="шт"
                    disabled={false}
                    variant="classic"
                    style={{ maxWidth: '150px' }}
                />
            </TableCell>
        </TableRow>
    );
};

export default ProductMovementItem;
