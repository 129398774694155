import React from 'react';
import { observer } from 'mobx-react';
import { Grid, Loader, Dimmer, Icon } from 'semantic-ui-react';

import Comments from '../Common/Comments/index';

import LabelArchive from '../Common/LabelArchive';
import productStore from '~/stores/ff/productStore';

import ProductMainShow from './ShowBlocks/ProductMainShow';
import ProductQrCodesShow from './ShowBlocks/ProductQrCodesShow';
import ClientShowMode from '~/components/Base/ClientShowBlock';

import ProductMovementStatistics from './ProductMovement/ProductMovementStatistics';

type ProductShowModeProps = {
    product_id: number;
};

const ProductShowMode = (props: ProductShowModeProps) => {
    const { product_id } = props;
    const { item, loadingItem } = productStore.getItem(product_id);

    const { contact, major_users = [], enable = true, title } = item || {};

    return (
        <Grid stackable>
            <Grid.Column width={8}>
                <h3>
                    <Icon name="box" />
                    <span>{title}</span>
                </h3>

                {!loadingItem && !enable && <LabelArchive />}

                {loadingItem && (
                    <Dimmer active inverted>
                        <Loader active size="large" />
                    </Dimmer>
                )}

                <ProductMainShow item_id={product_id} />
                <ProductQrCodesShow item_id={product_id} />
            </Grid.Column>

            <Grid.Column width={8}>
                <ClientShowMode title="Клиент (Посредник)" contact={contact} major_users={major_users} />
                {item && <ProductMovementStatistics product_id={product_id} />}
                <Comments item_id={product_id} item_type="product" />
            </Grid.Column>
        </Grid>
    );
};

export default observer(ProductShowMode);
