import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import {
    ProductMovementInBase,
    ProductMovement,
    ProductMovementFilter,
    ProductMovementDestination,
    ProductMovementStatus,
    ProductMovementList
} from '~/types/ff/productMovement.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { GQHistoryChange, GQUserMainFields } from '~/api/userApi';
import { GQContactPhones, GQMainContactFields } from '../contactsApi';
import { GQProductMainFields } from '~/api/ff/productApi';
import { HistoryChange } from '~/types/historyChanges.types';
import { GQComments } from '~/api/commentsApi';

const GQProductMovementFields = `
    product_movement_id
    contact_id
    
    direction
    destination_id
    status
   
    about
    planningDeliveryDate
    executeDeliveryDate
    
    major_user_id
    major_user {
        ${GQUserMainFields}    
    }
  
    contact {
        ${GQMainContactFields}
        ${GQContactPhones}
    }

    createTime
    updateTime
    enable
`;

export const fetchItem = async (product_movement_id: number): Promise<ProductMovement> => {
    const graphql = `{
        fetchProductMovement(product_movement_id: ${product_movement_id}) {
            ${GQProductMovementFields}
            items {
                product_id
                count
                product {
                    ${GQProductMainFields}    
                }
            }
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchProductMovement: ProductMovement }>(graphql);
    return data.fetchProductMovement;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    productMovementFilter: Partial<ProductMovementFilter>,
    controller?: AbortController
): Promise<{ list: ProductMovementList[]; count: number }> => {
    const graphql = `{
            fetchProductMovements(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(productMovementFilter)}) {
                list {
                    ${GQProductMovementFields}
                    itemsCount               
                    comments {
                        ${GQComments}
                    }
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchProductMovements: { list: ProductMovementList[]; count: number } }>(graphql, controller);
    return data.fetchProductMovements;
};

export const saveItem = async (product_movement_id: number, movementDiff: Partial<ProductMovementInBase>): Promise<number> => {
    const graphql = `mutation { 
        updateProductMovement(product_movement_id: ${product_movement_id}, movementDiff: ${objectToGraphql(movementDiff)})
    }`;

    const data = await fetchApi.postGQ<{ updateProductMovement: number }>(graphql);
    return data.updateProductMovement;
};

export const createItem = async (movementInput: ProductMovementInBase): Promise<number> => {
    const graphql = `mutation { 
          createProductMovement(movementInput: ${objectToGraphql(movementInput)})
        }`;

    const data = await fetchApi.postGQ<{ createProductMovement: number }>(graphql);
    return data.createProductMovement;
};

export const createMovementDestinations = async (name: string): Promise<number> => {
    const graphql = `mutation { 
          createMovementDestinations(name: ${objectToGraphql(name)})
        }`;

    const data = await fetchApi.postGQ<{ createMovementDestinations: number }>(graphql);
    return data.createMovementDestinations;
};

export const changeMovementStatus = async (product_movement_id: number, status: ProductMovementStatus): Promise<number> => {
    const graphql = `mutation { 
          changeMovementStatus(product_movement_id: ${product_movement_id}, status: ${status})
        }`;

    const data = await fetchApi.postGQ<{ changeMovementStatus: number }>(graphql);
    return data.changeMovementStatus;
};

export const fetchMovementDestinations = async (): Promise<ProductMovementDestination[]> => {
    const graphql = `{ 
          fetchMovementDestinations {
            destination_id
            name
          }
        }`;

    const data = await fetchApi.getGQ<{ fetchMovementDestinations: ProductMovementDestination[] }>(graphql);
    return data.fetchMovementDestinations;
};

export const fetchItemHistory = async (product_movement_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchProductMovementChangeHistory(product_movement_id: ${product_movement_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchProductMovementChangeHistory: HistoryChange[] }>(graphql);
    return data.fetchProductMovementChangeHistory;
};

export const productMovementsByContact = async (contact_id: number): Promise<ProductMovementInBase[]> => {
    const graphql = `{
        productMovementsByContact(contact_id: ${contact_id}) {
            product_movement_id
            direction
            createTime
        } 
      }`;

    const data = await fetchApi.getGQ<{ productMovementsByContact: ProductMovement[] }>(graphql);
    return data.productMovementsByContact;
};
