import { proxyL10nHandler } from '~/common/localizations';
import { ProductMovementStatus } from '~/types/ff/productMovement.types';

const PRODUCT_MOVEMENT_STATUS = [
    [ProductMovementStatus.WaitingForExecution, 'Ожидает'],
    [ProductMovementStatus.Done, 'Выполнено'],
    [ProductMovementStatus.Cancel, 'Отмена']
];

const PRODUCT_MOVEMENT_DIRECTION = [
    ['in', 'Приход товара'],
    ['out', 'Отгрузка товара']
];

const productL10n = new Proxy({}, proxyL10nHandler);

productL10n.PRODUCT_MOVEMENT_STATUS = PRODUCT_MOVEMENT_STATUS;
productL10n.PRODUCT_MOVEMENT_DIRECTION = PRODUCT_MOVEMENT_DIRECTION;

export default productL10n;
