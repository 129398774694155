import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MoneyIcon from '@mui/icons-material/Money';

import Button from '~ui/Button';
import Price from '~/components/Base/Price';
import { useToggle } from '~ui/Modal';
import ListError from '~/components/ListError';

import ContactPaymentModal from './SubBlocks/ContactPaymentModal';
import contactStore from '~/stores/contactStore';

const ContactPaymentsShowBlock = ({ contact_id }: { contact_id: number }) => {
    const [showModal, toggleModal] = useToggle();

    useMemo(() => {
        contactStore.fetchContactBalance(contact_id);
    }, [contact_id]);

    const { balance, balanceErrors } = contactStore.getItem(contact_id).property;

    return (
        <Card sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
            <ListError errors={balanceErrors} />
            <CardContent>
                <Typography variant="h5">
                    {typeof balance === 'number' && <Price price={balance} currency={1} />}
                    {typeof balance !== 'number' && <>---</>}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Баланс контакта
                </Typography>
            </CardContent>
            <CardActions>
                <Button startIcon={<MoneyIcon />} size="small" tooltip="Добавить баланс контакту" onClick={toggleModal}>
                    Начислить платеж
                </Button>
                {showModal && <ContactPaymentModal contact_id={contact_id} onClose={toggleModal} />}
            </CardActions>
        </Card>
    );
};

export default observer(ContactPaymentsShowBlock);
