import React, { ReactNode, CSSProperties, ChangeEvent, SyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MUSelect from '@material-ui/core/Select';

import { HandleChangeType } from '../EditField';
import { DropdownType } from '~/stores/prototypes/ListStore.prototype';
import Progress from './Progress';

import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

export type SelectMenuType = DropdownType & {
    style?: CSSProperties;
    caption?: string;
    disabled?: boolean;
    divider?: boolean;
};

type SelectProps = {
    label?: string | ReactNode;
    value: any;
    name?: string;
    onChange: HandleChangeType;
    options: SelectMenuType[];
    loading?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    fullWidth?: boolean;
    withoutBorder?: boolean;
    clearable?: boolean;
    multiple?: boolean;

    variant?: 'standard' | 'outlined' | 'classic' | 'filled';
    size?: 'small' | 'medium';
    error?: boolean;
};

const useStyles = makeStyles(() => ({
    withoutBorder: {
        '&::before': {
            display: 'none'
        }
    },
    clearBtn: {
        position: 'absolute',
        bottom: '0px',
        right: '35px',
        top: 'calc(50% - 12px)'
    }
}));

const SelectClearBtn = ({ onClick }: { onClick: (event: SyntheticEvent) => void }) => {
    const classes = useStyles();

    return (
        <div className={classes.clearBtn}>
            <Tooltip title="Очистить поле">
                <IconButton edge="end" color="inherit" onClick={onClick}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </div>
    );
};

const Select = ({
    label,
    onChange,
    value,
    options,
    name,
    disabled,
    loading,
    fullWidth,
    style,
    withoutBorder,
    clearable,
    multiple,
    error,
    variant,
    size
}: SelectProps) => {
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event, { type: '', name: name || '', value: event.target.value });
    };

    const handleReset = (event: SyntheticEvent) => {
        onChange(event, { type: '', name: name || '', value: null });
    };

    return (
        <FormControl disabled={disabled} fullWidth={fullWidth}>
            {label && (
                <InputLabel
                    style={
                        variant === 'classic'
                            ? { background: 'white', fontSize: '1.175rem', paddingLeft: '0.5rem', paddingRight: '0.rem' }
                            : undefined
                    }
                >
                    {label}
                </InputLabel>
            )}
            <MUSelect
                value={value ?? ''}
                className={cs({ [classes.withoutBorder]: withoutBorder })}
                onChange={handleChange}
                multiple={multiple}
                style={variant === 'classic' ? { ...style, background: 'white' } : style}
                variant={variant === 'classic' ? 'outlined' : variant}
                error={error}
            >
                {options.map(({ text, divider, disabled, key, value, style, caption }) => (
                    <MenuItem divider={divider} disabled={disabled} key={key || text} value={value as any} style={style}>
                        <Box display="flex" flexDirection="column">
                            <Typography>{text}</Typography>
                            {caption && <Typography variant="caption">{caption}</Typography>}
                        </Box>
                    </MenuItem>
                ))}
            </MUSelect>
            {clearable && Boolean(value) && <SelectClearBtn onClick={handleReset} />}

            {loading && <Progress show size={24} />}
        </FormControl>
    );
};

export default Select;
