import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Button, Card, Icon } from 'semantic-ui-react';

import contactStore from '~/stores/contactStore';

import ShadowFragment from '~/components/Base/ShadowFragment';
import PersonalBlockEditing from '../EditBlocks/PersonalBlockEditing';
import AdditionalInfoBlockEditing from '../EditBlocks/AdditionalInfoBlockEditing';

import EditingBlockWrapper, { EditingWrappedProps } from '../../EditingBlockWrapperNew';
import Time from '~/components/Base/Time';
import LabelArchive from '../../Common/LabelArchive';
import UsersLinkList from '~/components/Lists/Users/UsersLinkList';
import { nl2br } from '~/api/commentsApi';
import { matchContactTitle } from '~/components/Lists/Contacts/ContactLink';
import { CONTACT_TYPE_COMPANY_ID } from '~/types/contacts.types';
import ConfirmTrigger from '~ui/ConfirmTrigger';
import urlify from '~/common/urlify';

@observer
class PersonalBlockShow extends Component<EditingWrappedProps> {
    takeContactToWork = async () => {
        await contactStore.takeContactToWork(this.props.item_id);
    };

    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = contactStore.getItem(item_id);
        if (!item) {
            return null;
        }
        const { createTime, updateTime, birthday, major_users, enable, contactType, inn } = item;

        return (
            <Fragment>
                <Card fluid color="blue">
                    <Card.Content>
                        {!editing && (
                            <Fragment>
                                <Card.Header>
                                    {!loadingItem && !enable && <LabelArchive ribbon />}№{item_id} &nbsp;
                                    {contactType === CONTACT_TYPE_COMPANY_ID && <Icon name="briefcase" title="Юридическое лицо" />}
                                    <ShadowFragment print={!loadingItem && matchContactTitle(item)} />
                                </Card.Header>
                                <Card.Meta>
                                    {contactType === CONTACT_TYPE_COMPANY_ID && inn && (
                                        <Fragment>
                                            ИНН: {inn}
                                            <br />
                                            <br />
                                        </Fragment>
                                    )}
                                    <div className="date">
                                        Карточка создана <Time time={createTime} />
                                    </div>
                                    {updateTime > 0 && createTime !== updateTime && (
                                        <div className="date">
                                            Карточка обновлена <Time time={updateTime} />
                                        </div>
                                    )}
                                </Card.Meta>
                                {item.about && (
                                    <Card.Description style={{ padding: '10px 0 15px' }}>
                                        <span dangerouslySetInnerHTML={{ __html: !loadingItem && nl2br(urlify(item.about)) }} />
                                    </Card.Description>
                                )}
                                {birthday && (
                                    <Card.Meta>
                                        День Рождения &nbsp;
                                        {new Date(birthday).toLocaleDateString()}
                                    </Card.Meta>
                                )}
                            </Fragment>
                        )}
                        {editing && (
                            <Fragment>
                                <PersonalBlockEditing simpleMode item_id={item_id} {...item} />
                                <AdditionalInfoBlockEditing item_id={item_id} {...item} />
                            </Fragment>
                        )}
                    </Card.Content>
                    <Card.Content extra>
                        {!loadingItem && !major_users.length && (
                            <Fragment>
                                <p>Ответственный не назначен</p>
                                <ConfirmTrigger
                                    trigger={
                                        <Button color="teal" fluid size="tiny">
                                            <Icon name="lightbulb outline" /> Взять в работу
                                        </Button>
                                    }
                                    content="Установите правильное ФИО и другие контактные данные. Вы станете ответсвенным сотрудником по данному контакту"
                                    header="Взять контакт в работу?"
                                    confirmButton="Взять в работу"
                                    onConfirm={this.takeContactToWork}
                                />
                            </Fragment>
                        )}
                        <UsersLinkList usersList={major_users} avatar />
                    </Card.Content>
                </Card>
            </Fragment>
        );
    }
}

export default EditingBlockWrapper(contactStore, PersonalBlockShow);
