import React, { Component } from 'react';
import { Grid, Button, List, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import dealStore, { DEAL_BUYER, DEAL_SELLER, DEAL_PARTICIOANT_REMOVE } from '~/stores/dealStore';
import ItemDropdown from '../../Common/ItemDropdow';
import contactStore from '~/stores/contactStore';
import { ContactLinkType } from '~/types/contacts.types';
import history from '../../../../history';
import ContactLinkWithPreview from '../../../Lists/Contacts/ContactLinkWithPreview';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type CustomersBlockEditingProps = HandlerEditingWrappedProps & {
    sellers_ids?: Array<number>;
    sellers?: Array<ContactLinkType>;
    buyers_ids?: Array<number>;
    buyers?: Array<ContactLinkType>;
    editing?: boolean;
};

@observer
class DealCustomersEditing extends Component<CustomersBlockEditingProps> {
    constructor(props: CustomersBlockEditingProps) {
        super(props);

        const {
            location: {
                state: { from }
            }
        } = history;

        let buyers_ids = props.buyers_ids || [];
        let buyers = props.buyers || [];

        if (from && from.storeName === 'contactStore') {
            const { item_id } = from;
            const { item } = contactStore.getItem(item_id);

            if (item) {
                buyers_ids = [item_id];
                buyers = [item];
            }
        }

        const item = {
            sellers_ids: props.sellers_ids || [],
            sellers: props.sellers || [],
            buyers_ids,
            buyers
        };

        dealStore.setEditingItem(props.item_id, item);
    }

    handleChangeSeller = (event: React.SyntheticEvent, { value }: { value: string }) => {
        const { item_id } = this.props;

        dealStore.addSeller(item_id, Number(value));
    };

    handleChangeBuyer = (event: React.SyntheticEvent, { value }: { value: string }) => {
        const { item_id } = this.props;

        dealStore.addBuyer(item_id, Number(value));
    };

    removeSeller = (contact_id: number) => {
        const { item_id } = this.props;

        dealStore.changeParticipant(DEAL_SELLER, DEAL_PARTICIOANT_REMOVE, item_id, contact_id);
    };

    removeBuyer = (contact_id: number) => {
        const { item_id } = this.props;

        dealStore.changeParticipant(DEAL_BUYER, DEAL_PARTICIOANT_REMOVE, item_id, contact_id);
    };

    render() {
        const { item_id, editing } = this.props;
        const { sellers, buyers, type } = dealStore.getItem(item_id).editingItem;

        const participantsSet = dealStore.whetherIsNotEstate(type)
            ? [
                  {
                      title: 'Заказчик(и)',
                      onChange: this.handleChangeBuyer,
                      onRemove: this.removeBuyer,
                      callBackName: 'addBuyer',
                      listItems: buyers
                  }
              ]
            : [
                  {
                      title: 'Продавец(цы)',
                      onChange: this.handleChangeSeller,
                      onRemove: this.removeSeller,
                      callBackName: 'addSeller',
                      listItems: sellers
                  },
                  {
                      title: 'Покупатель(и)',
                      onChange: this.handleChangeBuyer,
                      onRemove: this.removeBuyer,
                      callBackName: 'addBuyer',
                      listItems: buyers
                  }
              ];

        return (
            <div className="crm-Item__editingMode">
                <Grid stackable columns={editing ? 1 : participantsSet.length > 1 ? 2 : 1}>
                    {participantsSet.map(({ title, onChange, callBackName, onRemove, listItems }) => (
                        <Grid.Column key={title}>
                            <b>{title}</b>
                            <Grid>
                                <Grid.Column width={12}>
                                    <ItemDropdown
                                        name="contact_id"
                                        store={contactStore}
                                        item_id={0}
                                        onChange={onChange}
                                        placeholder="Выбрать контакт"
                                        noResultsMessage="Контакт не найден"
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Button
                                        size="mini"
                                        color="green"
                                        title="Создать контакт"
                                        as={Link}
                                        to={{
                                            pathname: `/contacts/list/profile/${CREATING_ITEM_ID}`,
                                            state: {
                                                modal: true,
                                                callback: { storeName: 'dealStore', method: callBackName, item_id }
                                            }
                                        }}
                                    >
                                        <Icon name="plus" />
                                        <Icon name="vcard" />
                                    </Button>
                                </Grid.Column>
                            </Grid>

                            <List>
                                {listItems &&
                                    listItems.map(item => (
                                        <List.Item key={item.contact_id}>
                                            <div className="crm-Estate__fieldNowrap">
                                                <Icon link name="close" title="Убрать" onClick={onRemove.bind(this, item.contact_id)} />
                                                <ContactLinkWithPreview contact={item} />
                                            </div>
                                        </List.Item>
                                    ))}
                            </List>
                        </Grid.Column>
                    ))}
                </Grid>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(dealStore, DealCustomersEditing);
