import React, { ChangeEvent, useEffect } from 'react';
import { observer } from 'mobx-react';

import { ProductList } from '~/types/ff/product.types';
import productMovementStore from '~/stores/ff/productMovementStore';

import AddIcon from '@material-ui/icons/Add';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import { SelectMenuType } from '~ui/Select';
import Button from '~ui/Button';
import Table, { TableBody, TableCell, TableRow, TableHead } from '~ui/Table';

import ProductMovementItem from './ProductMovementItem';

const MovementItemsTable = ({ products, product_movement_id }: { products: ProductList[]; product_movement_id: number }) => {
    const { items = [] } = productMovementStore.getItem(product_movement_id).editingItem;

    useEffect(() => {
        if (!items.length && products.length) {
            productMovementStore.setEditingItem(product_movement_id, {
                items: [{ product_id: products[0].product_id, count: 1 }]
            });
        }
    }, [products]);

    const handleAddItem = () => {
        productMovementStore.setEditingItem(product_movement_id, {
            items: [...items, { product_id: null, count: 0 }]
        });
    };

    const handleChangeItemProduct =
        (index: number) =>
        (event: ChangeEvent, { value }: { value: number | null }) => {
            productMovementStore.changeArrayValue(product_movement_id, 'items', index, 'product_id', value);
        };

    const handleChangeItemCount =
        (index: number) =>
        (event: ChangeEvent, { value }: { value: number | null }) => {
            productMovementStore.changeArrayValue(product_movement_id, 'items', index, 'count', value);
        };

    const handleDeleteItem = (index: number) => () => {
        productMovementStore.deleteArrayItem(product_movement_id, 'items', index);
    };

    const productList: SelectMenuType[] = products.map(({ title, product_id, aspects }) => ({
        value: product_id,
        text: `${title}${aspects?.length ? ` (${aspects.map(({ aspect, value }) => `${aspect}: ${value}`).join('; ')})` : ''}`,
        disabled: items.some(item => item.product_id === product_id)
    }));

    return (
        <>
            <Table elevation={0}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '40px' }} />
                        <TableCell align="center">Товар</TableCell>
                        <TableCell style={{ width: '160px' }} align="center">
                            Количество
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map(({ product_id, count }, index) => (
                        <ProductMovementItem
                            key={product_id}
                            product_id={product_id}
                            count={count}
                            productList={productList}
                            onChangeProduct={handleChangeItemProduct(index)}
                            onChangeCount={handleChangeItemCount(index)}
                            onDelete={handleDeleteItem(index)}
                        />
                    ))}
                </TableBody>
            </Table>
            <Button
                size="large"
                startIcon={<AddShoppingCartIcon />}
                endIcon={<AddIcon />}
                color="secondary"
                fullWidth
                onClick={handleAddItem}
                tooltip={`Добавить еще один товар`}
            >
                Добавить еще товар
            </Button>
        </>
    );
};

export default observer(MovementItemsTable);
