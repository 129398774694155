import React, { createRef, Fragment } from 'react';
import { Popup } from 'semantic-ui-react';

import { ContactLinkType } from '~/types/contacts.types';
import ContactLinkWithPreview from '~/components/Lists/Contacts/ContactLinkWithPreview';
import ContactLink from '~/components/Lists/Contacts/ContactLink';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const ContactsLinkList = ({ contactList }: { contactList?: ContactLinkType[] }) => {
    return (
        <Fragment>
            {contactList && contactList.length === 1 ? (
                <ContactLinkWithPreview key={contactList[0].contact_id} contact={contactList[0]} />
            ) : null}
            {contactList && contactList.length > 1 && (
                <Popup
                    size="small"
                    hoverable
                    trigger={
                        <span className="crm-User__linkList_trigger">
                            <ContactLink key={contactList[0].contact_id} contact={contactList[0]} />
                            <b>{`+${contactList.length - 1}`}</b>
                        </span>
                    }
                >
                    <List disablePadding>
                        {contactList.map(contact => (
                            <ListItem key={contact.contact_id}>
                                <ContactLink contact={contact} />
                            </ListItem>
                        ))}
                    </List>
                </Popup>
            )}
        </Fragment>
    );
};

export default React.memo(ContactsLinkList);
