import { observable, action, reaction, runInAction } from 'mobx';
import * as calendarApi from '~/api/calendarApi';
import { CalendarEventType, CALENDAR_ITEM_TYPE } from '~/types/calendar.types';
import { ITEM_TYPES } from '~/types/notifications.types';
import CalendarEventsStorePrototype from './prototypes/CalendarEventsStore.prototype';
import indexDB from '../common/indexDB';

class CalendarStore {
    moduleName: ITEM_TYPES = 'calendar';

    @observable
    events: CalendarEventType[] = [];

    @observable
    errors: string[] = [];

    @observable
    loadingEvents = false;

    filterItemTypes: CALENDAR_ITEM_TYPE[] = ['callEvent', 'meetingEvent', 'showing', 'deal', 'user', 'contact', 'productMovement'];
    currentUserId = -1;

    toggleFilterItemTypes = (name: CALENDAR_ITEM_TYPE) => {
        const foundIndex = this.filterItemTypes.findIndex(key => key === name);
        if (~foundIndex) {
            this.filterItemTypes.splice(foundIndex, 1);
        } else {
            this.filterItemTypes.push(name);
        }
    };

    eventStores: CalendarEventsStorePrototype<any, any>[] = [];

    registerEventsStore(store: CalendarEventsStorePrototype<any, any>) {
        this.eventStores.push(store);
    }

    @action
    async fetchCalendarEvents(startTime: number, endTime: number, cached?: boolean) {
        this.loadingEvents = true;
        this.errors = [];

        try {
            if (cached) {
                indexDB.get(this.currentUserId, this.moduleName).then(data => {
                    if (data && this.loadingEvents) {
                        this.events = data.item;
                    }
                });
            }

            const events = await calendarApi.fetchCalendarEvents(this.currentUserId, startTime, endTime, this.filterItemTypes);

            if (cached) {
                indexDB.put(this.currentUserId, this.moduleName, [...events]);
            }

            this.events = events;
        } catch (errors) {
            this.errors = errors;
        } finally {
            this.loadingEvents = false;
        }
    }

    @action
    addNewEvent(event: CalendarEventType) {
        this.events.push(event);
    }

    @action
    mergeEvent(event: Partial<CalendarEventType>) {
        const foundIndex = this.events.findIndex(({ item_type, item_id }) => event.item_type === item_type && event.item_id === item_id);
        if (~foundIndex) {
            this.events[foundIndex] = { ...this.events[foundIndex], ...event };
            this.events = [...this.events];
        }
    }

    @action
    removeEvent(item_id: number, item_type: ITEM_TYPES) {
        const foundIndex = this.events.findIndex(event => event.item_type === item_type && event.item_id === item_id);
        if (~foundIndex) {
            this.events.splice(foundIndex, 1);
            this.events = [...this.events];
        }
    }

    @action
    async moveEvent(event: CalendarEventType, start: Date, end: Date) {
        const { item_type, item_id } = event;

        const store = this.eventStores.find(store => store.moduleName === item_type);

        if (store) {
            const foundIndex = this.events.findIndex(event => event.item_type === item_type && event.item_id === item_id);
            if (~foundIndex) {
                this.events[foundIndex] = { ...this.events[foundIndex], start, end };
                this.events = [...this.events];
            }

            store.changeItemDates(item_id, start.getTime() / 1000, end.getTime() / 1000);
        }
    }
}

export default new CalendarStore();
