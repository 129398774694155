import React from 'react';
import { observer } from 'mobx-react';

import Grid from '~ui/Grid';

import Comments from '../Common/Comments/index';

import productMovementStore from '~/stores/ff/productMovementStore';

import ProductMovementMainShow from './ShowBlocks/ProductMovementMainShow';
import ProductMovementItemsShow from './ShowBlocks/ProductMovementItemsShow';
import ProductMovementStatusList from './ShowBlocks/ProductMovementStatusList';
import ClientShowMode from '~/components/Base/ClientShowBlock';

type ProductShowModeProps = {
    product_movement_id: number;
};

const ProductMovementShowMode = (props: ProductShowModeProps) => {
    const { product_movement_id } = props;
    const { item, loadingItem } = productMovementStore.getItem(product_movement_id);

    const { contact, major_users } = item || {};

    return (
        <Grid stackable alignItems="flex-start" disableGutter>
            <Grid.Column width={7}>
                <ProductMovementMainShow item_id={product_movement_id} />
                <ProductMovementItemsShow item_id={product_movement_id} />
            </Grid.Column>

            <Grid.Column width={5}>
                <ClientShowMode title="Клиент (Посредник)" contact={contact} major_users={major_users} />
                <ProductMovementStatusList product_movement_id={product_movement_id} />
                <Comments item_id={product_movement_id} item_type="productMovement" />
            </Grid.Column>
        </Grid>
    );
};

export default observer(ProductMovementShowMode);
