import React, { Component, Fragment } from 'react';
import dealStore from '~/stores/dealStore';

import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import dealL10n from '../../../L10n/deals.L10n';
import estateL10n from '../../../L10n/estate.L10n';

const dealKeys = {
    type: 'Тип Договора',
    enable: 'Архив',
    dealType: 'Тип Договора',
    estateType: 'Тип Недвижимости',
    estate_id: 'Номер объекта',
    estateOuterUrl: 'Ссылка / Описание объекта',
    price: 'Стоимость объекта',
    currency: 'Валюта',
    isExclusive: 'Эксклюзив',
    deposit: 'Залог',
    depositDeadTime: 'Аванс истекает',
    exclusiveDeadTime: 'Эксклюзив до',
    dealOpenTime: 'Время открытия',
    dealDoneTime: 'Время проведения',
    dealCloseTime: 'Время закрытия',
    dealBreakTime: 'Время развала',
    status: 'Статус',
    sellers_ids: 'Продавец(цы)',
    buyers_ids: 'Покупатель(и)',
    description: 'Описание',
    isMortgage: 'Ипотека',
    bank_id: '№ банка',
    mortgagePercent: 'Процент ипотеки',
    mortgageDuration: 'Срок ипотеки',
    fee: 'Комиссия',
    feeCurrency: 'Валюта комиссии',
    usersFees: 'Агентская комиссия',
    releaseTime: 'Дата проведения',
    firstDeposit: 'Первоначальный взнос',
    depositTime: 'Дата внесения аванса',

    deal_id: 'Номер сделки',
    estate: 'Объект',
    updateTime: 'Время обновления'
};

const printDealVal = (key: string, value: unknown): string => {
    switch (key) {
        case 'type':
            return dealL10n.DEAL_ESTATE_TYPE_FIND(value);
        case 'dealType':
            return estateL10n.SALE_TYPES_FIND(value);
        case 'status':
            return dealL10n.DEAL_STATUS_TYPE_FIND(value);
        case 'estateType':
            return estateL10n.SHOWING_TYPES_FIND(value);
        case 'enable':
            return value ? 'нет' : 'да';
        case 'releaseTime':
        case 'updateTime':
        case 'exclusiveDeadTime':
        case 'depositDeadTime':
        case 'dealOpenTime':
        case 'dealDoneTime':
        case 'dealCloseTime':
        case 'dealBreakTime':
        case 'depositTime':
            return Number(value) > 0 ? new Date(Number(value) * 1000).toLocaleString() : 'н/д';
        default:
            break;
    }

    if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(dealStore, dealKeys, printDealVal);
