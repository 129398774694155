import React from 'react';
import { Icon } from 'semantic-ui-react';
import config from '~/config/ui.config';

import commonStore from '~/stores/commonStore';
import { LearningMaterial } from '~/types/learning.types';

import urlify from '~/common/urlify';
import nl2br from '~/common/nl2br';

import { Modal, ModalContent } from '~/components/Base/ui/Modal';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

const matchFileIcon = (ext: string): SemanticICONS => {
    switch (ext) {
        case 'pdf':
            return 'file pdf';
        case 'doc':
        case 'docx':
            return 'file word';
        case 'xlsc':
        case 'xls':
        case 'csv':
            return 'file excel';
        case 'avi':
        case 'mp4':
        case 'mpg':
        case 'mpeg':
        case 'mov':
            return 'file video';
        case 'wav':
        case 'mp3':
            return 'file audio';
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return 'file image';
        case 'ppt':
        case 'pptx':
            return 'file powerpoint';
        case 'zip':
        case 'rar':
        case '7z':
        case 'tar':
            return 'file archive';
        default:
            return 'file';
    }
};

type LearningShowModalProps = {
    learningMaterial: LearningMaterial;
    onClose: () => void;
};

export default function LearningShowModal({ learningMaterial, onClose }: LearningShowModalProps) {
    const { title, description, files, url } = learningMaterial;

    return (
        <Modal onClose={onClose} fullScreen={commonStore.isMobile} fullWidth header={title}>
            {description && (
                <ModalContent dividers>
                    <Typography gutterBottom>
                        <span dangerouslySetInnerHTML={{ __html: description && nl2br(urlify(description)) }} />
                    </Typography>
                </ModalContent>
            )}

            {url && (
                <ModalContent dividers>
                    <ListItem button component="a" href={url} target="_blank" title="Внешняя ссылка">
                        <ListItemIcon>
                            <Avatar>
                                <Icon name="external" style={{ margin: 0 }} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText primary={`${url.slice(0, 100)}${url.length > 99 ? '...' : ''}`} />
                    </ListItem>
                </ModalContent>
            )}

            {files.length > 0 && (
                <List dense>
                    {files.map(({ originalname, ext, filename, file_id }) => (
                        <ListItem
                            key={filename}
                            button
                            component="a"
                            href={`${config.learningDir}?filename=${filename}&file_id=${file_id}`}
                            target="_blank"
                            title="Просмотреть"
                            rel="noreferrer"
                        >
                            <ListItemIcon>
                                <Avatar>
                                    <Icon name={matchFileIcon(ext)} style={{ margin: 0 }} />
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText primary={originalname} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    title="скачать"
                                    href={`${config.learningDir}?filename=${filename}&download=true&file_id=${file_id}`}
                                    target="_blank"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            )}
        </Modal>
    );
}
