import React from 'react';
import { observer } from 'mobx-react';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HistoryIcon from '@material-ui/icons/History';

import ItemErrors from '../ItemErrors';
import { ItemWrapperState, TAB_ID_HISTORY } from '../ItemWrapper';
import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT } from '../ItemWrapper';
import productMovementStore from '~/stores/ff/productMovementStore';

import ProductMovementTabMenu from './ProductMovementTabMenu';
import { MenuItem } from '~/components/Items/TabMenuWrapper';

import ProductMovementAddMode from './ProductMovementAddMode';
import ProductMovementShowMode from './ProductMovementShowMode';
import ProductMovementHistoryMode from './ProductMovementHistoryMode';

const ProductMovementItem = ({ item_id, tabName, param }: ItemWrapperState) => {
    const { errors, item } = productMovementStore.getItem(item_id);
    const { enable, major_user_ids } = item || {};

    const productTabs: MenuItem[] = [
        { name: 'Описание', id: '', icon: <LocalShippingIcon /> },
        { name: 'История', id: TAB_ID_HISTORY, icon: <HistoryIcon /> }
    ];

    return (
        <>
            <ProductMovementTabMenu
                itemTabs={productTabs}
                item_id={item_id}
                tabName={tabName}
                enable={Boolean(enable)}
                major_user_id={major_user_ids}
                param={param}
            />
            <div className="crm-List__sidebar_modalBox">
                {errors && <ItemErrors errors={errors} tabName={tabName} />}
                {tabName === TAB_ID_DEFAULT && <ProductMovementShowMode product_movement_id={item_id} />}
                {tabName === TAB_ID_CREATING && <ProductMovementAddMode />}
                {tabName === TAB_ID_HISTORY && <ProductMovementHistoryMode item_id={item_id} />}
            </div>
        </>
    );
};

export default ItemWrapper(productMovementStore, observer(ProductMovementItem));
