import React from 'react';
import { observer } from 'mobx-react';
import history from '~/history';

import productMovementStore from '~/stores/ff/productMovementStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import authStore from '~/stores/authStore';

import Container from '@mui/material/Container';
import Box from '@material-ui/core/Box';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Typography from '@material-ui/core/Typography';

import Table, { TableHead } from '~ui/Table';
import Button from '~ui/Button';

import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';

import ProductMovementsTableBody from './ProductMovementsTableBody';
import ProductMovementsTableHeader from './ProductMovementsTableHeader';
import ListFooter from '../Common/ListFooterNew';

import ListError from '~/components/ListError';
import ListPagination from '~/components/Lists/Common/ListPaginationNew';
import { ProductMovementLinkState } from './ProductMovementLink';

const ProductsMovementsList = (props: ListWrapperProps) => {
    const { param, orderBy, orderDirection, handleSort } = props;
    const { loadingList, list, listCount, pagination, nextListLoaded, listErrors } = productMovementStore;

    const handleCreateProductMovement = () => {
        history.push(ProductMovementLinkState(CREATING_ITEM_ID, param === 'out' ? 'out' : 'in'));
    };

    return (
        <Container maxWidth="xl" id="crm_ListTop" sx={{ pb: 3 }}>
            <div className="crm-List" style={{ marginBottom: '1rem' }}>
                <Box display="flex" alignItems="center">
                    <LocalShippingIcon fontSize="large" style={{ paddingRight: '0.5rem' }} />
                    <Typography variant="h5" style={{ paddingRight: '0.5rem' }}>
                        {param === 'out' ? 'Отгрузка' : 'Приход'} Товара
                    </Typography>
                </Box>

                {authStore.canCreate(productMovementStore.moduleName) && (
                    <div className="crm-List__addButton">
                        <Button
                            startIcon={<LocalShippingIcon fontSize="large" style={{ paddingRight: '0.5rem' }} />}
                            primary={param === 'in'}
                            secondary={param === 'out'}
                            onClick={handleCreateProductMovement}
                            variant="contained"
                        >
                            Создать {param === 'out' ? 'Отгрузку' : 'Приход'}
                        </Button>
                    </div>
                )}

                {listErrors && <ListError errors={listErrors} />}
            </div>

            <Table size="small">
                <TableHead>
                    <ListPagination
                        loading={loadingList}
                        colSpan={6}
                        pagination={pagination}
                        listCount={listCount}
                        pageChange={productMovementStore.pageChange}
                        pageSizeChange={productMovementStore.pageSizeChange}
                        nextListLoaded={nextListLoaded}
                    />
                </TableHead>
                <ProductMovementsTableHeader />

                <ProductMovementsTableBody productMovements={list} />

                <ListFooter
                    loading={loadingList}
                    colSpan={6}
                    pagination={pagination}
                    listCount={listCount}
                    pageChange={productMovementStore.pageChange}
                    pageSizeChange={productMovementStore.pageSizeChange}
                    nextListLoaded={nextListLoaded}
                    scrollToElementId="crm_ListTop"
                />
            </Table>
        </Container>
    );
};

export default ListWrapper(productMovementStore, observer(ProductsMovementsList));
