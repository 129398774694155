import React, { Component, Fragment, ReactNode, SyntheticEvent } from 'react';
import { ComponentType } from 'react';
import { observer } from 'mobx-react';
import { Menu, Button, Segment, Icon, Grid } from 'semantic-ui-react';
import Modal, { ModalContent, ModalBtnAction } from '~/components/Base/ui/Modal';

import { HandleChangeType } from '../Items/HandlerEditingBlockWrapper';
import { ESTATE_BASE_GROUP, ESTATE_BASE_MAIN, ESTATE_BASE_OWNERS } from '~/types/estate.types';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import authStore from '~/stores/authStore';
import userStore from '~/stores/userStore';

import ListFilterHistory from './Common/ListFilterHistory';

import { AWAIT_LOADER_SHOW_TIME_MS } from '../Base/LoaderAwait';
import UsersAndGroupsDropdown from './Common/UsersAndGroupsDropdown';
import commonStore from '~/stores/commonStore';

const pultMenu = [
    { name: 'Компания', enable: true, color: null },
    { name: 'Архив', enable: false, color: 'red' }
];

export type ListFilterWrapperProps = {
    handleChange: HandleChangeType;
    handleCheckbox: (event: React.SyntheticEvent, data: { name: string; checked: boolean }) => void;
    setUnixTime: (string, number) => void;
    param?: string;
    setBlockOutsideClick: (event?: SyntheticEvent) => void;
};

export type ListFilterWrapperState = {
    visibleMoreDetails: boolean;
};

type ListFilterConfig = {
    disableMainUserDropdown?: boolean;
    multipleUser?: boolean;
};

export const deltaCreateTimes = [
    { key: 0, value: 0, text: 'Все время' },
    { key: 1, value: -1, text: 'Сегодня' },
    { key: 2, value: 86400, text: '24 часа' },
    { key: 3, value: -2, text: 'С понедельника' },
    { key: 4, value: 7 * 86400, text: '7 дней' },
    { key: 5, value: -3, text: 'С начала месяца' },
    { key: 6, value: 30 * 86400, text: '30 дней' },
    { key: 7, value: 50 * 86400, text: '50 дней' },
    { key: 8, value: 90 * 86400, text: '90 дней' }
];

export type FilterMoreDetailsProps = {
    handleChange: HandleChangeType;
    handleCheckbox: (event: SyntheticEvent, data: { name: string; checked: boolean }) => void;
};

type ListFilterWrapperClassProps = {
    children?: ReactNode;
    firstNode?: ReactNode;
    param?: string;
};

const ListFilterWrapper = (
    store: ListStoreInterface,
    WrappedComponent: ComponentType<any>,
    FilterMoreDetails?: ComponentType<any> | null,
    filterConfig?: ListFilterConfig
) => {
    @observer
    class ListFilterWrapperClass extends Component<ListFilterWrapperClassProps, ListFilterWrapperState> {
        state = {
            visibleMoreDetails: false
        };

        changeType = (event: React.SyntheticEvent, data: { name: string }) => {
            const { enable }: { enable: boolean } = pultMenu.find(menu => menu.name === data.name) || pultMenu[0];
            store.changeFilter('enable', enable);
        };

        handleChange = (event: SyntheticEvent, { type, name, value }: { type: string; name: string; value: string }) => {
            let val = value;
            if (type === 'clearable' && value === '') {
                val = null;
            }

            store.changeFilter(name, ['localNumber', 'number'].includes(type) ? Number(String(value).replace(/\D/g, '')) : val);
        };

        handleCheckbox = (event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => {
            event.preventDefault();
            store.changeFilter(name, checked);
        };

        handleMoreDetailsClick = () => {
            this.setState({
                visibleMoreDetails: !this.state.visibleMoreDetails
            });
            this.setBlockOutsideClick();
        };

        blockOutsideClick = false;

        closeMoreDetails = () => {
            if (this.state.visibleMoreDetails) {
                setTimeout(() => {
                    if (!this.blockOutsideClick) {
                        this.setState({ visibleMoreDetails: false });
                    }
                    this.blockOutsideClick = false;
                }, 100);
            }
        };

        handleRefresh = () => {
            // чтобы LoaderAwait отобразился
            store.startLoading();
            setTimeout(store.fetchList.bind(store), 2 * AWAIT_LOADER_SHOW_TIME_MS);
        };

        handleReset = () => {
            store.startLoading();
            store.clearFilter();
            // чтобы LoaderAwait отобразился
            setTimeout(() => {
                store.debounceFilterFetch();
            }, AWAIT_LOADER_SHOW_TIME_MS);
        };

        setUnixTime = (name: string, time: number) => {
            store.changeFilter(name, time);
        };

        setBlockOutsideClick = () => {
            this.blockOutsideClick = true;
            setTimeout(() => {
                this.blockOutsideClick = false;
            }, 200);
        };

        showListAndSave = () => {
            store.saveListSearch();
            this.handleMoreDetailsClick();
        };

        render() {
            const { loadingList, listCount } = store;
            const { enable, base } = store.listFilter;
            const { isMobile } = commonStore;

            const { visibleMoreDetails } = this.state;
            const { children, param, firstNode } = this.props;

            const config = filterConfig || {};

            const modalActions: ModalBtnAction[] = [
                { onClick: this.handleReset, label: 'Очистить', variant: 'text' },
                {
                    onClick: this.showListAndSave,
                    label: `Показать и Сохранить (${listCount.toLocaleString()})`,
                    variant: 'contained',
                    color: 'secondary',
                    loading: loadingList
                }
            ];

            const pultMenuFiltered = store === userStore && !authStore.canDelete(userStore.moduleName, []) ? [] : pultMenu;

            return (
                <Segment className="crm-List__segment crm-List__filterWrapper">
                    <Menu
                        pointing
                        secondary
                        stackable
                        className={`crm-List__filter ${enable ? '' : 'crm-List__filter_archive'} crm-Print__hidden`}
                        size="tiny"
                        onClick={this.setBlockOutsideClick}
                    >
                        {!isMobile && typeof FilterMoreDetails === 'function' && !visibleMoreDetails && (
                            <button
                                className="crm-List__filter_moreBtnBottom"
                                onClick={this.handleMoreDetailsClick}
                                color={visibleMoreDetails ? 'red' : null}
                            >
                                + еще детали
                            </button>
                        )}

                        <Menu.Item className="crm-List__filter_dropdown">
                            <Button.Group size="tiny">
                                <Button icon title="Обновить лист" onClick={this.handleRefresh} loading={loadingList}>
                                    <Icon name="refresh" />
                                </Button>
                                <Button
                                    color={!store.filterIsEmpty ? 'pink' : null}
                                    icon
                                    title="Сбросить фильтр"
                                    onClick={this.handleReset}
                                >
                                    <Icon name="close" />
                                </Button>
                            </Button.Group>
                        </Menu.Item>

                        {firstNode && (
                            <Menu.Item className="crm-List__filter_dropdown">
                                <div className="ui form mini">{firstNode}</div>
                            </Menu.Item>
                        )}

                        {(!base || [ESTATE_BASE_MAIN, ESTATE_BASE_OWNERS, ESTATE_BASE_GROUP].includes(base)) && (
                            <Fragment>
                                <Menu.Item className="crm-List__filter_dropdown">
                                    <div className="ui form mini">
                                        <UsersAndGroupsDropdown
                                            onOutsideClick={this.setBlockOutsideClick}
                                            store={store}
                                            disableGroups={base === ESTATE_BASE_GROUP}
                                            group_id={base === ESTATE_BASE_GROUP ? authStore.currentUser.group_id : null}
                                            disableUsers={config.disableMainUserDropdown}
                                        />
                                    </div>
                                </Menu.Item>
                            </Fragment>
                        )}

                        <WrappedComponent
                            {...this.props}
                            handleChange={this.handleChange}
                            handleCheckbox={this.handleCheckbox}
                            setUnixTime={this.setUnixTime}
                            param={param}
                            setBlockOutsideClick={this.setBlockOutsideClick}
                        />

                        <Menu.Menu position="right">
                            {typeof FilterMoreDetails === 'function' && (
                                <Fragment>
                                    {store.moduleName === 'estate' && store.listFiltersSaved.length > 0 && (
                                        <Menu.Item>
                                            <ListFilterHistory filters={store.listFiltersSaved} />
                                        </Menu.Item>
                                    )}
                                    <Menu.Item>
                                        <Button onClick={this.handleMoreDetailsClick} color={visibleMoreDetails ? 'red' : null}>
                                            Детали
                                        </Button>
                                    </Menu.Item>
                                </Fragment>
                            )}

                            {!isMobile &&
                                pultMenuFiltered.map(menu => (
                                    <Menu.Item
                                        key={`menu_${menu.enable.toString()}`}
                                        name={menu.name}
                                        color={menu.color as any}
                                        active={enable === menu.enable}
                                        onClick={this.changeType}
                                    />
                                ))}
                        </Menu.Menu>
                    </Menu>
                    {typeof FilterMoreDetails === 'function' && (
                        <Fragment>
                            {children}

                            {visibleMoreDetails && (
                                <Modal
                                    fullScreen={isMobile}
                                    header="Уточните детали для поиска"
                                    onClose={this.closeMoreDetails}
                                    actions={modalActions}
                                    maxWidth="sm"
                                >
                                    <ModalContent dividers>
                                        <FilterMoreDetails handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} />
                                    </ModalContent>
                                </Modal>
                            )}
                        </Fragment>
                    )}
                </Segment>
            );
        }
    }

    return ListFilterWrapperClass;
};

export default ListFilterWrapper;
