import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import Calendar from '../Calendar';
import authStore from '~/stores/authStore';
import calendarStore from '~/stores/calendarStore';
import { DAY_MS } from '../../common/time';
import { CALENDAR_ITEM_TYPE } from '~/types/calendar.types';
import { ACCESS_COMPANY, ACCESS_GROUP, ACCESS_READ, ACCESS_WRITE } from '~/types/access.types';
import ListError from '../ListError';
import commonStore from '~/stores/commonStore';

@observer
class HomeCalendar extends Component<{}, { startRange: number; endRange: number }> {
    constructor(props: {}) {
        super(props);

        const startRange = Math.round((Date.now() - 7 * DAY_MS) / 1000);
        const endRange = Math.round((Date.now() + 7 * DAY_MS) / 1000);

        calendarStore.currentUserId = authStore.currentUser.user_id;
        calendarStore.fetchCalendarEvents(startRange, endRange, true);

        this.state = {
            startRange,
            endRange
        };
    }

    handleCalendarRefresh = (startRange: number, endRange: number) => {
        this.setState({
            startRange,
            endRange
        });

        calendarStore.fetchCalendarEvents(startRange, endRange);
    };

    handleToggleFilter = (name: CALENDAR_ITEM_TYPE) => {
        const { startRange, endRange } = this.state;

        calendarStore.toggleFilterItemTypes(name);
        calendarStore.fetchCalendarEvents(startRange, endRange);
    };

    handleChangeUser = (user_id: number | Array<number>) => {
        if (typeof user_id === 'number' && user_id > 0) {
            calendarStore.currentUserId = user_id;
        } else {
            calendarStore.currentUserId = authStore.currentUser.user_id;
        }

        calendarStore.fetchCalendarEvents(this.state.startRange, this.state.endRange);
    };

    componentWillUnmount() {
        calendarStore.currentUserId = authStore.currentUser.user_id;
    }

    render() {
        const { events, loadingEvents, currentUserId, errors } = calendarStore;

        const groups_id =
            authStore.getPermission(calendarStore.moduleName, ACCESS_WRITE) === ACCESS_GROUP ? [authStore.currentUser.group_id] : [];

        const onChangeUser =
            !commonStore.isMobile && [ACCESS_GROUP, ACCESS_COMPANY].includes(authStore.getPermission(calendarStore.moduleName, ACCESS_READ))
                ? this.handleChangeUser
                : null;

        return (
            <Fragment>
                <ListError errors={errors} />

                <Calendar
                    className="crm-Home__calendar"
                    events={events}
                    loading={loadingEvents}
                    onRefresh={this.handleCalendarRefresh}
                    itemTypesAvailable={['callEvent', 'meetingEvent', 'showing', 'deal', 'user', 'contact', 'productMovement']}
                    itemTypes={calendarStore.filterItemTypes}
                    onItemTypeClick={this.handleToggleFilter}
                    onChangeUser={onChangeUser}
                    disableCreatingEvent={currentUserId !== authStore.currentUser.user_id}
                    user_id={currentUserId}
                    groups_id={groups_id}
                />
            </Fragment>
        );
    }
}

export default HomeCalendar;
