import React from 'react';
import { observer } from 'mobx-react';

import productStore from '~/stores/ff/productStore';
import authStore from '~/stores/authStore';

import Box from '@mui/material/Box';
import MoneyIcon from '@mui/icons-material/Money';

import Button from '~ui/Button';
import { useToggle } from '~ui/Modal';

import ContactPaymentModal from '~/components/Items/Contact/ShowBlocks/SubBlocks/ContactPaymentModal';
import ContactPaymentModeTable from './ShowBlocks/ContactPaymentModeTable';

const ContactPaymentMode = ({ contact_id }: { contact_id: number }) => {
    const [showModal, toggleModal] = useToggle();

    return (
        <>
            {authStore.canCreate(productStore.moduleName) && (
                <Box sx={{ pb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" startIcon={<MoneyIcon />} tooltip="Добавить баланс контакту" onClick={toggleModal}>
                        Начислить платеж
                    </Button>
                    {showModal && <ContactPaymentModal contact_id={contact_id} onClose={toggleModal} />}
                </Box>
            )}

            <ContactPaymentModeTable contact_id={contact_id} />
        </>
    );
};

export default observer(ContactPaymentMode);
