import React from 'react';

import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import productStore from '~/stores/ff/productStore';

const productKeys = {};

const printProductVal = (key: string, value: unknown): string => {
    if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(productStore, productKeys, printProductVal);
