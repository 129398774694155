import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import productStore from '~/stores/ff/productStore';
import Grid from '~ui/Grid';

import ProductMovementsTable from './ProductMovement/ProductMovementsTable';
import ProductMovementStatistics from './ProductMovement/ProductMovementStatistics';

const ProductMovementMode = ({ product_id }: { product_id: number }) => {
    useMemo(() => {
        productStore.productMovementsByProductId(product_id);
    }, [product_id]);

    return (
        <Grid stackable alignItems="flex-start">
            <Grid.Column width={9}>
                <ProductMovementsTable product_id={product_id} />
            </Grid.Column>

            <Grid.Column width={3}>
                <ProductMovementStatistics product_id={product_id} />
            </Grid.Column>
        </Grid>
    );
};

export default observer(ProductMovementMode);
