import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const cutString = (title: string, maxLength: number = 40): string => {
    return title.length > maxLength ? title.slice(0, maxLength) + '...' : title;
};

const CutString = ({ title, maxLength = 40 }: { title: string | null; maxLength?: number }) => {
    return (
        <Tooltip title={title || ''}>
            <span>{cutString(title || '', maxLength)}</span>
        </Tooltip>
    );
};

export default React.memo(CutString);
