import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { ProductMovementItemShort } from '~/types/ff/productMovement.types';

import productMovementStore from '~/stores/ff/productMovementStore';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { WarningMessage } from '~ui/Message';

import MovementItemsContainer from './MovementItems/MovementItemsContainer';

const ProductMovementItemsEditing = (props: { product_movement_id: number; items?: ProductMovementItemShort[]; contact_id?: number }) => {
    const { product_movement_id, items } = props;
    useMemo(() => {
        productMovementStore.setEditingItem(product_movement_id, {
            product_movement_id,
            items: items || []
        });
        // Нельзя объеденить с верхним, тк может переписаться при создании отгрузки, а тут для редактирования
        if (props.contact_id) {
            productMovementStore.setEditingItem(product_movement_id, {
                contact_id: props.contact_id
            });
        }
    }, []);

    const { contact_id } = productMovementStore.getItem(product_movement_id).editingItem;

    return (
        <Container maxWidth="md">
            <Paper>
                {!contact_id && <WarningMessage header="Укажите Контакт">Таблица с товарами появится после выбора Контакта</WarningMessage>}
                {contact_id && <MovementItemsContainer product_movement_id={product_movement_id} contact_id={contact_id} />}
            </Paper>
        </Container>
    );
};

export default observer(ProductMovementItemsEditing);
