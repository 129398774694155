import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import productMovementStore from '~/stores/ff/productMovementStore';
import productsL10n from '~/L10n/products.L10n';

import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import ButtonGroup from '~/components/Base/ButtonGroup';
import { ProductMovement } from '~/types/ff/productMovement.types';

type ProductMovementDirectionEditingProps = HandlerEditingWrappedProps;

const ProductMovementDirectionEditing = (props: ProductMovementDirectionEditingProps) => {
    const { param: initDirection = 'in' }: { param: ProductMovement['direction'] } = useParams();

    useMemo(() => {
        productMovementStore.setEditingItem(props.item_id, {
            direction: initDirection
        });
    }, [initDirection]);

    const { item_id, handleChange } = props;
    const { direction } = productMovementStore.getItem(item_id).editingItem;

    return (
        <div className="crm-Item__editingMode">
            <ButtonGroup
                buttonSet={productsL10n.PRODUCT_MOVEMENT_DIRECTION}
                name="direction"
                value={direction}
                handleChange={handleChange}
            />
        </div>
    );
};

export default HandlerEditingBlockWrapper(productMovementStore, observer(ProductMovementDirectionEditing));
