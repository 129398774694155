import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ListFooter from '../Common/ListFooter';
import dealStore from '~/stores/dealStore';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import authStore from '~/stores/authStore';
import { DealLinkState } from './DealLink';
import FilterDeals from './FilterDeals';
import DealTableBody from './DealTableBody';
import ListError from '../../ListError';
import { ESTATE_TYPE_RENT, ESTATE_TYPE_SELL } from '~/types/estate.types';
import commonStore from '~/stores/commonStore';
import ButtonGroup, { ButtonInSet } from '../../Base/ButtonGroup';
import Calendar from '../../Calendar';
import { CALENDAR_VIEW_TYPE_MONTH, CALENDAR_VIEW_TYPE_WEEK } from '~/types/calendar.types';
import {
    DEAL_TYPE_ACCOMPANIMENT,
    DEAL_TYPE_EXCLUSIVE,
    DEAL_TYPE_FF,
    DEAL_TYPE_FORKEYS,
    DEAL_TYPE_MORTGAGE,
    DEAL_TYPE_NEWBUILDING,
    DEAL_TYPE_SEO,
    dealOperationsId
} from '~/types/deals.types';
import { DAY_MS } from '~/common/time';
import DealPropertyShowing from '~/components/Items/Deal/ShowBlocks/DealPropertyShowing';

const SHOW_MODE_TABLE = 'table';
const SHOW_MODE_CALENDAR = 'calendar';

type DealShowModeType = 'table' | 'calendar';

const dealsViewSet: ButtonInSet[] = [
    [SHOW_MODE_TABLE, 'Таблица'],
    [SHOW_MODE_CALENDAR, 'Календарь']
];

type DealListState = {
    showMode: DealShowModeType;
};

@observer
class DealList extends Component<ListWrapperProps, DealListState> {
    constructor(props: ListWrapperProps) {
        super(props);
        this.state = {
            showMode: SHOW_MODE_TABLE
        };
    }

    tablePageSize: number;
    tableActivePage: number;

    handleChangeListViewMode = (event: React.SyntheticEvent, { value: showMode }: { value: DealShowModeType }) => {
        if (showMode === SHOW_MODE_CALENDAR) {
            this.tablePageSize = dealStore.pagination.pageSize;
            this.tableActivePage = dealStore.pagination.activePage;

            dealStore.pagination.pageSize = 10000;
            dealStore.pagination.activePage = 1;

            dealStore.fetchListCalendar(Math.round((Date.now() - 31 * DAY_MS) / 1000), Math.round((Date.now() + 31 * DAY_MS) / 1000));
        } else {
            dealStore.pagination.pageSize = this.tablePageSize;
            dealStore.pagination.activePage = this.tableActivePage;

            dealStore.changeFilter('releaseTimeMin', null);
            dealStore.changeFilter('releaseTimeMax', null);
        }

        this.setState({ showMode });
    };

    render() {
        const { orderBy, orderDirection, handleSort, param } = this.props;
        const { showMode } = this.state;
        const { loadingList, list, listCount, pagination, listErrors, nextListLoaded } = dealStore;
        let label;

        switch (param) {
            case ESTATE_TYPE_SELL:
                label = 'Сделки';
                break;
            case ESTATE_TYPE_RENT:
                label = 'Аренда';
                break;
            case DEAL_TYPE_EXCLUSIVE:
                label = 'Эксклюзивы';
                break;
            case DEAL_TYPE_ACCOMPANIMENT:
                label = 'Сопровождение';
                break;
            case DEAL_TYPE_NEWBUILDING:
                label = 'Новостройка';
                break;
            case DEAL_TYPE_MORTGAGE:
                label = 'Ипотека';
                break;
            case DEAL_TYPE_FF:
                label = 'Фулфилмент';
                break;
            case DEAL_TYPE_FORKEYS:
                label = 'Выкупы под ключ';
                break;
            case DEAL_TYPE_SEO:
                label = 'SEO';
                break;
            default:
                return null;
        }

        const isStatusCellAvailable = [
            ESTATE_TYPE_SELL,
            ESTATE_TYPE_RENT,
            DEAL_TYPE_ACCOMPANIMENT,
            DEAL_TYPE_MORTGAGE,
            DEAL_TYPE_NEWBUILDING
        ].includes(param);

        return (
            <Fragment>
                <div className="crm-List">
                    <div className="crm-List__filterType">
                        <Header as="h3">
                            <Icon name="money" />
                            <Header.Content className="crm-List__filterType_headerContent">{label}</Header.Content>
                        </Header>

                        <div className="crm-List__filterType_topBtns">
                            <ButtonGroup
                                buttonSet={dealsViewSet}
                                name="base"
                                value={showMode}
                                handleChange={this.handleChangeListViewMode}
                                stackable={commonStore.isMobile}
                            />
                        </div>

                        {listErrors && <ListError errors={listErrors} />}
                    </div>

                    {authStore.canCreate(dealStore.moduleName) && (
                        <div className="crm-List__addButton">
                            <Button icon labelPosition="left" primary size="tiny" as={Link} to={DealLinkState(0, param)}>
                                <Icon name="money" /> Добавить
                            </Button>
                        </div>
                    )}
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <FilterDeals param={param} />

                    {showMode === SHOW_MODE_TABLE && (
                        <Segment className="crm-List__segment">
                            <Table structured size="small" sortable compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell
                                            sorted={orderBy === 'deal_id' ? orderDirection : null}
                                            onClick={() => handleSort('deal_id')}
                                        >
                                            Дата
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            sorted={orderBy === 'price' ? orderDirection : null}
                                            onClick={() => handleSort('price')}
                                        >
                                            Стоимость
                                        </Table.HeaderCell>
                                        {isStatusCellAvailable && (
                                            <Table.HeaderCell
                                                sorted={orderBy === 'status' ? orderDirection : null}
                                                onClick={() => handleSort('status')}
                                            >
                                                Статус
                                            </Table.HeaderCell>
                                        )}
                                        <Table.HeaderCell
                                            sorted={orderBy === 'releaseTime' ? orderDirection : null}
                                            onClick={() => handleSort('releaseTime')}
                                        >
                                            {param === DEAL_TYPE_EXCLUSIVE ? 'Договор до' : 'Проведение'}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {dealStore.whetherIsNotEstate(dealOperationsId[param]) ? 'Заказчик' : 'Объект'}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell title="Прикрепленные документы">
                                            <Icon name="file alternate outline" />
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>Агент(ы)</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <DealTableBody dealsList={list} param={param} isStatusCellAvailable={isStatusCellAvailable} />

                                <ListFooter
                                    loading={loadingList}
                                    pagination={pagination}
                                    listCount={listCount}
                                    pageChange={dealStore.pageChange}
                                    pageSizeChange={dealStore.pageSizeChange}
                                    colSpan={isStatusCellAvailable ? 6 : 5}
                                    nextListLoaded={nextListLoaded}
                                    scrollToElementId="crm_ListTop"
                                />
                            </Table>
                        </Segment>
                    )}

                    {showMode === SHOW_MODE_CALENDAR && (
                        <Calendar
                            views={[CALENDAR_VIEW_TYPE_MONTH, CALENDAR_VIEW_TYPE_WEEK]}
                            defaultView={CALENDAR_VIEW_TYPE_MONTH}
                            events={dealStore.listToCalendar}
                            loading={loadingList}
                            className="crm-List__calendarMode"
                            onRefresh={dealStore.fetchListCalendar.bind(dealStore)}
                        />
                    )}
                </Segment.Group>
            </Fragment>
        );
    }
}

export default ListWrapper(dealStore, DealList);
