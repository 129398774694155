import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Dimmer, Loader, Label } from 'semantic-ui-react';
import DropzoneComponent from 'react-dropzone-component';
import config from '~/config/ui.config';
import documentStore from '~/stores/documentStore';
import { authHeaders } from '~/common/fetchApi';
import { DocumentTemp, AdditionalDocType } from '~/types/documents.types';
import DocumentRow from './DocumentRow';
import { ITEM_TYPES } from '~/types/notifications.types';
import ListError from '~/components/ListError';

import Table, { TableHead, TableCell, TableBody, TableRow } from '~ui/Table';
import DocumentsUrlUpload from './DocumentsUrlUpload';

const componentConfig = {
    showFiletypeIcon: false,
    postUrl: config.serverUrl + '/uploadDocsHandler'
};

const eventHandlers = (item_id: number, item_type: ITEM_TYPES) => ({
    drop: () => {},

    success: (file: File, { response }: { response: DocumentTemp }) => {
        documentStore.setError(null);
        documentStore.addTempDoc(response, item_id, item_type);
    },

    error: ({ name }, { message }) => {
        documentStore.setError(`${message}: ${name}`);
    }
});

const djsConfig = (item_id: number, item_type: ITEM_TYPES) => ({
    autoProcessQueue: true,
    addRemoveLinks: false,
    clickable: true,
    dictDefaultMessage: 'перетащите документ(ы) сюда',
    chunksUploaded: () => {
        console.log('chunksUploaded');
    },
    headers: authHeaders(),
    params: {
        item_id,
        item_type
    }
});

type DocumentsTableProps = {
    item_id: number;
    item_type: ITEM_TYPES;
    additionalTypes?: AdditionalDocType[];
};

type DocumentsTableState = {
    item_id: number;
    item_type: ITEM_TYPES;
    additionalTypes?: AdditionalDocType[];
};

const DZC: any = DropzoneComponent;

@observer
export default class DocumentsTable extends Component<DocumentsTableProps, DocumentsTableState> {
    constructor(props: DocumentsTableProps) {
        super(props);

        documentStore.setError(null);
        const { item_id, item_type, additionalTypes } = this.props;
        documentStore.fetchDocuments(item_id, item_type, additionalTypes);

        this.state = {
            item_id,
            item_type,
            additionalTypes
        };
    }

    render() {
        const { documentDirs, loadingDirs, documents, loadingDocuments, loadingError } = documentStore;
        const { item_id, item_type } = this.props;

        let lastDir = 0;
        let lastSourceTitle = '';

        return (
            <Fragment>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell>Документ</TableCell>
                            <TableCell colSpan={3} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.length > 0 &&
                            documents.map(({ dir_id, doc_id, sourceTitle }, index) => {
                                let DirTitle = null;
                                let SourceTitle = null;

                                if (!loadingDirs && dir_id !== lastDir) {
                                    lastDir = dir_id;
                                    const dir = documentDirs.find(doc => doc.dir_id === dir_id);
                                    if (dir) {
                                        DirTitle = (
                                            <TableRow key={dir_id}>
                                                <TableCell colSpan={4}>
                                                    <Label ribbon>{dir.title}</Label>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    } else {
                                        throw new Error(`Dir didn't find`);
                                    }
                                }

                                if (!loadingDirs && sourceTitle && sourceTitle !== lastSourceTitle) {
                                    SourceTitle = (
                                        <TableRow key={sourceTitle}>
                                            <TableCell colSpan={4}>
                                                <Label color="green" ribbon>
                                                    {sourceTitle}
                                                </Label>
                                            </TableCell>
                                        </TableRow>
                                    );

                                    lastSourceTitle = String(sourceTitle);
                                }

                                return [SourceTitle, DirTitle, <DocumentRow key={doc_id} index={index} />];
                            })}
                        {documents.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Label ribbon color="brown">
                                        Документов нет
                                    </Label>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                <br />
                <DocumentsUrlUpload item_id={item_id} item_type={item_type} />
                <br />
                <br />

                {loadingError && <ListError errors={[loadingError]} />}

                <DZC config={componentConfig} eventHandlers={eventHandlers(item_id, item_type)} djsConfig={djsConfig(item_id, item_type)} />

                <Dimmer active={loadingDocuments} inverted>
                    <Loader active size="medium" />
                </Dimmer>
            </Fragment>
        );
    }
}
