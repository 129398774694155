import React from 'react';
import { Link } from 'react-router-dom';
import { ProductLink as ProductLinkType } from '~/types/ff/product.types';

type ProductProps = {
    product: ProductLinkType;
};

export const ProductLinkState = (product_id: number) => ({
    pathname: `/products/list/overview/${product_id}`,
    state: { modal: true, switchFromTab: false }
});

export const productLinkTitle = ({ title, product_id }: ProductProps['product']): string => {
    return `№${product_id} ${title}`;
};

const ProductLink = ({ product: { product_id, title } }: ProductProps) => {
    return <Link to={ProductLinkState(product_id)}>{productLinkTitle({ product_id, title })}</Link>;
};

export default ProductLink;
