import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react';

import productMovementStore from '~/stores/ff/productMovementStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import TextInput, { ProgressAdornment } from '~ui/TextInput';
import Select, { SelectMenuType } from '~ui/Select';
import Button from '~ui/Button';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

const CreateDestinationInput = observer(
    ({ onClose, onCreate }: { onClose: () => void; onCreate: (destination_id: number | null) => void }) => {
        const { loadingDestinations } = productMovementStore;

        const [name, setName] = useState('');
        const handleChangeName = (event: ChangeEvent, { value }: { value: string }) => {
            setName(value);
        };

        const handleCreate = async () => {
            const destination_id = await productMovementStore.createDestination(name);
            onCreate(destination_id);
        };

        return (
            <TextInput
                autoFocus
                value={name}
                onChange={handleChangeName}
                fullWidth
                extraLabel={
                    loadingDestinations ? (
                        <ProgressAdornment />
                    ) : (
                        <Box>
                            <Button
                                icon={<AddIcon />}
                                tooltip="Добавить направление"
                                color="primary"
                                disabled={name.length < 4}
                                onClick={handleCreate}
                            />
                            <Button icon={<CloseIcon />} tooltip="Отменить" color="secondary" onClick={onClose} />
                        </Box>
                    )
                }
            />
        );
    }
);

const ProductMovementSelectDestination = ({
    destination_id,
    onChange
}: {
    destination_id: number | null;
    onChange: (destination_id: number) => void;
}) => {
    const { loadingDestinations, destinations } = productMovementStore;

    const handleChangeDestination = (event: ChangeEvent, { value }: { value: number | null }) => {
        onChange(value);
    };

    const destinationsDropDowns: SelectMenuType[] = destinations.map(({ destination_id, name }) => ({
        value: destination_id,
        text: name
    }));
    destinationsDropDowns.push({ value: CREATING_ITEM_ID, text: '+ Добавить новый вариант', style: { color: '#707070' } });

    return (
        <>
            {destination_id !== CREATING_ITEM_ID && (
                <Select
                    loading={loadingDestinations}
                    label="Направление отгрузки *"
                    value={destination_id}
                    onChange={handleChangeDestination}
                    options={destinationsDropDowns}
                    fullWidth
                    error={!destination_id}
                />
            )}
            {destination_id === CREATING_ITEM_ID && <CreateDestinationInput onClose={() => onChange(null)} onCreate={onChange} />}
        </>
    );
};

export default observer(ProductMovementSelectDestination);
