import { observable, action, runInAction } from 'mobx';
import { IObservableArray } from 'mobx';
import * as documentApi from '~/api/documentApi';
import { AdditionalDocType, DocumentDir, DocumentTemp, DocumentType } from '~/types/documents.types';
import { ITEM_TYPES } from '~/types/notifications.types';
import authStore from './authStore';
import { DropdownType } from './prototypes/ListStore.prototype';
import { nProgress } from './helpers/decorators.helpers';

class DocumentStore {
    documentDirs: Array<DocumentDir> = [];
    documentDirsDropdown: Array<DropdownType> = [];
    @observable
    loadingDirs = true;

    @action
    async fetchDirs() {
        this.documentDirs = await documentApi.fetchDocumentDirs();

        this.documentDirsDropdown = this.documentDirs.map(({ dir_id, title }) => ({
            key: dir_id,
            value: dir_id,
            text: title
        }));

        runInAction(() => {
            this.loadingDirs = false;
        });
    }

    @observable
    loadingDocuments = false;
    @observable
    documents: IObservableArray<DocumentType> = observable.array([]);

    @action
    async fetchDocuments(item_id: number, item_type: ITEM_TYPES, additionalTypes: AdditionalDocType[] = []) {
        this.loadingDocuments = true;

        await this.fetchDirs();

        const documents = await documentApi.fetchDocuments(item_id, item_type);

        await Promise.all(
            additionalTypes.map(async ({ item_id, item_type, sourceTitle }) => {
                const additionalDocuments = await documentApi.fetchDocuments(item_id, item_type);
                documents.push(
                    ...additionalDocuments.map(doc => {
                        doc.sourceTitle = sourceTitle;
                        return doc;
                    })
                );
            })
        );

        runInAction(async () => {
            this.documents = observable.array(documents);
            this.adjustDocSort();

            this.loadingDocuments = false;
        });
    }

    @action
    adjustDocSort() {
        this.documents = observable.array(Array.from(this.documents).sort((doc1, doc2) => doc1.dir_id - doc2.dir_id));
    }

    @action
    editDocument(index: number, editingProps: any, withSave = false) {
        const item = this.documents[index];
        this.documents[index] = { ...item, ...editingProps };

        if (withSave) {
            this.saveDocument(index);
        }

        this.adjustDocSort();
    }

    @action
    deleteDocument(index: number) {
        this.documents[index].enable = false;

        this.saveDocument(index);

        this.documents.remove(this.documents[index]);
    }

    @action
    addTempDoc(file: DocumentTemp, item_id: number, item_type: ITEM_TYPES) {
        const createTime = Math.round(Date.now() / 1000);

        const { firstName, lastName, user_id, enable } = authStore.currentUser;

        const major_user = {
            firstName,
            lastName,
            user_id,
            enable
        };

        const doc = {
            dir_id: 0,
            type: 'file',
            url: null,
            documentType: null,
            title: file.originalname,
            createTime,
            hash: null,
            enable: true,
            major_user_id: user_id,
            major_user,
            ...file
        } as const;

        this.documents.push(doc);
        this.adjustDocSort();
    }

    @nProgress
    @action
    async saveDocument(index: number) {
        const { doc_id, title, enable, dir_id } = this.documents[index];
        await documentApi.updateDocument(doc_id, { title, enable, dir_id });
    }

    @observable
    loadingError: string | null = null;
    @action
    setError(error: string | null) {
        this.loadingError = error;
    }

    @nProgress
    @action
    async addLinkDocument(item_id: number, item_type: ITEM_TYPES, url: string): Promise<void> {
        try {
            this.loadingDocuments = true;
            await documentApi.uploadLinkDocument(item_id, item_type, url);
            this.fetchDocuments(item_id, item_type);
        } catch (error) {
            this.setError(error);
        } finally {
            this.loadingDocuments = false;
        }
    }
}

export default new DocumentStore();
