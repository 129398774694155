import React, { PureComponent, Fragment, ChangeEvent } from 'react';
import { Input, Dropdown, InputOnChangeData } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { EstateFilterMain, ESTATE_PROPERTY_FLATS_ARR } from '~/types/estate.types';
import estateL10n from '~/L10n/estate.L10n';

import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import commonStore from '~/stores/commonStore';
import { isFloorAvailable, isTotalAreaAvailable } from '~/stores/helpers/estate.store.helpers';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

import MetroMapBlock from './MetroMapBlock';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import Checkbox from '~ui/Checkbox';
import FilterCell from '~/components/Lists/Common/FilterCell';

const InputMinMax = ({
    minName,
    maxName,
    minValue,
    maxValue,
    title,
    onChange,
    min,
    max,
    smartValue,
    disableCurrency
}: {
    minName: string;
    maxName: string;
    minValue: number | null;
    maxValue: number | null;
    title: string;
    onChange: (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
    min: number;
    max: number;
    smartValue?: boolean;
    disableCurrency?: boolean;
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        onChange(event, { type: 'text', name: event.target.name, value: Number(event.target.value.replace(/\D/g, '')) });
    };

    return (
        <Grid container spacing={2} justify="space-around" alignItems="center">
            <Grid item xs={6}>
                <TextField
                    type={smartValue ? 'text' : 'number'}
                    label={`${title}, от`}
                    placeholder={`${title}, от`}
                    variant="outlined"
                    inputProps={{ min, max, name: minName }}
                    value={minValue ? (smartValue ? minValue.toLocaleString() : minValue) : ''}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                        endAdornment: !disableCurrency ? <InputAdornment position="end">₽</InputAdornment> : undefined
                    }}
                    size="small"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type={smartValue ? 'text' : 'number'}
                    label={`${title}, до`}
                    placeholder={`${title}, до`}
                    error={minValue && maxValue && maxValue < minValue}
                    variant="outlined"
                    inputProps={{ min, max, name: maxName }}
                    value={maxValue ? (smartValue ? maxValue.toLocaleString() : maxValue) : ''}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                        endAdornment: !disableCurrency ? <InputAdornment position="end">₽</InputAdornment> : undefined
                    }}
                    size="small"
                />
            </Grid>
        </Grid>
    );
};

type FilterEstateMainProps = EstateFilterMain & {
    handleChange: HandleChangeType;
    handleMetroChange?: (metroIds: number[]) => void;
    simpleMode?: boolean;
    store?: ListStoreInterface;
    isForeign?: boolean;
};

@observer
class FilterEstateMain extends PureComponent<FilterEstateMainProps, EstateFilterMain> {
    constructor(props: FilterEstateMainProps) {
        super(props);
        const { handleChange, ...state } = props;
        this.state = state;
    }

    handleChange = (event: React.SyntheticEvent, { type, name, value }: InputOnChangeData) => {
        const val = type === 'number' || name === 'priceMin' || name === 'priceMax' ? Number(String(value).replace(/\D/g, '')) : value;
        // @ts-ignore
        this.setState({ [name]: val });
        this.props.handleChange(event, { type, name, value: val });
    };

    handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const { type, name, checked } = event.target;
        // @ts-ignore
        this.setState({ [name]: checked });
        this.props.handleChange(event, { type, name, value: checked });
    };

    handleArrayCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const { type, name, checked, value } = event.target;
        if (!(this.state[name] instanceof Array)) {
            throw new Error('Have to be an array');
        }

        const array = [...this.state[name]];
        if (checked) {
            array.push(Number(value));
        } else {
            array.splice(array.indexOf(value), 1);
        }
        // @ts-ignore
        this.setState({ [name]: Array.from(new Set(array)) });

        this.props.handleChange(event, { name, type, value: array });
    };

    handleRoomsNumber = (event: React.SyntheticEvent, { value, type }: { value: Array<number>; type: string }) => {
        this.setState({ roomsCount: value });
        this.props.handleChange(event, { type, name: 'roomsCount', value });
    };

    static getDerivedStateFromProps(newProps: FilterEstateMainProps) {
        const { handleChange, ...state } = newProps;
        return state;
    }

    handleMetroChange = (metroIds: number[]) => {
        if (!this.props.simpleMode && this.props.store) {
            this.props.store.changeFilter('main_metro_id', metroIds);
        } else {
            if (typeof this.props.handleMetroChange === 'function') {
                this.props.handleMetroChange(metroIds);
            }
        }
    };

    checkPropertyTypes = (propertySet: number[]): boolean => {
        const { propertyTypes } = this.state;
        return propertyTypes.length > 0 && propertyTypes.every(type => propertySet.includes(type));
    };

    isTotalAreaAvailable = (): boolean => {
        return isTotalAreaAvailable(this.state.propertyTypes);
    };

    isFloorAvailable = (): boolean => {
        return isFloorAvailable(this.state.propertyTypes);
    };

    isFlatsOnly = (): boolean => {
        return this.checkPropertyTypes(ESTATE_PROPERTY_FLATS_ARR);
    };

    render() {
        const {
            priceMin,
            regionId,
            priceMax,
            priceM2Min,
            priceM2Max,
            saleType,

            totalAreaMin,
            totalAreaMax,
            livingAreaMin,
            livingAreaMax,
            kitchenAreaMin,
            kitchenAreaMax,

            floorNumberMin,
            floorNumberMax,
            floorsCountMin,
            lastFloorOnly,

            materialType,
            buildYearMin,
            buildYearMax,
            transportTime,
            transportType,

            roomsCount,

            hasLoggia,
            hasBalcone,
            wcType,

            showApartmentsOnly,
            main_metro_id
        } = this.state;

        const { simpleMode, isForeign } = this.props;
        const roomsNumberArr = (roomsCount || []).map(value => value);

        return (
            <Fragment>
                {this.isFlatsOnly() && (
                    <FilterCell title="Комнат">
                        <Grid item sm={6} xs={12}>
                            <Dropdown
                                placeholder="количество комнат"
                                closeOnChange
                                selection
                                options={estateL10n.ROOMS_COUNT_DROPDOWN}
                                value={roomsNumberArr}
                                multiple
                                onChange={this.handleRoomsNumber}
                            />
                        </Grid>
                        {!simpleMode && (
                            <Fragment>
                                <Grid item sm={6} xs={1} />
                                <Grid item sm={6} xs={11}>
                                    <Typography variant="subtitle1">
                                        <Checkbox
                                            checked={showApartmentsOnly}
                                            name="showApartmentsOnly"
                                            label="Апартаменты"
                                            onChange={this.handleCheckbox}
                                        />
                                    </Typography>
                                </Grid>
                            </Fragment>
                        )}
                    </FilterCell>
                )}

                <FilterCell title="Цена">
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">
                            {!simpleMode && (
                                <Dropdown
                                    inline
                                    options={[{ key: -1, value: 0, text: 'Любой тип продажи' }, ...estateL10n.SALE_TYPES_DROPDOWN]}
                                    value={saleType || 0}
                                    name="saleType"
                                    onChange={this.handleChange}
                                    className="crm-Estate__squareInput"
                                />
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputMinMax
                            smartValue={true}
                            minName="priceMin"
                            minValue={priceMin}
                            maxName="priceMax"
                            maxValue={priceMax}
                            title="Цена"
                            onChange={this.handleChange}
                            min={1}
                            max={999999999}
                            disableCurrency={isForeign}
                        />
                    </Grid>
                    {this.isTotalAreaAvailable() && (
                        <Grid item xs={12}>
                            <InputMinMax
                                smartValue={true}
                                minName="priceM2Min"
                                minValue={priceM2Min}
                                maxName="priceM2Max"
                                maxValue={priceM2Max}
                                title="За м²"
                                onChange={this.handleChange}
                                min={1}
                                max={999999999}
                                disableCurrency={isForeign}
                            />
                        </Grid>
                    )}
                </FilterCell>

                {[0, 100, 77].includes(regionId) && !isForeign && (
                    <FilterCell title="Метро" titleFullWidth>
                        <Grid item xs={12}>
                            {main_metro_id && (
                                <Fragment>
                                    {main_metro_id.length === 1 && 'Выбрана одна станция '}
                                    {main_metro_id.length === 2 && 'Выбраны две станции '}
                                    {main_metro_id.length === 3 && 'Выбраны три станции '}
                                    {main_metro_id.length === 4 && 'Выбраны четыре станции '}
                                    {main_metro_id.length >= 5 && `Выбрано ${main_metro_id.length} станций `}
                                </Fragment>
                            )}
                            <MetroMapBlock
                                store={this.props.store}
                                main_metro_ids={main_metro_id}
                                onChangeMetro={this.handleMetroChange}
                                simpleMode={simpleMode}
                            />
                            не более &nbsp;
                            <Input
                                type="number"
                                size="mini"
                                min={0}
                                max={99}
                                label={
                                    <Dropdown
                                        options={estateL10n.TRANSPORT_TYPES_DROPDOWN}
                                        name="transportType"
                                        value={transportType}
                                        onChange={this.handleChange}
                                    />
                                }
                                labelPosition="right"
                                placeholder="минут"
                                name="transportTime"
                                value={transportTime || ''}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                        </Grid>
                    </FilterCell>
                )}
                {this.isTotalAreaAvailable() && (
                    <FilterCell titleFullWidth title="Площадь">
                        {(this.isFlatsOnly()
                            ? [
                                  { n1: 'totalAreaMin', v1: totalAreaMin, n2: 'totalAreaMax', v2: totalAreaMax, L: 'Общая' },
                                  { n1: 'livingAreaMin', v1: livingAreaMin, n2: 'livingAreaMax', v2: livingAreaMax, L: 'Жилая' },
                                  { n1: 'kitchenAreaMin', v1: kitchenAreaMin, n2: 'kitchenAreaMax', v2: kitchenAreaMax, L: 'Кухни' }
                              ]
                            : [{ n1: 'totalAreaMin', v1: totalAreaMin, n2: 'totalAreaMax', v2: totalAreaMax, L: 'Общая' }]
                        )
                            .slice(0, commonStore.isMobile ? 1 : Infinity)
                            .map(({ n1, v1, n2, v2, L }) => (
                                <Grid item xs={12} key={n1}>
                                    <InputMinMax
                                        minName={n1}
                                        minValue={v1}
                                        maxName={n2}
                                        maxValue={v2}
                                        title={L}
                                        onChange={this.handleChange}
                                        min={1}
                                        max={999}
                                    />
                                </Grid>
                            ))}
                    </FilterCell>
                )}
                {this.isFloorAvailable() && (
                    <FilterCell title="Этаж" titleFullWidth>
                        <Grid item xs={12}>
                            <InputMinMax
                                minName="floorNumberMin"
                                minValue={floorNumberMin}
                                maxName="floorNumberMax"
                                maxValue={floorNumberMax}
                                title="Этаж"
                                onChange={this.handleChange}
                                min={1}
                                max={999}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label className="crm-Estate__field_labelFlex">Дом не ниже</label>
                            <Input
                                placeholder="этажей"
                                type="number"
                                min={1}
                                max={999}
                                name="floorsCountMin"
                                value={floorsCountMin || ''}
                                size="mini"
                                onChange={this.handleChange}
                                className="crm-Estate__squareInput"
                                autoComplete="off"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Checkbox
                                checked={Boolean(lastFloorOnly)}
                                name="lastFloorOnly"
                                label={<label>Последний</label>}
                                onChange={this.handleCheckbox}
                            />
                        </Grid>
                    </FilterCell>
                )}
                {!isForeign && this.isFlatsOnly() && (
                    <FilterCell title="Тип дома" titleFullWidth spacing={0}>
                        {estateL10n.HOUSE_MATERIAL_TYPES_DROPDOWN.map(({ value, text }: { value: number; text: string }) => (
                            <Grid item xs={6} key={value}>
                                <Checkbox
                                    checked={materialType && materialType.includes(value)}
                                    value={value}
                                    name="materialType"
                                    label={<label>{text}</label>}
                                    onChange={this.handleArrayCheckbox}
                                />
                            </Grid>
                        ))}

                        <Grid item xs={12}>
                            <InputMinMax
                                minName="buildYearMin"
                                maxName="buildYearMax"
                                minValue={buildYearMin}
                                maxValue={buildYearMax}
                                title="Год постройки"
                                onChange={this.handleChange}
                                min={1700}
                                max={2100}
                            />
                        </Grid>
                    </FilterCell>
                )}

                {!isForeign && this.isFlatsOnly() && (
                    <FilterCell title="Удобства">
                        <Grid item xs={6}>
                            <Dropdown
                                inline
                                options={estateL10n.WC_TYPE_DROPDOWN}
                                value={wcType}
                                name="wcType"
                                onChange={this.handleChange}
                                className="crm-Estate__squareInput"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox
                                checked={Boolean(hasBalcone)}
                                name="hasBalcone"
                                label={<label>Балкон</label>}
                                onChange={this.handleCheckbox}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox
                                checked={Boolean(hasLoggia)}
                                name="hasLoggia"
                                label={<label>Лоджия</label>}
                                onChange={this.handleCheckbox}
                            />
                        </Grid>
                    </FilterCell>
                )}
            </Fragment>
        );
    }
}

export default FilterEstateMain;
