import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import Button from '~ui/Button';
import { useToggle } from '~ui/Modal';

import productStore from '~/stores/ff/productStore';

import ProductMovementCreateModal from './ProductMovementCreateModal';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import InputIcon from '@material-ui/icons/Input';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import StorageIcon from '@material-ui/icons/Storage';
import Typography from '@material-ui/core/Typography';

const CreateMovementInBtn = ({ product_id, contact_id }: { product_id: number; contact_id: number }) => {
    const [showModal, toggleModal] = useToggle();

    return (
        <>
            <Button icon={<AddCircleOutlineIcon />} tooltip="Создать приход товара" onClick={toggleModal} primary />
            {showModal && (
                <ProductMovementCreateModal direction="in" onClose={toggleModal} product_id={product_id} contact_id={contact_id} />
            )}
        </>
    );
};

const CreateMovementOutBtn = ({ product_id, contact_id }: { product_id: number; contact_id: number }) => {
    const [showModal, toggleModal] = useToggle();

    return (
        <>
            <Button icon={<ArrowDropDownCircleIcon />} tooltip="Создать отгрузку товара" onClick={toggleModal} secondary />
            {showModal && (
                <ProductMovementCreateModal direction="out" onClose={toggleModal} product_id={product_id} contact_id={contact_id} />
            )}
        </>
    );
};

const ProductMovementStatistics = ({ product_id }: { product_id: number }) => {
    useMemo(() => {
        productStore.fetchCountOnStore(product_id);
    }, [product_id]);

    const { loadingCount, productOnStoreStat } = productStore.getItem(product_id).property;
    const { contact_id } = productStore.getItem(product_id).item || {};

    return (
        <Paper>
            <List disablePadding dense>
                <ListItem divider>
                    <ListItemIcon>
                        <StorageIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h6">
                                {loadingCount ? '--' : productOnStoreStat?.countOnStorage.toLocaleString('ru')}
                            </Typography>
                        }
                        secondary="Остаток товара"
                    ></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <InputIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={loadingCount ? '--' : productOnStoreStat?.countIn.toLocaleString('ru')}
                        secondary="Приход товара"
                    ></ListItemText>
                    <ListItemSecondaryAction>
                        <CreateMovementInBtn product_id={product_id} contact_id={contact_id} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <AssignmentReturnIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={loadingCount ? '--' : productOnStoreStat?.countOut.toLocaleString('ru')}
                        secondary="Отгрузка товара"
                    ></ListItemText>
                    <ListItemSecondaryAction>
                        <CreateMovementOutBtn product_id={product_id} contact_id={contact_id} />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </Paper>
    );
};

export default observer(ProductMovementStatistics);
