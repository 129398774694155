import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';

import productStore from '~/stores/ff/productStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ItemAddModeWrapper from '../ItemAddModeWrapper';
import { ProductLinkState } from '~/components/Lists/Products/ProductLink';

import Container from '@material-ui/core/Container';

import Divider from '~ui/Divider';

import CreationButtonsSet from '~/components/Items/Common/CreationButtonsSet';
import ProductMainEditing from './EditBlocks/ProductMainEditing';
import ProductSizeEditing from './EditBlocks/ProductSizeEditing';
import ProductContactEditing from './EditBlocks/ProductContactEditing';
import ProductAspectsEditing from './EditBlocks/ProductAspectsEditing';

type ProductAddModeProps = RouteComponentProps<{}> & ItemAddModeWrapperProps;

const ProductAddMode = ({ handleCreate, clearForm }: ProductAddModeProps) => {
    const { loadingItem } = productStore.getItem(CREATING_ITEM_ID);

    return (
        <Container maxWidth="md">
            <ProductContactEditing item_id={CREATING_ITEM_ID} />
            <ProductMainEditing item_id={CREATING_ITEM_ID} />
            <ProductSizeEditing item_id={CREATING_ITEM_ID} />
            <Divider />
            <ProductAspectsEditing item_id={CREATING_ITEM_ID} />
            <Divider />
            <CreationButtonsSet loading={loadingItem} onCreate={handleCreate} onClearForm={clearForm} />
        </Container>
    );
};

export default ItemAddModeWrapper(productStore, ProductLinkState, withRouter(observer(ProductAddMode)));
