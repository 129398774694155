import React, { Component, Fragment } from 'react';
import { Button, Divider } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';

import ItemAddModeWrapper from '../ItemAddModeWrapper';

import DealPropertyEditing from './EditBlocks/DealPropertyEditing';
import DealCustomersEditing from './EditBlocks/DealCustomersEditing';
import DealStatusEditing from './EditBlocks/DealStatusEditing';
import DealPriceEditing from './EditBlocks/DealPriceEditing';
import DealTypeEditing from './EditBlocks/DealTypeEditing';
import DealPaymentsEditing from './EditBlocks/DealPaymentsEditing';

import dealStore from '~/stores/dealStore';
import { DealLinkState } from '../../Lists/Deals/DealLink';
import { ESTATE_TYPE_SELL_ID } from '~/types/estate.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { DealOperationType } from '~/types/deals.types';
import { dealOperationsId } from '~/types/deals.types';
import CreationButtonsSet from '../Common/CreationButtonsSet';

import Container from '@material-ui/core/Container';

type DealAddModeProps = RouteComponentProps<{ param: DealOperationType }> & ItemAddModeWrapperProps;

@observer
class DealAddMode extends Component<DealAddModeProps> {
    constructor(props: DealAddModeProps) {
        super(props);

        const { param: operationType } = props.match.params;

        dealStore.setEditingItem(CREATING_ITEM_ID, { type: dealOperationsId[operationType] });
    }

    render() {
        const { clearForm, handleCreate } = this.props;

        const {
            loadingItem,
            editingItem: { type }
        } = dealStore.getItem(CREATING_ITEM_ID);

        return (
            <Container maxWidth="md">
                <DealTypeEditing item_id={CREATING_ITEM_ID} type={type} />
                <Divider />
                {!dealStore.whetherIsNotEstate(type) && (
                    <>
                        <DealPropertyEditing item_id={CREATING_ITEM_ID} type={type || ESTATE_TYPE_SELL_ID} />
                        <Divider />
                    </>
                )}
                <DealCustomersEditing item_id={CREATING_ITEM_ID} />
                <Divider />
                <DealStatusEditing item_id={CREATING_ITEM_ID} type={type} />
                <Divider />
                <DealPriceEditing item_id={CREATING_ITEM_ID} type={type} />
                <Divider />
                <DealPaymentsEditing item_id={CREATING_ITEM_ID} />
                <Divider />
                <CreationButtonsSet loading={loadingItem} onCreate={handleCreate} onClearForm={clearForm} />
            </Container>
        );
    }
}

export default ItemAddModeWrapper(dealStore, DealLinkState, withRouter(DealAddMode));
