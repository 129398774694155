import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import productStore from '~/stores/ff/productStore';
import commonStore from '~/stores/commonStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import { NumberInput } from '~ui/TextInput';
import Grid from '~ui/Grid';

type SizeBlockEditingProps = HandlerEditingWrappedProps & {
    simpleMode?: boolean;

    weight?: number;
    length?: number;
    height?: number;
    width?: number;
};

const ProductSizeEditing = (props: SizeBlockEditingProps) => {
    useMemo(() => {
        const item = {
            product_id: props.item_id,
            weight: props.weight || null,
            length: props.length || null,
            height: props.height || null,
            width: props.width || null
        };
        productStore.setEditingItem(item.product_id, item);
    }, []);

    const { item_id, handleChange, simpleMode } = props;
    const { weight, length, height, width } = productStore.getItem(item_id).editingItem;

    return (
        <Grid columns={simpleMode || commonStore.isMobile ? 3 : 4}>
            <Grid.Column>
                <NumberInput
                    label="Длина упаковки"
                    size="small"
                    value={length || 0}
                    name="length"
                    onChange={handleChange}
                    variant="classic"
                    fullWidth
                    extraLabel="мм"
                />
            </Grid.Column>
            <Grid.Column>
                <NumberInput
                    label="Ширина упаковки"
                    size="small"
                    value={width || 0}
                    name="width"
                    onChange={handleChange}
                    variant="classic"
                    fullWidth
                    extraLabel="мм"
                />
            </Grid.Column>
            <Grid.Column>
                <NumberInput
                    label="Высота упаковки"
                    size="small"
                    value={height || 0}
                    name="height"
                    onChange={handleChange}
                    variant="classic"
                    fullWidth
                    extraLabel="мм"
                />
            </Grid.Column>
            <Grid.Column>
                <NumberInput
                    label="Вес с упаковкой"
                    size="small"
                    value={weight || 0}
                    name="weight"
                    onChange={handleChange}
                    variant="classic"
                    fullWidth
                    extraLabel="г"
                />
            </Grid.Column>
        </Grid>
    );
};

export default HandlerEditingBlockWrapper(productStore, observer(ProductSizeEditing));
