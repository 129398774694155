import React, { useEffect, useState } from 'react';

import * as productApi from '~/api/ff/productApi';
import { ProductList } from '~/types/ff/product.types';
import productMovementStore from '~/stores/ff/productMovementStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import { WarningMessage } from '~ui/Message';
import Progress from '~ui/Progress';

import ListError from '~/components/ListError';
import MovementItemsTable from './MovementItemsTable';

const MovementItemsContainer = ({ contact_id, product_movement_id }: { contact_id: number; product_movement_id: number }) => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [products, setProducts] = useState<ProductList[]>([]);

    useEffect(() => {
        setLoading(true);
        setErrors([]);
        setProducts([]);
        if (product_movement_id === CREATING_ITEM_ID) {
            productMovementStore.setEditingItem(product_movement_id, {
                items: []
            });
        }
        productApi
            .fetchProductByContact(contact_id)
            .then(setProducts)
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, [contact_id]);

    return (
        <>
            <ListError errors={errors} />
            {loading && <Progress show={loading} linear={true} />}
            {!loading && products.length === 0 && (
                <WarningMessage header="Товары не найдены">У данного Контакта нет товаров</WarningMessage>
            )}
            {products.length > 0 && <MovementItemsTable product_movement_id={product_movement_id} products={products} />}
        </>
    );
};

export default MovementItemsContainer;
