import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'semantic-ui-react';
import urlify from '~/common/urlify';
import nl2br from '~/common/nl2br';

import productMovementStore from '~/stores/ff/productMovementStore';

import ShadowFragment from '~/components/Base/ShadowFragment';
import ProductMovementMainEditing from '../EditBlocks/ProductMovementMainEditing';

import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapperNew';
import LabelArchive from '~/components/Items/Common/LabelArchive';

import Time from '~/components/Base/Time';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

const ProductMovementMainShow = (props: EditingWrappedProps) => {
    const { item_id, editing } = props;
    const { item, loadingItem } = productMovementStore.getItem(item_id);
    const { createTime, updateTime, enable, about, direction } = item || {};

    return (
        <Fragment>
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Card.Header>
                                {!loadingItem && !enable && <LabelArchive ribbon />}№{item_id}&nbsp;
                                <ShadowFragment print={!loadingItem && `${direction === 'in' ? 'Приход товара' : 'Отгрузка товара'}`} />
                            </Card.Header>
                            <Card.Meta>
                                <div className="date">
                                    Карточка создана <Time time={createTime} />
                                </div>
                                {updateTime > 0 && createTime !== updateTime && (
                                    <div className="date">
                                        Карточка обновлена <Time time={updateTime} />
                                    </div>
                                )}
                            </Card.Meta>
                            {about && (
                                <Card.Description style={{ padding: '10px 0 15px' }}>
                                    <span dangerouslySetInnerHTML={{ __html: !loadingItem && nl2br(urlify(about)) }} />
                                </Card.Description>
                            )}
                        </Fragment>
                    )}
                    {editing && (
                        <div style={{ paddingTop: '1.5rem' }}>
                            <ProductMovementMainEditing item_id={item_id} {...item} />
                        </div>
                    )}
                </Card.Content>
            </Card>
        </Fragment>
    );
};

export default EditingBlockWrapper(productMovementStore, observer(ProductMovementMainShow));
