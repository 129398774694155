import React, { ReactNode, CSSProperties, ChangeEvent, SyntheticEvent, useState } from 'react';
import { createMask, MaskedPattern } from 'imask';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import { HandleChangeType } from '../EditField';
import Progress from './Progress';
import TextField from '@material-ui/core/TextField';

type TextProps = {
    type?: string;
    label?: string | ReactNode;
    value?: any;
    name?: string | number;
    onChange?: HandleChangeType;
    loading?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    extraLabel?: string | ReactNode;
    startLabel?: string | ReactNode;
    fullWidth?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    size?: 'medium' | 'small';
    variant?: 'standard' | 'outlined' | 'classic' | 'filled';
    minLength?: number;
    maxLength?: number;
    className?: string;
    margin?: 'none' | 'dense' | 'normal';
    helperText?: ReactNode;
    pattern?: string | RegExp;
    title?: string;
    mask?: string;
    readOnly?: boolean;
    error?: boolean;
} & (
    | {
          multiline?: false;
      }
    | {
          multiline?: true;
          rowsMax: number;
          rows: number;
      }
);

export const checkValidPattern = (value: string, pattern: string | RegExp): boolean => {
    let re = typeof pattern === 'string' ? new RegExp(pattern) : pattern;
    return re.test(value);
};

const CachedMasks: Record<string, MaskedPattern> = {};
const adjustValue = (value: any, mask?: string) => {
    if (mask) {
        const masked =
            CachedMasks[mask] ||
            createMask({
                // mask: '+7 (000) 000-00-00'
                mask
            });
        CachedMasks[mask] = masked;
        return masked.resolve(value);
    }
    return value;
};

export const ProgressAdornment = React.memo(() => (
    <div style={{ position: 'relative', right: '24px' }}>
        <Progress show size={24} />
    </div>
));

const TextInput = (props: TextProps) => {
    const {
        label,
        type,
        onChange,
        fullWidth = true,
        name,
        disabled,
        loading,
        extraLabel,
        startLabel,
        size = 'medium',
        required,
        autoComplete,
        variant = 'standard',
        minLength,
        maxLength,
        className,
        multiline,
        margin,
        style,
        helperText,
        mask,
        pattern,
        title,
        readOnly,
        error,
        autoFocus
    } = props;

    let { value } = props;
    // при первой инициализации
    value = value && adjustValue(value, mask);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let { value } = event.target;
        onChange(event, { type: 'text', name: String(name) || '', value: adjustValue(value, mask) });
    };

    const endAdornment = loading ? <ProgressAdornment /> : extraLabel ? <InputAdornment position="end">{extraLabel}</InputAdornment> : null;

    const isError = error || (pattern && value ? !checkValidPattern(value, pattern) : false);

    return (
        <TextField
            error={isError}
            autoFocus={autoFocus}
            className={className}
            fullWidth={fullWidth}
            disabled={disabled}
            label={label}
            type={type || 'text'}
            required={required}
            onChange={handleChange}
            autoComplete={autoComplete}
            value={value ?? ''}
            size={size}
            variant={variant === 'classic' ? 'outlined' : variant}
            margin={margin}
            multiline={multiline}
            rows={props.multiline === true ? props.rows : null}
            rowsMax={props.multiline === true ? props.rowsMax : null}
            InputProps={{
                startAdornment: startLabel ? <InputAdornment position="start">{startLabel}</InputAdornment> : null,
                endAdornment
            }}
            inputProps={{
                minLength,
                maxLength,
                pattern: typeof pattern === 'string' ? pattern : undefined,
                title,
                autoComplete,
                readOnly
            }}
            style={variant === 'classic' ? { background: 'white' } : style}
            helperText={helperText}
        />
    );
};

type NumberInputProps = TextProps & {
    value: number | null;
    min?: number;
    max?: number;
    step?: number;
};

export const NumberInput = ({
    label,
    onChange,
    startLabel,
    fullWidth,
    value,
    name,
    disabled,
    loading,
    style,
    extraLabel,
    min,
    max,
    step
}: NumberInputProps) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value.replace(',', '.').replace(/\D\.-/g, ''));
        onChange(event, { type: 'number', name: String(name) || '', value });
    };

    return (
        <FormControl disabled={disabled} fullWidth={fullWidth} style={style}>
            {label && <InputLabel>{label}</InputLabel>}
            <Input
                type="number"
                value={typeof value === 'number' ? String(value) : ''}
                onChange={handleChange}
                inputProps={{ min, max, step }}
                endAdornment={extraLabel ? <InputAdornment position="end">{extraLabel}</InputAdornment> : null}
                startAdornment={startLabel ? <InputAdornment position="start">{startLabel}</InputAdornment> : null}
            />
            {loading && <Progress show size={24} />}
        </FormControl>
    );
};

export const PriceInput = (props: TextProps) => {
    const [currentValue, setCurrentValue] = useState(props.value || '');
    const { value, onChange, ...otherProps } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = event.target.value.replace(/[^0-9\-]/g, '');
        setCurrentValue(currentValue);
        const value = Number(currentValue);
        onChange(event, { type: 'number', name: String(props.name), value });
    };

    return (
        <TextInput
            {...otherProps}
            extraLabel="₽"
            value={Number(currentValue) ? Number(currentValue).toLocaleString() : currentValue}
            onChange={handleChange}
        />
    );
};

export const useHandleChange = <T extends string | number>(defaultValue: T): [T, HandleChangeType] => {
    const [value, setValue] = useState(defaultValue);
    const handleChange = (event: SyntheticEvent, { value }: { value: T }) => {
        setValue(value);
    };
    return [value, handleChange];
};

export default React.memo(TextInput);
