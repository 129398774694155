import { observable, action, runInAction } from 'mobx';
import * as contactPaymentApi from '~/api/ff/contactPaymentApi';
import { ContactPaymentType } from '~/types/ff/contactPayment.types';
import ItemStorePrototype from '../prototypes/ItemStore.prototype';

class ContactPaymentStore extends ItemStorePrototype<ContactPaymentType> {
    orderBy = 'payment_id';

    constructor() {
        super('payment_id', 'contact', contactPaymentApi);
    }
}

export default new ContactPaymentStore();
