import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import history from '~/history';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import contactStore from '~/stores/contactStore';
import productStore from '~/stores/ff/productStore';
import authStore from '~/stores/authStore';

import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import Box from '@mui/material/Box';

import Table from '~ui/Table';
import Button from '~ui/Button';
import SkeletonTable from '~ui/SkeletonTable';
import { WarningMessage } from '~ui/Message';

import ProductTableHeader from '~/components/Lists/Products/ProductTableHeader';
import ProductTableBody from '~/components/Lists/Products/ProductTableBody';

import { ProductLinkState } from '~/components/Lists/Products/ProductLink';
import ListError from '~/components/ListError';

const ContactProductMode = ({ contact_id }: { contact_id: number }) => {
    useMemo(() => {
        // contactStore.fetchProductsByContact(contact_id);
    }, [contact_id]);

    const handleCreateProduct = () => {
        const { pathname } = ProductLinkState(CREATING_ITEM_ID);
        history.push({
            pathname,
            state: {
                modal: true,

                from: { storeName: 'contactStore', item_id: contact_id },
                callback: { storeName: 'contactStore', method: 'fetchProductsByContact', item_id: contact_id }
            }
        });
    };

    const { products, productsErrors, productsLoading } = contactStore.getItem(contact_id).property;

    return (
        <>
            {authStore.canCreate(productStore.moduleName) && (
                <Box sx={{ pb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button startIcon={<TakeoutDiningIcon fontSize="large" />} primary onClick={handleCreateProduct} variant="contained">
                        Добавить продукт
                    </Button>
                </Box>
            )}
            <ListError errors={productsErrors} />
            <Table size="small">
                {productsLoading && <SkeletonTable rows={5} columns={6} />}
                <ProductTableHeader orderBy={''} orderDirection={'ascending'} handleSort={() => {}} />
                <ProductTableBody productList={products} />
            </Table>
            {!productsLoading && products?.length === 0 && (
                <WarningMessage header="Товары не найдены">Добавьте товары в данном контакте</WarningMessage>
            )}
        </>
    );
};

export default observer(ContactProductMode);
