import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import productMovementStore from '~/stores/ff/productMovementStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';
import ItemAddModeWrapper from '../ItemAddModeWrapper';
import CreationButtonsSet from '~/components/Items/Common/CreationButtonsSet';

import Container from '@material-ui/core/Container';
import Divider from '~ui/Divider';
import Grid from '~ui/Grid';

import { ProductMovementLinkState } from '~/components/Lists/ProductsMovements/ProductMovementLink';
import ProductMovementMainEditing from './EditBlocks/ProductMovementMainEditing';
import ProductMovementContactEditing from './EditBlocks/ProductMovementContactEditing';
import ProductMovementItemsEditing from './EditBlocks/ProductMovementItemsEditing';
import ProductMovementDirectionEditing from './EditBlocks/ProductMovementDirectionEditing';

type ProductAddModeProps = RouteComponentProps<{}> & ItemAddModeWrapperProps;

const ProductMovementAddMode = ({ handleCreate, clearForm }: ProductAddModeProps) => {
    const { loadingItem } = productMovementStore.getItem(CREATING_ITEM_ID);

    return (
        <Container maxWidth="md">
            <Grid disableGutter>
                <Grid.Column>
                    <ProductMovementDirectionEditing item_id={CREATING_ITEM_ID} />
                </Grid.Column>
                <Grid.Column>
                    <ProductMovementContactEditing item_id={CREATING_ITEM_ID} />
                </Grid.Column>
                <Grid.Column>
                    <ProductMovementItemsEditing product_movement_id={CREATING_ITEM_ID} />
                </Grid.Column>
                <Grid.Column>
                    <ProductMovementMainEditing item_id={CREATING_ITEM_ID} />
                </Grid.Column>
                <Grid.Column>
                    <Divider />
                    <CreationButtonsSet loading={loadingItem} onCreate={handleCreate} onClearForm={clearForm} />
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default ItemAddModeWrapper(productMovementStore, ProductMovementLinkState, withRouter(observer(ProductMovementAddMode)));
