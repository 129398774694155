import React from 'react';
import estateL10n from '~/L10n/estate.L10n';

const Price = ({ price, currency }: { price: number; currency?: number }) => {
    return (
        <>
            {price && price.toLocaleString()} {estateL10n.CURRENCY_TYPES_FIND(currency || 1)}
        </>
    );
};

export default React.memo(Price);
